import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { GlobalStyle } from './style/globalStyle'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { IntlProvider } from 'react-intl'
import { languages } from './lang'
import theme from './style/themes'

const language = navigator.language.split(/[-_]/)[0]
const messages = languages[language] || languages['en']

const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider messages={messages} locale={language} defaultLocale="en">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
