import { Score, TimeSpan, SimMarket, DCOU } from './types'
import { logicDC } from './DC'
import { logicOUnS } from './OU'

export const logicDCOU =
  (handicap: number) => (outcome: DCOU, score: Score) => {
    const oc: { [key in DCOU]: () => boolean } = {
      '1x & over': () =>
        logicDC('1x', score) && logicOUnS(handicap)('o', score),
      '2x & over': () =>
        logicDC('2x', score) && logicOUnS(handicap)('o', score),
      '12 & over': () =>
        logicDC('12', score) && logicOUnS(handicap)('o', score),
      '1x & under': () =>
        logicDC('1x', score) && logicOUnS(handicap)('u', score),
      '2x & under': () =>
        logicDC('2x', score) && logicOUnS(handicap)('u', score),
      '12 & under': () =>
        logicDC('12', score) && logicOUnS(handicap)('u', score),
    }
    return oc[outcome]() ?? false
  }

const ocDCOU = [
  '1x & over',
  '2x & over',
  '12 & over',
  '1x & under',
  '2x & under',
  '12 & under',
] as DCOU[]

const _DCOU = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocDCOU,
  logic: logicDCOU(handicap),
})

export const FT_DCOU_15 = _DCOU(23, 'FT', 1.5)
export const FT_DCOU_25 = _DCOU(24, 'FT', 2.5)
export const FT_DCOU_35 = _DCOU(25, 'FT', 3.5)
export const FT_DCOU_45 = _DCOU(26, 'FT', 4.5)
