import { GameSpeed } from '../../features/app/store/app.state'

export const speeds = (speed: GameSpeed, isCashier: boolean) => {
  const speeds: {
    [key in GameSpeed]: number
  } = {
    normal: 500,
    fast: 300,
    turbo: isCashier ? 200 : 80,
  }
  return speeds[speed]
}
