import { useSelector } from 'react-redux'
import { getEnv } from '../../../../config'
import { IntlMessage } from '../../../../lang/IntlMessage'
import {
  selectDisplayStake,
  selectPlaceBet,
} from '../../../betslip/store/betslip.selectors'
import { closeKeyboard } from '../../../betslip/store/betslip.state'
import { placeBet } from '../../../game/store/game.state'
import { useAppDispatch } from '../../../../store/store'
import { Spinner } from '../../../../components/spinner/spinner'
import { Gift } from '../../../../components/svgs'
import { Button } from './placeBetButton.style'

export const PlaceBetButton = () => {
  const dispatch = useAppDispatch()
  const stake = useSelector(selectDisplayStake)
  const { disablePlaceBet, sessionID, freeBetAmount } =
    useSelector(selectPlaceBet)

  const handlePlaceBet = () => {
    dispatch(placeBet())
  }

  return (
    <Button
      disabled={disablePlaceBet}
      onClick={handlePlaceBet}
      onFocus={() => dispatch(closeKeyboard())}
      data-testid="placeBetButton"
    >
      {!sessionID ? (
        <Spinner height={'1vh'} size={'35px'} />
      ) : freeBetAmount ? (
        <span className="stake">
          <Gift width="20" height="16" viewBox="5 1 20 20" />
          <IntlMessage id="placeBetButton_freeBet" />
        </span>
      ) : (
        <p className="stake">
          <IntlMessage id="placeBetButton_bet" /> ({getEnv().currency} {stake})
        </p>
      )}
    </Button>
  )
}
