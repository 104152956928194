import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import appReducer from '../features/app/store/app.state'
import betslipReducer from '../features/betslip/store/betslip.state'
import mygamesReducer from '../features/my-games/store/mygames.state'
import gameReducer from '../features/game/store/game.state'
import sportsBookReducer from '../features/explore/store/explore.state'
import reportsReducer from '../features/reports/store/reports.state'
import receiptReducer from '../features/receipt/store/receipt.state'
import tournamentReducer from '../features/tournament/store/tournament.state'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const appPersistConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['acceptOdds', 'speed', 'isCashierSpeed', 'canPrint'],
}

export const store = configureStore({
  reducer: {
    app: persistReducer(appPersistConfig, appReducer),
    betslip: betslipReducer,
    mygames: mygamesReducer,
    game: gameReducer,
    explore: sportsBookReducer,
    reports: reportsReducer,
    receipt: receiptReducer,
    tournament: tournamentReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
