import { soccerTimeline$ } from '../sports/soccer/timeline/soccerTimeline'
import { basketballTimeline$ } from '../sports/basketball/timeline/basketballTimeline'
import { useState, useEffect } from 'react'
import { SimSports } from '../config'

export const useTimeline = (sport: SimSports) => {
  const [timeline, setTimeline] = useState<any>()

  let sub: any

  useEffect(() => {
    if (sport === 'Soccer') sub = soccerTimeline$?.subscribe(setTimeline)
    if (sport === 'Basketball')
      sub = basketballTimeline$?.subscribe(setTimeline)
    return () => sub?.unsubscribe()
  }, [])

  return { timeline }
}
