import { ArrowDropDown, ChartBar } from '../../../../components/svgs'
import { Wrapper } from './statsToggle.style'

interface IStatsToggle {
  setExpand(): void
}
export const StatsToggle: React.FC<IStatsToggle> = ({ setExpand }) => {
  return (
    <Wrapper onClick={() => setExpand()}>
      <div className="icon_arrow">
        <ArrowDropDown width="24" height="24" viewBox="0 0 24 24" />
      </div>
      <div className="icon_stats">
        <ChartBar width="16" height="16" viewBox="0 0 24 24" />
      </div>
    </Wrapper>
  )
}
