import { BehaviorSubject, queueScheduler, scheduled, timer } from 'rxjs'
import { concatAll, map, scan, take } from 'rxjs/operators'
import { GameSpeed } from '../../../features/app/store/app.state'
import { clamp } from '../../../lib/utils/mathUtils'
import { speeds } from '../../../lib/timeline/utils'

export type TimeLine = {
  boost: boolean
  clock: number
  countDown: string
  display: string
  segment: string
}

const timelineInit: TimeLine = {
  boost: false, // boost animation
  countDown: '', // count down animation
  clock: 0, // absolute time
  display: '0:00', // counter in game header
  segment: '1st Quarter',
}

export const basketballTimeline$ = new BehaviorSubject<TimeLine>(timelineInit)

export const resetBasketballTimeLine = () =>
  basketballTimeline$.next(timelineInit)

export const formatClock = (n: number): string => {
  n = clamp(0, 48, n)
  const result: {
    [key: number]: string
  } = {
    12: '1ST Quarter',
    24: '2ND Quarter',
    36: '3RD Quarter',
    48: '4TH Quarter',
  }
  return result[n] || `${n}:00`
}

const formatSegment = (n: number) => {
  if (n <= 12) return '1st Quarter'
  if (n > 12 && n <= 24) return '2nd Quarter'
  if (n > 24 && n <= 36) return '3rd Quarter'
  if (n > 36 && n <= 48) return '4th Quarter'
}

export const generateBasketballTimeLine = (
  speed: GameSpeed,
  isCashier: boolean
) => {
  const calcBreak = () => {
    if (isCashier) return 3150
    if (speed === 'turbo') return 1500
    if (speed === 'fast') return 4600
    return 7600
  }

  const periodSpeed = speeds(speed, isCashier)
  const duration = 12
  const cdArray = isCashier
    ? ['5', '4', '3', '2', '1', 'Kick-off']
    : ['3', '2', '1', 'Kick-off']

  const boost = () =>
    timer(0, 800).pipe(
      take(2),
      map(() => ({
        boost: true,
      }))
    )

  const countDown = () =>
    timer(0, 800).pipe(
      take(cdArray.length),
      map((i) => ({
        boost: false,
        countDown: cdArray[i],
      }))
    )

  const pause = (p: number) => timer(p)

  const period = (
    duetime: number,
    period: number,
    offset: number,
    duration: number
  ) =>
    timer(duetime, period).pipe(
      take(duration),
      map((n) => ({
        boost: false,
        countDown: '',
        clock: n + 1 + offset,
        display: formatClock(n + 1 + offset),
        segment: formatSegment(n + 1 + offset),
      }))
    )

  const line$ = [
    boost(),
    speed !== 'turbo' || isCashier ? countDown() : pause(0),
    period(speed !== 'turbo' || isCashier ? 800 : 40, periodSpeed, 0, duration),
    period(calcBreak(), periodSpeed, duration, duration),
    period(calcBreak(), periodSpeed, duration * 2, duration),
    period(calcBreak(), periodSpeed, duration * 3, duration),
    pause(speed !== 'turbo' || isCashier ? 400 : 700),
  ]

  const gameTimeline$ = scheduled(line$, queueScheduler).pipe(
    concatAll(),
    scan((acc, curr) => Object.assign({}, acc, curr), timelineInit),
    map((t) => {
      // console.log('basketball', t)
      basketballTimeline$.next(t)
      return t
    })
  )

  return gameTimeline$
}
