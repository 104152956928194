import { IntlMessage } from '../../lang/IntlMessage'
import { TMessages } from '../../lang/types'
import { ToggleWrapper } from './toggle.style'

export interface ToggleProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isOn: boolean
  title: keyof TMessages
  id: string
}
const Toggle: React.FC<ToggleProps> = ({ onChange, isOn, title, id }) => {
  return (
    <ToggleWrapper>
      <span data-testid="toggle-title" className="simulate-toggle-text">
        <IntlMessage id={title} />
      </span>
      <input
        checked={isOn}
        onChange={onChange}
        className="simulate-toggle-checkbox"
        id={id}
        type="checkbox"
        data-testid="checkbox"
      />
      <label
        className="simulate-toggle-label"
        htmlFor={id}
        style={{ background: isOn ? '#F1E3C6' : '#2B2B32' }}
      >
        <span
          className={`simulate-toggle-button`}
          style={{ background: isOn ? '#F6B52D' : '#FFFFFF' }}
          data-testid="toggle-button"
        />
      </label>
    </ToggleWrapper>
  )
}

export default Toggle
