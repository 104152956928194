import { useSelector } from 'react-redux'
import { selectFreeBets } from '../../../betslip/store/betslip.selectors'
import { FreeBetContent, FreeBetNoContent } from '../freeBetItem/freeBetItem'
import { FreeBetItem } from '../freeBetItem/freeBetItem'

export const FreeBetList = () => {
  const freeBetsList = useSelector(selectFreeBets)

  return (
    <ul style={{ margin: '8px', overflow: 'auto' }} data-testid="freeBetList">
      {freeBetsList.length === 0 ? (
        <FreeBetItem>
          <FreeBetNoContent />
        </FreeBetItem>
      ) : (
        freeBetsList.map((bet) => (
          <FreeBetItem key={bet.free_bet_uid}>
            <FreeBetContent freeBet={bet} />
          </FreeBetItem>
        ))
      )}
    </ul>
  )
}
