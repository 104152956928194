import styled from 'styled-components'

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  padding: 6px 16px;
  margin-top: 4px;
  background: ${(props) => props.theme.colours.S090};
  color: ${(props) => props.theme.colours.N040};
  border-radius: 3px;
  z-index: 19;
  box-sizing: border-box;

  .text {
    font-size: 10px;
    padding-left: 4px;
  }
  .amount {
    font-size: 10px;
  }
`

export const AddMore = styled.button`
  font-weight: 400;
  color: ${(props) => props.theme.colours.AP050};
  background-color: ${(props) => props.theme.colours.S090};
  border: none;
  outline: none;

  &:active {
    color: ${(props) => props.theme.colours.AP010};
  }

  &:disabled {
    color: ${(props) => props.theme.colours.N050};
  }

  &:hover {
    cursor: pointer;
  }
`
