import { bet9jaTheme } from './bet9ja.theme'
import { yajuegoTheme } from './yajuego.theme'
import { BRAND } from '../../config'

const themes: { [key: string]: any } = {
  bet9ja: bet9jaTheme,
  yajuego: yajuegoTheme,
}

const theme = BRAND ? themes[BRAND] : themes['bet9ja']

export default theme
