import * as B9J from './types'
import * as Sim from '../../markets'

/** Maps Bet9Ja markets into simulate markets */
export const B9jSoccerMap = new Map<B9J.Markets, Sim.Market>()
/** Maps Bet9Ja outcomes into simulate outcomes */
export const B9JSoccerOutcomeMap = new Map<B9J.Outcomes, Sim.Outcome>()

/**
 * a function that returns the simulate outcome code for Soccer events,
 * used by the backend for the simulation.
 * @param _b9jMkt unused
 * @param outcome the outcome from B9Ja code
 */
export const findSoccerOutcome = (
  _b9jMkt: B9J.Markets,
  outcome: B9J.Outcomes
): Sim.Outcome => {
  return B9JSoccerOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 1x2
B9jSoccerMap.set('1X2', 'FT 1X2')
// 1x2 halftime
B9jSoccerMap.set('1X21T', 'HT 1X2')
B9JSoccerOutcomeMap.set('1', '1')
B9JSoccerOutcomeMap.set('X', 'x')
B9JSoccerOutcomeMap.set('2', '2')

// Over/Under
B9jSoccerMap.set('OU@0.5', 'FT OU 0.5')
B9jSoccerMap.set('OU@1.5', 'FT OU 1.5')
B9jSoccerMap.set('OU@2.5', 'FT OU 2.5')
B9jSoccerMap.set('OU@3.5', 'FT OU 3.5')
B9jSoccerMap.set('OU@4.5', 'FT OU 4.5')
B9jSoccerMap.set('OU@5.5', 'FT OU 5.5')
B9jSoccerMap.set('OU@6.5', 'FT OU 6.5')
B9jSoccerMap.set('OU@7.5', 'FT OU 7.5')
B9jSoccerMap.set('OU@8.5', 'FT OU 8.5')
B9jSoccerMap.set('OU1T@0.5', 'HT_OU_0.5')
B9jSoccerMap.set('OU1T@1.5', 'HT_OU_1.5')
B9jSoccerMap.set('OU1T@2.5', 'HT_OU_2.5')
B9JSoccerOutcomeMap.set('O', 'o')
B9JSoccerOutcomeMap.set('U', 'u')

// Goal/No Goal
B9jSoccerMap.set('GGNG', 'FT GG/NG')
B9jSoccerMap.set('GGNG1T', 'HT_GG/NG')
B9JSoccerOutcomeMap.set('Y', 'gg')
B9JSoccerOutcomeMap.set('N', 'ng')

// Double Chance
B9jSoccerMap.set('DC', 'FT DC')
B9jSoccerMap.set('DC1T', 'HT_DC')
B9JSoccerOutcomeMap.set('1X', '1x')
B9JSoccerOutcomeMap.set('12', '12')
B9JSoccerOutcomeMap.set('X2', '2x')

// 1X2 Or Goal/No Goal -> chance Mix
B9jSoccerMap.set('CHANCEMIX', 'Chance Mix')
B9JSoccerOutcomeMap.set('1orGG', '1 or gg')
B9JSoccerOutcomeMap.set('XorGG', 'x or gg')
B9JSoccerOutcomeMap.set('2orGG', '2 or gg')
B9JSoccerOutcomeMap.set('1orNG', '1 or ng')
B9JSoccerOutcomeMap.set('XorNG', 'x or ng')
B9JSoccerOutcomeMap.set('2orNG', '2 or ng')

// CHANCEMIXGGOU is not supported

// 1X2 || Over/Under -> ChanceMix or OU
B9jSoccerMap.set('CHANCEMIXOU@1.5', 'Chance Mix 1.5')
B9jSoccerMap.set('CHANCEMIXOU@2.5', 'Chance Mix 2.5')
B9jSoccerMap.set('CHANCEMIXOU@3.5', 'Chance Mix 3.5')
B9JSoccerOutcomeMap.set('1orOv', '1 or ov')
B9JSoccerOutcomeMap.set('XorOv', 'x or ov')
B9JSoccerOutcomeMap.set('2orOv', '2 or ov')
B9JSoccerOutcomeMap.set('1orUn', '1 or un')
B9JSoccerOutcomeMap.set('XorUn', 'x or un')
B9JSoccerOutcomeMap.set('2orUn', '2 or un')

// 1X2 && Over/Under
B9jSoccerMap.set('1X2OU@1.5', '1X2 & O/U 1.5')
B9jSoccerMap.set('1X2OU@2.5', '1X2 & O/U 2.5')
B9jSoccerMap.set('1X2OU@3.5', '1X2 & O/U 3.5')
B9jSoccerMap.set('1X2OU@4.5', '1X2 & O/U 4.5')
B9JSoccerOutcomeMap.set('1O', '1 & over')
B9JSoccerOutcomeMap.set('1U', '1 & under')
B9JSoccerOutcomeMap.set('XO', 'x & over')
B9JSoccerOutcomeMap.set('XU', 'x & under')
B9JSoccerOutcomeMap.set('2O', '2 & over')
B9JSoccerOutcomeMap.set('2U', '2 & under')

// Home/Away - Over/Under
B9jSoccerMap.set('HAOU@0.5', 'H/A O/U 0.5')
B9jSoccerMap.set('HAOU@1.5', 'H/A O/U 1.5')
B9jSoccerMap.set('HAOU@2.5', 'H/A O/U 2.5')
B9jSoccerMap.set('HAOU@3.5', 'H/A O/U 3.5')
B9JSoccerOutcomeMap.set('OH', 'over home')
B9JSoccerOutcomeMap.set('UH', 'under home')
B9JSoccerOutcomeMap.set('OA', 'over away')
B9JSoccerOutcomeMap.set('UA', 'under away')

// 1X2 && Goal/No Goal
B9jSoccerMap.set('1X2GGNG', '1X2 & GG/NG')
B9JSoccerOutcomeMap.set('1ANDGG', '1 & gg')
B9JSoccerOutcomeMap.set('1ANDNG', '1 & ng')
B9JSoccerOutcomeMap.set('XANDGG', 'x & gg')
B9JSoccerOutcomeMap.set('XANDNG', 'x & ng')
B9JSoccerOutcomeMap.set('2ANDGG', '2 & gg')
B9JSoccerOutcomeMap.set('2ANDNG', '2 & ng')

// Double Chance && Over/Under
B9jSoccerMap.set('DCOU@1.5', 'DC & O/U 1.5')
B9jSoccerMap.set('DCOU@2.5', 'DC & O/U 2.5')
B9jSoccerMap.set('DCOU@3.5', 'DC & O/U 3.5')
B9jSoccerMap.set('DCOU@4.5', 'DC & O/U 4.5')
B9JSoccerOutcomeMap.set('1XO', '1x & over')
B9JSoccerOutcomeMap.set('X2O', '2x & over')
B9JSoccerOutcomeMap.set('12O', '12 & over')
B9JSoccerOutcomeMap.set('1XU', '1x & under')
B9JSoccerOutcomeMap.set('X2U', '2x & under')
B9JSoccerOutcomeMap.set('12U', '12 & under')

// Correct Score
B9jSoccerMap.set('CSFT', 'Correct Score')
B9JSoccerOutcomeMap.set('0:0', '0-0')
B9JSoccerOutcomeMap.set('0:1', '0-1')
B9JSoccerOutcomeMap.set('0:2', '0-2')
B9JSoccerOutcomeMap.set('0:3', '0-3')
B9JSoccerOutcomeMap.set('0:4', '0-4')
B9JSoccerOutcomeMap.set('1:0', '1-0')
B9JSoccerOutcomeMap.set('1:1', '1-1')
B9JSoccerOutcomeMap.set('1:2', '1-2')
B9JSoccerOutcomeMap.set('1:3', '1-3')
B9JSoccerOutcomeMap.set('1:4', '1-4')
B9JSoccerOutcomeMap.set('2:0', '2-0')
B9JSoccerOutcomeMap.set('2:1', '2-1')
B9JSoccerOutcomeMap.set('2:2', '2-2')
B9JSoccerOutcomeMap.set('2:3', '2-3')
B9JSoccerOutcomeMap.set('2:4', '2-4')
B9JSoccerOutcomeMap.set('3:0', '3-0')
B9JSoccerOutcomeMap.set('3:1', '3-1')
B9JSoccerOutcomeMap.set('3:2', '3-2')
B9JSoccerOutcomeMap.set('3:4', '3-4')
B9JSoccerOutcomeMap.set('3:3', '3-3')
B9JSoccerOutcomeMap.set('4:0', '4-0')
B9JSoccerOutcomeMap.set('4:1', '4-1')
B9JSoccerOutcomeMap.set('4:2', '4-2')
B9JSoccerOutcomeMap.set('4:3', '4-3')
B9JSoccerOutcomeMap.set('4:4', '4-4')
B9JSoccerOutcomeMap.set('OTH', 'other')

// Multi C.Score (4) -> MARKET 29
B9jSoccerMap.set('MCS4', 'C. Score Multi')
B9JSoccerOutcomeMap.set('00110110', '0-0 / 1-1 / 0-1 / 1-0')
B9JSoccerOutcomeMap.set('20213031', '2-0 / 2-1 / 3-0 / 3-1')
B9JSoccerOutcomeMap.set('02120313', '0-2 / 1-2 / 0-3 / 1-3')
B9JSoccerOutcomeMap.set('22233233', '2-2 / 2-3 / 3-2 / 3-3')
B9JSoccerOutcomeMap.set('04142434', '0-4 / 1-4 / 2-4 / 3-4')
B9JSoccerOutcomeMap.set('40414243', '4-0 / 4-1 / 4-2 / 4-3')
B9JSoccerOutcomeMap.set('44O', '4-4 / other')

// Multi C.Score (3) -> MARKET 30
B9jSoccerMap.set('MCS3', 'C. Score Multi2')
B9JSoccerOutcomeMap.set('0011', '0-0 / 1-1')
B9JSoccerOutcomeMap.set('223344', '2-2 / 3-3 / 4-4')
B9JSoccerOutcomeMap.set('102021', '1-0 / 2-0 / 2-1')
B9JSoccerOutcomeMap.set('010212', '0-1 / 0-2 / 1-2')
B9JSoccerOutcomeMap.set('303132', '3-0 / 3-1 / 3-2')
B9JSoccerOutcomeMap.set('031323', '0-3 / 1-3 / 2-3')
B9JSoccerOutcomeMap.set('414243', '4-1 / 4-2 / 4-3')
B9JSoccerOutcomeMap.set('142434', '1-4 / 2-4 / 3-4')
B9JSoccerOutcomeMap.set('4004O', '4-0 / 0-4 / other')

// Multi goal
B9jSoccerMap.set('NGOALS', 'Multi goal')
B9JSoccerOutcomeMap.set('1-2', '1-2 goals')
B9JSoccerOutcomeMap.set('1-3', '1-3 goals')
B9JSoccerOutcomeMap.set('1-4', '1-4 goals')
B9JSoccerOutcomeMap.set('1-5', '1-5 goals')
B9JSoccerOutcomeMap.set('1-6', '1-6 goals')
B9JSoccerOutcomeMap.set('2-3', '2-3 goals')
B9JSoccerOutcomeMap.set('2-4', '2-4 goals')
B9JSoccerOutcomeMap.set('2-5', '2-5 goals')
B9JSoccerOutcomeMap.set('2-6', '2-6 goals')
B9JSoccerOutcomeMap.set('3-4', '3-4 goals')
B9JSoccerOutcomeMap.set('3-5', '3-5 goals')
B9JSoccerOutcomeMap.set('3-6', '3-6 goals')
B9JSoccerOutcomeMap.set('4-5', '4-5 goals')
B9JSoccerOutcomeMap.set('4-6', '4-6 goals')
B9JSoccerOutcomeMap.set('5-6', '5-6 goals')

// Halft Time Full Time
B9jSoccerMap.set('HTFT', 'HT_FT')
B9JSoccerOutcomeMap.set('1/1', '1/1')
B9JSoccerOutcomeMap.set('1/X', '1/x')
B9JSoccerOutcomeMap.set('1/2', '1/2')
B9JSoccerOutcomeMap.set('X/1', 'x/1')
B9JSoccerOutcomeMap.set('X/X', 'x/x')
B9JSoccerOutcomeMap.set('X/2', 'x/2')
B9JSoccerOutcomeMap.set('2/1', '2/1')
B9JSoccerOutcomeMap.set('2/X', '2/x')
B9JSoccerOutcomeMap.set('2/2', '2/2')

// Odd EVen
B9jSoccerMap.set('OE', 'FT_ODD/EVEN')
B9jSoccerMap.set('OE1T', 'HT_ODD/EVEN')
B9JSoccerOutcomeMap.set('OD', 'odd')
B9JSoccerOutcomeMap.set('EV', 'even')

// Handicaps
// B9jSoccerMap.set('1X2HND@1', 'FT_HANDICAP_1_1');
// B9JSoccerOutcomeMap.set('1H', '1h:+1');
// B9JSoccerOutcomeMap.set('1H', '1h:+2');
// B9JSoccerOutcomeMap.set('1H', '1h:+3');

// B9jSoccerMap.set('1X2HND@2', 'FT_HANDICAP_1_2');

// B9jSoccerMap.set('1X2HND@3', 'FT_HANDICAP_1_3');

// B9jSoccerMap.set('1X2HND@-1', 'FT_HANDICAP_2_1');

// B9jSoccerMap.set('1X2HND@-2', 'FT_HANDICAP_2_2');

// B9jSoccerMap.set('1X2HND@-3', 'FT_HANDICAP_2_3');
