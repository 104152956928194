import styled from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 40px;
  padding: 0 8px;
  align-items: center;
`
