import { SimMarket, OneXTwoAndGGNG, Score, TimeSpan } from './types'
import { logic1X2 } from './1X2'
import { logicGGNG } from './GG'

export const logic1X2GG = (outcome: OneXTwoAndGGNG, score: Score) => {
  const oc: { [key in OneXTwoAndGGNG]: () => boolean | 'settled' } = {
    '1 & gg': () => logic1X2('1', score) && logicGGNG('gg', score),
    '1 & ng': () => logic1X2('1', score) && logicGGNG('ng', score),
    'x & gg': () => logic1X2('x', score) && logicGGNG('gg', score),
    'x & ng': () => logic1X2('x', score) && logicGGNG('ng', score),
    '2 & gg': () => logic1X2('2', score) && logicGGNG('gg', score),
    '2 & ng': () => logic1X2('2', score) && logicGGNG('ng', score),
  }
  return oc[outcome]() ?? false
}

const oc1X2GG = [
  '1 & gg',
  '1 & ng',
  'x & gg',
  'x & ng',
  '2 & gg',
  '2 & ng',
] as OneXTwoAndGGNG[]

const _1X2GG = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: oc1X2GG,
  logic: logic1X2GG,
})

export const FT_1X2GG = _1X2GG(22, 'FT')
