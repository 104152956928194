import { useEffect } from 'react'
import { ErrorModal } from '../../../components/error-modal/errorModal'
import { StyledPage } from '../../../style/styles'
import { useAppDispatch } from '../../../store/store'
import { fetchTournamentEvents } from '../store/tournament.state'
import { Football } from '../../../components/svgs'
import { IntlMessage } from '../../../lang/IntlMessage'
import { HeadingWrapper, Heading, PaddingWrapper } from './tournamentPage.style'
import { Tournaments } from '../components/tournaments/tournaments'
import theme from '../../../style/themes'

export const TournamentPage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchTournamentEvents())
  }, [dispatch])

  return (
    <>
      <ErrorModal />
      <StyledPage background={theme.colours.AP052G}>
        <PaddingWrapper>
          <HeadingWrapper>
            <Football width="16" height="16" viewBox="0 0 14 16" />
            <Heading>
              <IntlMessage id="tournament_Heading" />
            </Heading>
          </HeadingWrapper>
          <Tournaments />
        </PaddingWrapper>
      </StyledPage>
    </>
  )
}
