import { SimMarket, Score, TimeSpan, ODDEVEN } from './types'

export const logicODDEVEN = (outcome: ODDEVEN, score: Score) => {
  const totalScore = score.reduce((score, acc) => score + acc, 0)
  const oc: { [key in ODDEVEN]: () => boolean } = {
    even: () => totalScore % 2 === 0,
    odd: () => totalScore % 2 === 1,
  }
  return oc[outcome]() ?? false
}

const ocODDEVEN = ['odd', 'even'] as ODDEVEN[]

const _ODDEVEN = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocODDEVEN,
  logic: logicODDEVEN,
})

export const FT_ODDEVEN = _ODDEVEN(41, 'FT')
export const HT_ODDEVEN = _ODDEVEN(43, 'HT')
