import * as Sim from '.'

export const simulateMarkets = new Map<Sim.Market, Sim.SimMarket>()
simulateMarkets.set('FT 1X2', Sim.FT_1X2)
simulateMarkets.set('HT 1X2', Sim.HT_1X2)

simulateMarkets.set('FT OU 0.5', Sim.FT_OU_05)
simulateMarkets.set('FT OU 1.5', Sim.FT_OU_15)
simulateMarkets.set('FT OU 2.5', Sim.FT_OU_25)
simulateMarkets.set('FT OU 3.5', Sim.FT_OU_35)
simulateMarkets.set('FT OU 4.5', Sim.FT_OU_45)
simulateMarkets.set('FT OU 5.5', Sim.FT_OU_55)
simulateMarkets.set('FT OU 6.5', Sim.FT_OU_65)
simulateMarkets.set('FT OU 7.5', Sim.FT_OU_75)
simulateMarkets.set('FT OU 8.5', Sim.FT_OU_85)

simulateMarkets.set('FT GG/NG', Sim.FT_GGNG)
simulateMarkets.set('HT_GG/NG', Sim.HT_GGNG)

simulateMarkets.set('FT DC', Sim.FT_DC)
simulateMarkets.set('HT_DC', Sim.HT_DC)

simulateMarkets.set('Chance Mix', Sim.FT_CM_GGNG)

simulateMarkets.set('Chance Mix 1.5', Sim.FT_CMOU_15)
simulateMarkets.set('Chance Mix 2.5', Sim.FT_CMOU_25)
simulateMarkets.set('Chance Mix 3.5', Sim.FT_CMOU_35)

simulateMarkets.set('1X2 & O/U 1.5', Sim.FT_1X2OU_15)
simulateMarkets.set('1X2 & O/U 2.5', Sim.FT_1X2OU_25)
simulateMarkets.set('1X2 & O/U 3.5', Sim.FT_1X2OU_35)
simulateMarkets.set('1X2 & O/U 4.5', Sim.FT_1X2OU_45)

simulateMarkets.set('1X2 & GG/NG', Sim.FT_1X2GG)

simulateMarkets.set('DC & O/U 1.5', Sim.FT_DCOU_15)
simulateMarkets.set('DC & O/U 2.5', Sim.FT_DCOU_25)
simulateMarkets.set('DC & O/U 3.5', Sim.FT_DCOU_35)
simulateMarkets.set('DC & O/U 4.5', Sim.FT_DCOU_45)

simulateMarkets.set('M1_FT_DNB', {} as Sim.SimMarket)

simulateMarkets.set('Correct Score', Sim.FT_CSCORE)

simulateMarkets.set('C. Score Multi', Sim.FT_CS_M1)
simulateMarkets.set('C. Score Multi2', Sim.FT_CS_M2)

simulateMarkets.set('Multi goal', Sim.FT_MG)

simulateMarkets.set('H/A O/U 0.5', Sim.FT_HAOU_05)
simulateMarkets.set('H/A O/U 1.5', Sim.FT_HAOU_15)
simulateMarkets.set('H/A O/U 2.5', Sim.FT_HAOU_25)
simulateMarkets.set('H/A O/U 3.5', Sim.FT_HAOU_35)

simulateMarkets.set('FT_ODD/EVEN', Sim.FT_ODDEVEN)
simulateMarkets.set('HT_ODD/EVEN', Sim.HT_ODDEVEN)

simulateMarkets.set('HT_OU_0.5', Sim.HT_OU_05)
simulateMarkets.set('HT_OU_1.5', Sim.HT_OU_15)
simulateMarkets.set('HT_OU_2.5', Sim.HT_OU_25)

simulateMarkets.set('HT_FT', Sim.FT_HTFT)
