import React from 'react'
import { Odometer } from '../odometer/odometer'
import { BrandSimulate } from '../../../../components/svgs'
import { ScoreBarWrapper, Team } from './gameItem.style'
import { splitTeams } from '../../../../lib/utils/uiUtils'

type ScoreBarProps = {
  score: number[]
  halftimeResult?: number[]
  event: string
  won: boolean | string
  sport?: number
}
export const ScoreBar: React.FC<ScoreBarProps> = React.memo(
  ({ score, event, won, halftimeResult, sport }) => {
    const teams = splitTeams(event)
    const [result1, result2] = halftimeResult || []

    return (
      <ScoreBarWrapper won={!!won}>
        <div className="scoreDisplay">
          <Team won={!!won}>{teams.team1}</Team>
          <div className="score">
            <Odometer score={score[0]} won={won} />
          </div>
          {sport === 1 && <span className="results">({result1})</span>}
          <div className="logo">
            <BrandSimulate width="14" height="14" viewBox="-2 -2 28 28" />
          </div>
          {sport === 1 && <span className="results">({result2})</span>}

          <div className="score">
            <Odometer score={score[1]} won={won} />
          </div>

          <Team left won={!!won}>
            {teams.team2}
          </Team>
        </div>
      </ScoreBarWrapper>
    )
  }
)

ScoreBar.displayName = 'ScoreBar'
