import styled from 'styled-components'

export const FRebet = styled.div`
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  .rebet {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: ${(props) => props.theme.colours.RP020};
    color: ${(props) => props.theme.colours.AP020};
    box-shadow: 0px 3px 0px #188a5a, 0px 6px 3px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 11px 0;
    overflow: hidden;

    @keyframes flash {
      0% {
        left: -50%;
      }
      50% {
        left: 120%;
      }
      100% {
        left: -50%;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      background-color: #fff;
      transform: skew(330deg, 0deg);
      width: 4px;
      opacity: 0.8;
      box-shadow: 0px 0px 25px 30px rgb(255 255 255);
      animation: flash 2s infinite linear;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 23px;

      path {
        fill: ${(props) => props.theme.colours.AP020};
      }
    }

    &__txt {
      font-size: 25px;
      font-weight: 700;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.09);
      text-transform: uppercase;
    }

    &__countdown {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.colours.AS060};
      border: 1px solid #167f52;
      border-radius: 24px;
      padding: 7px;
      gap: 4px;

      .icon-clock {
        display: flex;
        path {
          fill: ${(props) => props.theme.colours.AP020};
        }
      }

      .time {
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
`
