import { BehaviorSubject, timer, EMPTY, Observable } from 'rxjs'
import {
  switchMap,
  timestamp,
  map,
  distinctUntilKeyChanged,
} from 'rxjs/operators'

export const formatTimeStamp = (timestamp: number) => {
  const days = Math.floor(timestamp / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timestamp % (1000 * 60)) / 1000)
  const time = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's'
  return {
    days,
    hours,
    minutes,
    seconds,
    time,
  }
}

export type CountDown = { output: string; time: string; qstk: string }

export const countDown = (deadline: number): Observable<CountDown> => {
  deadline = deadline * 1000

  const doIt$ = new BehaviorSubject<boolean>(true)
  const countDown$ = doIt$.pipe(
    switchMap((x) => (x ? timer(0, 1000) : EMPTY)),
    timestamp(),
    map((x) => {
      const now = x.timestamp
      const remain = deadline - now
      const isExpired = deadline < now

      const { days, hours, minutes, seconds, time } = formatTimeStamp(remain)

      let output = minutes + ' min'
      let qstk = minutes + ' min'

      if (hours === 0 && minutes < 10) {
        let strMinutes = minutes.toString()
        let strSeconds = seconds.toString()
        if (minutes < 10) strMinutes = '0' + minutes
        if (seconds < 10) strSeconds = '0' + seconds
        output = strMinutes + ':' + strSeconds
        qstk = strMinutes + ':' + strSeconds
      }

      if (hours > 0) {
        output = hours === 1 ? hours + ' hour' : hours + ' hours'
        qstk = ''
        doIt$.next(false)
      }

      if (days > 0) {
        output = days === 1 ? days + ' day' : days + ' days'
        qstk = ''
        doIt$.next(false)
      }

      if (isExpired) {
        output = 'Expired'
        doIt$.next(false)
      }

      return {
        time,
        output,
        qstk,
      }
    }),
    distinctUntilKeyChanged('output')
  )

  return countDown$
}
