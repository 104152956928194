import { useAppDispatch, useAppSelector } from '../../../../store/store'
import { teams } from '../../../../lib/utils/teamSVGMap'
import { titleCase } from '../../../../lib/utils/uiUtils'
import { SimBet } from '../../../../sports/types'
import { selectBets } from '../../../betslip/store/betslip.selectors'
import { clicksOnExploreItem } from '../../../betslip/store/betslip.state'
import {
  SelectionWithMarketWrapper,
  EventName,
  IconFlag,
  Odds,
} from './matchSelection.style'
import { Lock } from '../../../../components/svgs'

interface ISelectionWithMarket {
  team: string
  odds: number | undefined
  bet: SimBet | undefined
}

export const SelectionWithMarket: React.FC<ISelectionWithMarket> = ({
  team,
  odds,
  bet,
}) => {
  const dispatch = useAppDispatch()
  const betslip = useAppSelector(selectBets)
  const isActive = bet ? betslip.some((b) => b.b9jBet === bet.b9jBet) : false

  const teamSVG = teams.get(team.toUpperCase()) || 'Tottenham'

  const handleClick = () => {
    bet && dispatch(clicksOnExploreItem(bet))
  }

  return (
    <SelectionWithMarketWrapper onClick={handleClick} isActive={isActive}>
      {team !== 'draw' && (
        <IconFlag isActive={isActive}>
          <img
            title="flag"
            src={require(`../../../../components/svgs/shirts/${teamSVG}.svg`)}
            width="36"
            height="26"
          />
        </IconFlag>
      )}

      <EventName isActive={isActive}>{titleCase(team)}</EventName>
      <Odds isActive={isActive}>{odds || <Lock />}</Odds>
    </SelectionWithMarketWrapper>
  )
}
