import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px 0px;
  background-color: ${(props) => props.theme.colours.S050};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;
  cursor: pointer;
`

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  margin-top: 6px 13px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  span {
    color: ${(props) => props.theme.colours.N040};
    &.bold {
      font-weight: 500;
      color: ${(props) => props.theme.colours.N030};
    }
  }
`

export const PrintButton = styled.button`
  border: 1px solid ${(props) => props.theme.colours.AP050};
  background: ${(props) => props.theme.colours.AP050};
  border-radius: 22px;
  margin-bottom: 14px;
  padding: 8px 16px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`
