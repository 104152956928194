import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { TExploreStats } from '../../../../sports/types'
import { Stats } from '../../../../components/stats/stats'
import { StatsToggle } from '../statsToggle/statsToggle'
import { StatsWrapper } from './matchStats.style'

interface IMatchStats {
  stats?: TExploreStats[]
  eventName: string
}

export const MatchStats: React.FC<IMatchStats> = ({ stats, eventName }) => {
  const [expand, setExpand] = useState(false)

  return (
    <>
      {!expand && <StatsToggle setExpand={() => setExpand(true)} />}
      <AnimatePresence>
        {expand && (
          <StatsWrapper
            variants={{
              open: { height: 'calc(100% - 52px)' },
              closed: { height: '0' },
            }}
            initial={{ height: 0 }}
            animate={{ height: expand ? 'auto' : 0 }}
            exit="closed"
            transition={{ duration: 0.12 }}
            onClick={() => setExpand(false)}
          >
            <Stats stats={stats} event={eventName} withIcon />
          </StatsWrapper>
        )}
      </AnimatePresence>
    </>
  )
}
