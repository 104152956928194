import { errorHandling } from '../../../api/utils'
import { targetUrl } from '../../../config'
import { TExploreApi } from '../../../sports/types'

export const getExploreMarket = async (
  sessionID: string,
  marketID: number
): Promise<TExploreApi> => {
  const response = await fetch(
    `${targetUrl()}/api/explore/market/${marketID}`,
    {
      method: 'get',
      headers: {
        accept: 'application/json',
        'X-SID': `${sessionID}`,
      },
    }
  ).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}
