import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { adapter } from '../../../sports/adapter'
import { Source } from '../../../sports/types'
import { PostMessage } from '../../../lib/postMessageValidator'
import { authenticate } from '../api/authenticate'
import { ErrorResponse, FreeBet, NewOdds } from '../../../api/types'
import {
  createBetslip,
  updateStake,
  createIncentives,
  createNotification,
  closeNotification,
  updateOddsFromError,
} from '../../betslip/store/betslip.state'
import { AppThunk, RootState } from '../../../store/store'

// reducer ------------------------------------------------------------

export type GameSpeed = 'normal' | 'fast' | 'turbo'
export type AppView = 'betslip' | 'game'

type AppState = {
  sessionID: string
  source: Source
  acceptOdds: boolean
  speed: GameSpeed
  view: AppView
  speedDrawer: boolean
  error: ErrorResponse | null
  permissions: { [key: string]: boolean }
  redirect: string | null
  isCashier: boolean
  canPrint: boolean
}

export const initialState: AppState = {
  sessionID: '',
  source: '',
  acceptOdds: false,
  view: 'betslip',
  speed: 'fast',
  speedDrawer: false,
  error: null,
  permissions: { explore: false, basketball: false, tournament_explore: false },
  redirect: null,
  isCashier: false,
  canPrint: true,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateSessionID: (state, { payload }: PayloadAction<string>) => {
      state.sessionID = payload
    },
    updateSource: (state, { payload }: PayloadAction<Source>) => {
      state.source = payload || 'new-mobile'
    },
    toggleAcceptOdds: (state) => {
      state.acceptOdds = !state.acceptOdds
    },
    clicksSetSpeed: (state) => {
      state.speedDrawer = !state.speedDrawer
    },
    closeSpeedDrawer: (state) => {
      state.speedDrawer = false
    },
    clicksSpeed: (state, { payload }: PayloadAction<GameSpeed>) => {
      state.speed = payload
      state.speedDrawer = false
    },
    updateSpeed: (state, { payload }: PayloadAction<GameSpeed>) => {
      state.speed = payload
    },
    createError: (state, { payload }: PayloadAction<ErrorResponse>) => {
      state.error = payload
    },
    resetError: (state) => {
      state.error = null
    },
    changeView: (state, { payload }: PayloadAction<AppView>) => {
      state.view = payload
    },
    updatePermissions: (
      state,
      { payload }: PayloadAction<{ [key: string]: boolean }>
    ) => {
      payload &&
        Object.keys(payload).map((k) => (state.permissions[k] = payload[k]))
    },
    updateRedirect: (state, { payload }: PayloadAction<string | null>) => {
      state.redirect = payload
    },
    updateIsCashier: (state, { payload }: PayloadAction<boolean>) => {
      state.isCashier = payload
    },
    toggleCanPrint: (state) => {
      state.canPrint = !state.canPrint
    },
  },
})

export default appSlice.reducer

// actions ------------------------------------------------------------

export const {
  updateSessionID,
  updateSource,
  toggleAcceptOdds,
  clicksSetSpeed,
  closeSpeedDrawer,
  clicksSpeed,
  updateSpeed,
  resetError,
  createError,
  changeView,
  updatePermissions,
  updateRedirect,
  updateIsCashier,
  toggleCanPrint,
} = appSlice.actions

export const bootUp =
  (postMessage: PostMessage): AppThunk =>
  async (dispatch, getState) => {
    const { sessionID } = getState().app
    if (!sessionID) {
      await login(postMessage.otp, postMessage.source, dispatch)
    }
    const { permissions } = getState().app

    dispatch(createBetslip(adapter(postMessage, permissions)))
    dispatch(updateStake(postMessage.stake))

    if (postMessage.selections.length === 0) {
      const { permissions } = getState().app
      if (permissions.explore) {
        dispatch(updateRedirect('explore'))
      } else {
        dispatch(createError({ msg: 'error1000' }))
      }
    }
  }

const login = async (otp: string, source: Source, dispatch: any) => {
  dispatch(updateSource(source))

  try {
    const { session_id, incentives, permissions, users } = await authenticate(
      otp,
      source
    )
    dispatch(updateSessionID(session_id))
    dispatch(createIncentives(incentives))
    dispatch(updatePermissions(permissions))
    if (users.user_self_service === false && users.user_type === 3) {
      dispatch(updateIsCashier(true))
    }

    const dateNow = Date.now()
    const validIncentives = incentives?.free_bets.filter(
      (freebet: FreeBet) => freebet.expiry * 1000 > dateNow
    )
    const numOfFreeBets = validIncentives?.length

    if (numOfFreeBets > 0) {
      if (numOfFreeBets === 1) {
        dispatch(
          createNotification({
            icon: 'gift',
            msg: 'notificationItem_msgFreeBet',
            btn: 'notificationItem_msgFreeBetBtn',
          })
        )
      } else if (numOfFreeBets > 1) {
        dispatch(
          createNotification({
            icon: 'gift',
            msg: 'notificationItem_msgFreeBets',
            btn: 'notificationItem_msgFreeBetsBtn',
          })
        )
      }
      setTimeout(() => {
        dispatch(closeNotification())
      }, 5000)
    }

    if (source === 'self-service') {
      dispatch(updateSpeed('turbo'))
    }
  } catch (e) {
    dispatch(createError(e as ErrorResponse))
  }
}

export const validatesErrorModal =
  (): AppThunk => async (dispatch, getState) => {
    const { error } = getState().app
    if (error?.status === 424 && error?.data) {
      dispatch(updateOddsFromError(error.data as NewOdds))
    }
    dispatch(resetError())
  }

// selectors -----------------------------------------------------------------

export const selectSessionID = (state: RootState) => state.app.sessionID
export const selectSource = (state: RootState) => state.app.source
export const selectAccepOdds = (state: RootState) => state.app.acceptOdds
export const selectSpeed = (state: RootState) => state.app.speed
export const selectSpeedDrawer = (state: RootState) => state.app.speedDrawer
export const selectError = (state: RootState) => state.app.error
export const selectView = (state: RootState) => state.app.view
export const selectPermissions = (state: RootState) => state.app.permissions
export const selectRedirect = (state: RootState) => state.app.redirect
export const selectIsCashier = (state: RootState) => state.app.isCashier
export const selectCanPrint = (state: RootState) => state.app.canPrint
