import { useState } from 'react'
import { ExploreDetail } from './exploreDetail'
import { ExploreHead } from './exploreHead'
import { ExploreWrapper } from './exploreItem.style'
import { SimBet } from '../../../../sports/types'

interface ExploreProps {
  bet: SimBet
}

export const ExploreItem: React.FC<ExploreProps> = ({ bet }) => {
  const [expand, setExpand] = useState(false)
  return (
    <ExploreWrapper data-testid="exploreItem">
      <ExploreHead expand={expand} setExpand={setExpand} bet={bet} />
      <ExploreDetail expand={expand} setExpand={setExpand} bet={bet} />
    </ExploreWrapper>
  )
}
