import { Score, TimeSpan, SimMarket, CSCORE } from '.'

export const logicCSCORE = (outcome: CSCORE, score: Score) => {
  const formatScore = score[0] + '-' + score[1]
  const OC = ocCSCORE.find((oc) => oc === formatScore)
  if (!OC && outcome === 'other') {
    return true
  }
  return outcome === formatScore
}

const ocCSCORE = [
  '1-0',
  '2-0',
  '2-1',
  '3-0',
  '3-1',
  '3-2',
  '4-0',
  '4-1',
  '4-2',
  '4-3',
  '0-1',
  '0-2',
  '1-2',
  '0-3',
  '1-3',
  '2-3',
  '0-4',
  '1-4',
  '2-4',
  '3-4',
  '0-0',
  '1-1',
  '2-2',
  '3-3',
  '4-4',
  'other',
] as CSCORE[]

const _CSCORE = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocCSCORE,
  logic: logicCSCORE,
})

export const FT_CSCORE = _CSCORE(28, 'FT')
