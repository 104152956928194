import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { IntlMessage } from '../../../../lang/IntlMessage'
import { TournamentEvent } from '../../../../sports/types'
import { selectTournamentEvents } from '../../store/tournament.state'
import { Match } from '../match/match'

export const Tournaments = () => {
  const events = useSelector(selectTournamentEvents)
  return (
    <>
      {events.length === 0 ? (
        <Message>
          <IntlMessage id="no_info" />
        </Message>
      ) : (
        <ul>
          {events.map((event: TournamentEvent) => (
            <Match key={event.market_id} event={event} />
          ))}
        </ul>
      )}
    </>
  )
}

const Message = styled.p`
  color: #fff;
  text-align: center;
`
