import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Back = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 54px;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  z-index: 200;
`

export const Ball = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 54px;
  border-radius: 3px;
  overflow: hidden;
  z-index: 200;
`

export const Text = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 54px;
  border-radius: 3px;
  overflow: hidden;
  z-index: 200;
`

export const Letter = styled(motion.div)`
  position: relative;
  width: 1.1em;
  height: 20px;
  color: #fff;
  text-align: center;
  font-size: 3.2em;
  font-weight: 700;
`
