import React from 'react'
import { GameSpeed } from '../../../app/store/app.state'
import { ProgressBarContainer } from './progressBar.style'

export const ProgressBar: React.FC<{
  progress: number
  speed: GameSpeed
}> = React.memo(({ progress, speed }) => (
  <ProgressBarContainer progress={progress} speed={progressSpeed(speed)}>
    <svg width="100%" height="5px">
      <title>Progress Bar</title>
      <defs>
        <linearGradient
          id="gd"
          x1="0"
          x2="60%"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(1)"
        >
          <stop stopColor="#996106" offset="5%" />
          <stop stopColor="#F59F14" offset="95%" />
        </linearGradient>
      </defs>
      <line
        x1="0"
        x2="100%"
        strokeDasharray={`${progress * 1.577}% 200%`}
        stroke="url(#gd)"
      />
    </svg>
  </ProgressBarContainer>
))

export const progressSpeed = (speed: GameSpeed): number => {
  switch (speed) {
    case 'normal':
      return 1000
    case 'fast':
      return 600
    case 'turbo':
      return 250
    default:
      return 0
  }
}
