import { SimMarket, Score, TimeSpan, OneXTwo } from './types'

export const logic1X2 = (outcome: OneXTwo, score: Score) => {
  const oc: { [key in OneXTwo]: () => boolean } = {
    1: () => score[0] > score[1],
    x: () => score[0] === score[1],
    2: () => score[0] < score[1],
  }
  return oc[outcome]() ?? false
}

const oc1X2 = ['1', 'x', '2'] as OneXTwo[]

const _1X2 = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: oc1X2,
  logic: logic1X2,
})

export const FT_1X2 = _1X2(1, 'FT')
export const HT_1X2 = _1X2(12, 'HT')
