import styled from 'styled-components'
import { ExploreButton } from './exploreButton'
import { ExploreItem } from './exploreItem'
import { useMemo, useState } from 'react'
import { SimBet } from '../../../../sports/types'

interface ExploreListProps {
  bets: SimBet[]
}
export const ExploreList: React.FC<ExploreListProps> = ({ bets }) => {
  const [viewMore, setViewMore] = useState(false)

  const betsList = useMemo(
    () => (viewMore ? bets : bets.slice(0, 4)),
    [viewMore, bets]
  )

  return (
    <Wrapper>
      <ul data-testid="explore-list">
        {betsList.map((b: SimBet) => (
          <ExploreItem bet={b} key={b.b9jBet} />
        ))}
      </ul>

      {bets.length > 4 && (
        <ExploreButton setViewMore={setViewMore} viewMore={viewMore} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 8px 4px;
`
