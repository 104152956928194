import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { countDown, CountDown } from './countdown'
import { FreeBet } from '../../api/types'
import {
  selectActivefreeBet,
  selectFreeBetLength,
  selectFreeBets,
} from '../../features/betslip/store/betslip.selectors'
import {
  clearFreeBet,
  deleteFreeBet,
} from '../../features/betslip/store/betslip.state'

export const useCountDown = (freeBet: FreeBet) => {
  const [state, setState] = useState<CountDown>()

  const dispatch = useDispatch()
  const activeFreeBet = useSelector(selectActivefreeBet)

  useEffect(() => {
    const sub = countDown(freeBet?.expiry).subscribe({
      next: (cd) => {
        setState(cd)
        // clear the freeBet if they have expired
        if (cd.output === 'Expired') {
          dispatch(deleteFreeBet(freeBet.free_bet_uid))
          // reset if they are equal
          if (freeBet.free_bet_uid === activeFreeBet.free_bet_uid) {
            dispatch(clearFreeBet())
          }
        }
      },
    })
    return () => sub.unsubscribe()
  }, [freeBet, dispatch, activeFreeBet])

  return state
}

export function useActiveFreeBetCountDown() {
  // get the first freeBet and generate the countdown
  const firstFreeBet = useSelector(selectFreeBets)[0]
  const firstToExpireCountDown = useCountDown(firstFreeBet)

  // get the active freeBet and generate the count down
  const activeFreeBet = useSelector(selectActivefreeBet)
  const activeFreeBetCountDown = useCountDown(activeFreeBet)

  const freeBetLength = useSelector(selectFreeBetLength)

  const displayCountDown = !activeFreeBet.amount
    ? firstToExpireCountDown?.qstk
    : activeFreeBetCountDown?.qstk

  return {
    displayCountDown,
    freeBetLength,
  }
}
