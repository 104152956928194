import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StatsWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colours.S060O};
  width: 100%;
  padding: 12px;
  margin-top: -4px;
  border-radius: 6px;
  overflow: hidden;
`
