import styled from 'styled-components'

export const StyledSpinner = styled.svg<{ size?: string; color?: string }>`
  animation: rotate 2s linear infinite;
  width: ${({ size }) => size || '45px'};
  height: ${({ size }) => size || '45px'};

  & .path {
    stroke: ${({ color }) => color || '#2d2f34'};
    opacity: 0.9;
    stroke-linecap: round;
    animation: dash 1.3s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export const SpinnerWrapper = styled.div<{ height?: string }>`
  display: flex;
  width: 100%;
  height: ${({ height }) => height || '50vh'};
  justify-content: center;
  align-items: center;
  z-index: 300;
`
