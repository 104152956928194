import React from 'react'
import { StatsBarWrapper } from './gameItem.style'
import { splitTeams } from '../../../../lib/utils/uiUtils'

type StatsBarProps = {
  event: string
  won: boolean | string
  firstQuarterResult: number[]
  secondQuarterResult: number[]
  thirdQuarterResult: number[]
  fourthQuarterResult: number[]
}
export const StatsBar: React.FC<StatsBarProps> = React.memo(
  ({
    event,
    won,
    firstQuarterResult,
    secondQuarterResult,
    thirdQuarterResult,
    fourthQuarterResult,
  }) => {
    const teams = splitTeams(event)
    return (
      <StatsBarWrapper won={!!won}>
        <div className="summary">
          <span className="teamName">{teams.team1}</span>
          <span className="score">
            <span className={`digit ${won ? 'bold' : ''}`}>
              {firstQuarterResult[0]}
            </span>
            <span className={`digit ${won ? 'bold' : ''}`}>
              {secondQuarterResult[0]}
            </span>
            <span className={`digit ${won ? 'bold' : ''}`}>
              {thirdQuarterResult[0]}
            </span>
            <span className={`digit ${won ? 'bold' : ''}`}>
              {fourthQuarterResult[0]}
            </span>
          </span>
        </div>
        <div className="summary">
          <span className="teamName">{teams.team2}</span>
          <span className="score">
            <span className={`digit ${won ? 'bold' : ''}`}>
              {firstQuarterResult[1]}
            </span>
            <span className={`digit ${won ? 'bold' : ''}`}>
              {secondQuarterResult[1]}
            </span>
            <span className={`digit ${won ? 'bold' : ''}`}>
              {thirdQuarterResult[1]}
            </span>
            <span className={`digit ${won ? 'bold' : ''}`}>
              {fourthQuarterResult[1]}
            </span>
          </span>
        </div>
      </StatsBarWrapper>
    )
  }
)

StatsBar.displayName = 'StatsBar'
