import { useSelector } from 'react-redux'
import { IntlMessage } from '../../../../lang/IntlMessage'
import { selectToWin } from '../../../betslip/store/betslip.selectors'
import { Wrapper } from './winBar.style'

export const WinBar = () => {
  const toWin = useSelector(selectToWin)
  return (
    <Wrapper>
      <span className="heading">
        <IntlMessage id="winBar_heading" />
      </span>
      <span className="winAmount">{toWin.toFixed(2).toLocaleString()}</span>
    </Wrapper>
  )
}
