import { StyledCouponBet } from './couponBet.style'
import { BetListSelection } from '../../../../api/types'
import { findMarketFromID } from '../../../../sports/marketUtils'
import {
  showOutcome,
  showMarket,
  titleCaseSentence,
} from '../../../../lib/utils/uiUtils'
import { getSpread } from '../../../../sports/adapter'

interface CouponBetProps {
  selection: BetListSelection
  idx: number
}
export const CouponBet: React.FC<CouponBetProps> = ({ selection, idx }) => {
  const {
    event_name,
    selected_option,
    market_id,
    odd,
    full_time_result,
    half_time_result,
    sport_id,
  } = selection
  const spread = getSpread(undefined, undefined, market_id, sport_id)
  return (
    <StyledCouponBet idx={idx} data-testid="coupon-bet">
      <div>
        <div className="coupon-bet">
          <span className="emphasis">
            {showOutcome(selected_option, event_name, spread)}
          </span>
          <span>{showMarket(findMarketFromID(market_id, sport_id))}</span>
        </div>
        <div className="event">
          <span className="event-icon">
            {sport_id === 1 && '⚽'}
            {sport_id === 2 && '🏀'}
          </span>
          <span className="event-name">{titleCaseSentence(event_name)}</span>
        </div>
      </div>
      <div className="results">
        <div className="odd">{odd}</div>
        <span>
          ({half_time_result}) {full_time_result}
        </span>
      </div>
    </StyledCouponBet>
  )
}
