import { TMessages } from './types'

export const messagesInSpanish: TMessages = {
  acceptOdds_changes: 'Aceptar cambios de cuotas',
  couponDetail_receipt: 'Reimprimir recibo de apuesta',
  couponDetail_odds: 'Probabilidades',
  couponHead_freeBet: 'Apuesta gratuita',
  couponHead_won: 'Ganó',
  couponHead_lost: 'Perdido',
  emptyNotif_title: 'Sus notificaciones aparecerán aquí',
  emptyNotif_subtitle:
    'Recibir información importante y cualquier otra actualización relevante para usted',
  stats_explore: 'Estadísticas de simulación de mercado',
  explore_soccerHeading: 'Las fútbol más populares',
  explore_basketHeading: 'Las baloncesto más populares',
  explore_leagueHeading: 'Top {value1} Apuestas',
  tournament_Heading: 'Mercados más populares de la Premier League',
  explorePage_msg: 'Actualmente no hay información para mostrar aquí.',
  filter_won: 'Ganó',
  filter_lost: 'Perdido',
  filter_all: 'Todos',
  freeBetTicket_won: 'HA GANADO UN',
  freeBetTicket_rebet: 'REBOTE GRATUITO!',
  freeBetTicket_info: 'Pague una vez y juegue dos veces',
  freeRebet_txt: 'Rebote gratuito',
  freeBetNoContent_heading: 'Siga jugando para recibir apuestas gratuitas!',
  freeBetContent_title: 'Apuesta gratuita',
  freeBetContent_expiry: 'Expira en',
  freeBetContent_btn: '¡Usa esto!',
  gameDisplay_heading: 'RESULTADOS QUE LLEGAN',
  gameFooter_yes: '¡SI!',
  gameFooter_betWon: 'APUESTA GANADA',
  gameFooter_done: 'Hecho',
  gameFooter_rebet: 'Rebote',
  gameFooter_freeBet: 'Apuesta gratuita',
  inputStake_amount: 'Introduzca el importe',
  loader_printingTicket: 'Impresión del billete',
  loader_simulating: 'Simulando',
  loader_info: 'Por favor, espere',
  togglePrintButton_title: 'Imprimir boleto de apuestas',
  myBetsNav_myBets: 'Mis apuestas',
  myBetsNav_freeBets: 'Apuestas gratuitas disponibles',
  navBar_linkExplore: 'Explore',
  navBar_linkBetSlip: 'Boleto de apuestas',
  navBar_linkMyBets: 'Mis apuestas',
  navBar_linkReport: 'Reporte',
  navBar_linkTournament: 'Eng Premier',
  placeBetButton_freeBet: 'Colocar la apuesta gratis simulada',
  placeBetButton_bet: 'Colocar la apuesta',
  quickFreeBet_freeBet: 'APUESTA GRATUITA',
  receipt_not: 'No',
  receipt_certified: 'Certificado',
  receipt_copy: 'Copiar',
  receipt_originalTicket: 'BILLETE ORIGINAL',
  receipt_ticketCopy: 'COPIA DEL BILLETE',
  receipt_user: 'USUARIO#',
  receipt_ticket: 'TICKET#',
  receipt_selection: 'SELECCIONES',
  receipt_totalOdds: 'PROBABILIDADES TOTALES',
  receipt_totalStake: 'PARTICIPACIÓN TOTAL',
  receipt_potWin: 'POT. GANA',
  reportBox_lastUpdated: 'Última actualización',
  reportBox_turnOver: 'Vuelta atrás',
  reportBox_paidOut: 'Pagado',
  reportBox_tickets: 'Tickets',
  reportBox_totalProfit: 'Beneficio total',
  reportsFilter_thisWeek: 'Esta semana',
  reportsFilter_yesterday: 'Ayer',
  reportsFilter_today: 'Hoy',
  scrollElement_info: 'Introduzca Stake para continuar',
  scrollElement_odds: 'Probabilidades',
  unsupportedSelection_info1:
    'Este mercado de apuestas aún no es compatible con la Simulación',
  unsupportedSelection_info2: 'No se incluirá en su apuesta',
  selectionsBar_sel: 'Selecciones',
  selectionsBar_addMore: '+ Añadir más',
  speedDrawer_normalSpeed: 'Velocidad normal',
  speedDrawer_fastSpeed: 'Velocidad rápida',
  speedDrawer_turboSpeed: 'Velocidad turbo',
  totalOdds_heading: 'Total de probabilidades',
  winBar_heading: 'Ganancia potencial',

  notificationItem_msgFreeBet: '¡Tienes una apuesta gratis!',
  notificationItem_msgFreeBetBtn: '¡Úsalo!',
  notificationItem_msgFreeBets: '¡Tienes algunas apuestas gratuitas!',
  notificationItem_msgFreeBetsBtn: '¡Muéstrame!',
  notificationItem_msgFreeBetSelected:
    '{value1} {value2} ¡apuesta gratuita seleccionada!',
  exploreBanner_btn: '¡Vamos a verlo!',
  exploreBanner_heading: '¡TODO NUEVO!',
  exploreBanner_title: 'Baloncesto',

  //reusable
  no_info: 'Actualmente no hay información para mostrar aquí.',
  btnContinue: 'OK Continuar',
  clear: 'Borrar',
  done: 'Hecho',

  // errors
  errorTest: 'test error',
  error1000: 'No hay selección para Simular.',
  error500: 'Oops, un problema ocurrió...',
  error401: 'La sesión ha caducado.',
  error403: 'Unsuccessful login.',
  error404: 'Inicio de sesión fallido.',
  error408: 'Tiempo de espera de la solicitud.',
  error422: 'Betslip inválido.',
  error424: 'Sus selecciones han cambiado.',
  error425: 'Freebet no es válida o ha expirado.',
  errorPM: 'inválido post message',
  errorSelectionNum: 'El número máximo de selecciones es de 40.',
  errorNoSelection: 'No hay selecciones',
  errorMinStake: 'Minimum Stake is {value1} {value2}',
  errorMaxStake: 'La apuesta máxima es {value1} {value2}',
  errorMaxWin: 'Ganancias de más de {value1} {value2} superan el máximo.',
  errorAccountStatus_2: 'Ganancias no pagadas',
  errorAccountStatus_1: 'No hay fondos suficientes',
  errorAccountStatus0: 'No hay dinero pagado',
  errorAccountStatus1: 'Apuesta pagada',
  errorAccountStatus2: 'Ganar pagado',
  errorBetStatus_10: 'Apuesta no realizada',
  errorBetStatus_1: 'Perdido',
  errorBetStatus0: 'Ejecutar',
  errorBetStatus1: 'Ganó',
}
