import * as Sim from './soccer/markets'
import * as SimBasketball from './basketball/markets'

import {
  B9jSoccerMap,
  findSoccerOutcome,
} from './soccer/maps/bet9ja/b9jSoccerMap'
import {
  B9jZoomSoccerMap,
  findZoomOutcome,
} from './soccer/maps/bet9ja/b9jZoomMap'
import {
  ISolSoccerMap,
  findISolOutcome,
} from './soccer/maps/iSol/iSolSoccerMap'
import {
  B9jBasketballMap,
  findBasketballOutcome,
} from './basketball/maps/bet9ja/b9jBasketballMap'
import {
  ISolBasketballMap,
  findIsolBasketballOutcome,
} from './basketball/maps/iSol/iSolBasketballMap'
import { Source } from './types'
import { findSimMarketId } from './marketUtils'

type Client = {
  b9j: SportDefinition
  isol: SportDefinition
}
export type SportDefinition = {
  name: SportName
  sportId: number
  marketMap: Map<unknown, Sim.Market | SimBasketball.Market>
  // TODO CREATE THE UNION OF ALL OUTCOMES TO TYPE
  outcomeMap: (mkt: any, outcome: any) => Sim.Outcome | SimBasketball.Outcome
  findSimMarketId: (market: any, sport_id: number) => number | undefined
}
export type SportsCode = 'S' | 'VS' | 'B'
export type SportName = 'Soccer' | 'Z.Soccer' | 'Basketball'

export const getSport = (src: Source, sport: SportsCode) => {
  if (src === 'old-mobile' || src === 'desktop' || src === 'self-service')
    return Sports.get(sport)?.isol
  return Sports.get(sport)?.b9j
}

/** Maps sports to logic */
export const Sports = new Map<SportsCode, Client>()

Sports.set('S', {
  b9j: {
    name: 'Soccer',
    sportId: 1,
    marketMap: B9jSoccerMap,
    outcomeMap: findSoccerOutcome,
    findSimMarketId: findSimMarketId,
  },
  isol: {
    name: 'Soccer',
    sportId: 1,
    marketMap: ISolSoccerMap,
    outcomeMap: findISolOutcome,
    findSimMarketId: findSimMarketId,
  },
})

Sports.set('VS', {
  b9j: {
    name: 'Z.Soccer',
    sportId: 1,
    marketMap: B9jZoomSoccerMap,
    outcomeMap: findZoomOutcome,
    findSimMarketId: findSimMarketId,
  },
  isol: {
    name: 'Z.Soccer',
    sportId: 1,
    marketMap: ISolSoccerMap,
    outcomeMap: findISolOutcome,
    findSimMarketId: findSimMarketId,
  },
})

Sports.set('B', {
  b9j: {
    name: 'Basketball',
    sportId: 2,
    marketMap: B9jBasketballMap,
    outcomeMap: findBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
  isol: {
    name: 'Basketball',
    sportId: 2,
    marketMap: ISolBasketballMap,
    outcomeMap: findIsolBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
})
