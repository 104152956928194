export const fakeToken =
  '0C1DC1A0EC8F8F3F03D25D5907E7E18348C68BF28611DBC8E8350552A52C0CD6'

export const fakeOTPs = {
  nofail_a: '473c40ac22fc8bd19674b9b778cc42d2',
  nofail_b: '673c40ac22fc8bd19674b9b778cc42d2',
  nofail_c: '773c40ac22fc8bd19674b9b778cc42d2',
  nofail_d: '573c40ac22fc8bd19674b9b778cc42d2',
  onePercentfail_a: '973c40ac22fc8bd19674b9b778cc42d2',
  onePercentfail_b: '073c40ac22fc8bd19674b9b778cc42d2',
  thirtyThreePercentfail: '873c40ac22fc8bd19674b9b778cc42d2',
  ninetyNinePercentfail: '873c40ac22fc8bd19674b9b778cc42d3',
}
