import { SimMarket, Score, TimeSpan, TwoWay } from './types'

export const logicTwoWay = (outcome: TwoWay, score: Score) => {
  const oc: { [key in TwoWay]: () => boolean } = {
    1: () => score[0] > score[1],
    2: () => score[0] < score[1],
  }

  return oc[outcome]() ?? false
}

const ocTwoWay = ['1', '2'] as TwoWay[]

const _TwoWay = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocTwoWay,
  logic: logicTwoWay,
})

export const FT_TWO_WAY = _TwoWay(2001, 'FT')
