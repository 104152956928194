import { Clock } from '../../../../components/svgs'
import { EventHeaderWrapper } from '../../pages/gamePage.style'
import { useTimeline } from '../../../../hooks/useTimeline'
import { SimSports } from '../../../../config'
import { useSelector } from 'react-redux'
import { selectSpeed } from '../../../app/store/app.state'
import { ProgressBar } from '../progress-bar/progressBar'

type EventHeaderProps = {
  icon: JSX.Element
  sport: SimSports
}

export const EventHeader: React.FC<EventHeaderProps> = ({ icon, sport }) => {
  const { timeline } = useTimeline(sport)
  const speed = useSelector(selectSpeed)
  const progress =
    sport === 'Basketball' ? timeline?.clock * 1.875 || 0 : timeline?.clock || 0

  return (
    <>
      <EventHeaderWrapper>
        <div className="box">
          <div>{icon}</div>
          <div className="eventName">{sport}</div>
        </div>
        <div className="box">
          <div className="time">{timeline?.segment}</div>
          <span className="bold">{timeline?.clock}</span>
          <div className="icon-clock">
            <Clock width="16" height="16" viewBox="0 -2 24 24" />
          </div>
        </div>
      </EventHeaderWrapper>
      <ProgressBar progress={progress} speed={speed} />
    </>
  )
}
