import React from 'react'
import { Checkthick } from '../../../../components/svgs'
import { Status, Icon } from './gameItem.style'

interface IGameStatusProps {
  outcome: string
  market: string
  winning: boolean | string
  won: boolean | string
}
export const StatusBar: React.FC<IGameStatusProps> = React.memo(
  ({ outcome, market, winning, won }) => (
    <Status won={!!won}>
      <span className="outcome">{outcome} </span>
      <span className="market">{market}</span>

      {winning && (
        <Icon>
          <Checkthick width="24" height="24" viewBox="-14 -8 46 46" />
        </Icon>
      )}
    </Status>
  )
)

StatusBar.displayName = 'StatusBar'
