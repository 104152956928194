import styled from 'styled-components'

export const Box = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-height: 23px;
`

export const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const VisibleCheckBox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid ${(props) => props.theme.colours.N040};
  background-color: ${(props) => props.theme.colours.S090};
  border-radius: 3px;

  svg {
    fill: none;
    stroke: ${(props) => props.theme.colours.N040};
    stroke-width: 4;
  }
`

export const Label = styled.span`
  margin-right: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colours.N040};
`
