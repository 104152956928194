import styled from 'styled-components'

export const BannerWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  background-color: #ee8125;
  margin: 4px;
  border: 1px solid #d1d511;
  border-radius: 3px;

  .circle {
    position: relative;
    height: 100%;
    margin-left: auto;

    &_line {
      height: 100%;
      width: 0.7px;
      background: #fff;
    }

    &_small {
      position: absolute;
      width: 9.92px;
      height: 9.92px;
      left: calc(50% - 9.92px / 2);
      top: calc(50% - 9.92px / 2);
      border: 0.7px solid #fff;
      border-radius: 50%;
    }
    &_big {
      position: absolute;
      width: 23.66px;
      height: 23.66px;
      left: calc(50% - 23.66px / 2);
      top: calc(50% - 23.66px / 2);
      border: 0.7px solid #fff;
      border-radius: 50%;
    }
  }

  .banner {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    margin-left: auto;

    &_heading {
      font-family: ${(props) => props.theme.fonts[2]};
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      text-transform: uppercase;
      margin: 0;
    }

    &_game {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -3px;
    }
    &_icon {
      filter: drop-shadow(0px 2.71612px 0px rgba(0, 0, 0, 0.09));
    }

    &_title {
      font-size: 25px;
      line-height: 25px;
      font-family: ${(props) => props.theme.fonts[2]};
      color: ${({ theme }) => theme.colours.AP050};
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      text-shadow: -1px -1px black, 1px 1px black, 1px -1px black,
        -1px 1px black, 0px 2.71612px 0px rgba(0, 0, 0, 0.09);
    }
  }

  .btn {
    width: 84px;
    height: 24px;
    font-weight: 900;
    font-size: 12px;
    color: ${(props) => props.theme.colours.ST100};
    background: ${(props) => props.theme.colours.AP050G};
    border: 0.7px solid ${(props) => props.theme.colours.AP080};
    box-shadow: 0px 3px 0px #b5892e, 0px 6px 3px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-left: auto;
    cursor: pointer;
  }

  .delete {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: rgba(23, 24, 28, 0.1);
    margin-left: auto;
    cursor: pointer;

    path {
      fill: ${({ theme }) => `${theme.colours.N040}`};
    }
  }

  .stripe {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    background: rgba(72, 74, 85, 0.1);
    height: 26px;
    width: 100%;
  }
`
