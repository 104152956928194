import { useSelector } from 'react-redux'
import { filterReports, selectReportsRange } from '../../store/reports.state'
import {
  FilterWrapper,
  FilterContainer,
  SelectorUnderlay,
  Selector,
} from './reportFilter.style'
import { Range } from '../../store/reports.state'
import { useAppDispatch } from '../../../../store/store'
import { IntlMessage } from '../../../../lang/IntlMessage'

export const ReportsFilter = () => {
  const dispatch = useAppDispatch()
  const range = useSelector(selectReportsRange)

  return (
    <FilterWrapper>
      <FilterContainer>
        <SelectorUnderlay range={range} />
        <Selector
          onClick={() => dispatch(filterReports(Range.LASTSEVENDAYS))}
          selected={range === Range.LASTSEVENDAYS}
        >
          <IntlMessage id="reportsFilter_thisWeek" />
        </Selector>
        <Selector
          onClick={() => dispatch(filterReports(Range.YESTERDAY))}
          selected={range === Range.YESTERDAY}
        >
          <IntlMessage id="reportsFilter_yesterday" />
        </Selector>
        <Selector
          onClick={() => dispatch(filterReports(Range.TODAY))}
          selected={range === Range.TODAY}
        >
          <IntlMessage id="reportsFilter_today" />
        </Selector>
      </FilterContainer>
    </FilterWrapper>
  )
}
