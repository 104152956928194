import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Odo = styled.div`
  width: 2.5em;
  height: 2.2em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OdoSlider = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OdoNum = styled(motion.div)<{ won: boolean }>`
  ${({ won, theme }) =>
    css`
      font-family: ${(props) => props.theme.fonts[0]};
      font-size: ${won ? '14px' : '16px'};
      font-weight: 700;
      /* line-height: 26px; */
      color: ${won ? theme.colours.ST000 : theme.colours.N030};
    `}
`

export default Odo
