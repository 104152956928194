import styled from 'styled-components'

export const Container = styled.div`
  /* position: relative; */
  position: fixed;
  top: 0px;
  z-index: 5;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 43px;
  min-width: 320px;
  max-width: 1024px;
  background-image: url('/static/images/sportsbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin: auto;
`
