import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background-color: ${(props) => props.theme.colours.S050};
  color: ${({ theme }) => theme.colours.N040};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`

export const ButtonStats = styled.button`
  color: ${(props) => props.theme.colours.AP050};
  background: transparent;
  line-height: 16px;
  font-weight: bold;
  border: none;
  outline: none;
  padding: 0;
  align-self: flex-start;
  cursor: pointer;
`
