import styled from 'styled-components'

export const StyledFlare = styled.div`
  position: absolute;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 3px;
  z-index: 500;
`
