import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { createError } from '../../app/store/app.state'
import { AppThunk, RootState } from '../../../store/store'

import { getReports } from '../api/getReports'
import { APIStatus, ErrorResponse, ReportResponse } from '../../../api/types'

export type TReportRange = 1 | 2 | 3

export enum Range {
  TODAY = 1,
  YESTERDAY = 2,
  LASTSEVENDAYS = 3,
}

type ReportsSlice = {
  status: APIStatus
  range: TReportRange
  data: ReportResponse
}

export const initialState: ReportsSlice = {
  status: 'idle',
  range: 1,
  data: {
    gen_date: '',
    total_stake: 0,
    number_bets: 0,
    total_win: 0,
    total_profit: 0,
  },
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<APIStatus>) => {
      state.status = payload
    },
    setData: (state, { payload }: PayloadAction<ReportResponse>) => {
      state.data = {
        ...payload,
        total_profit: payload.total_stake - payload.total_win,
      }
      state.status = 'idle'
    },
    setRange: (state, { payload }: PayloadAction<TReportRange>) => {
      state.range = payload
    },
  },
})

export default reportsSlice.reducer

// actions --------------------------------------------------------
export const { setStatus, setData, setRange } = reportsSlice.actions

// thunks --------------------------------------------------------
export const createReport = (): AppThunk => async (dispatch, getState) => {
  const state = getState()
  const { sessionID } = state.app
  const { range } = state.reports

  dispatch(setStatus('loading'))

  try {
    const response = await getReports(range, sessionID)
    dispatch(setData(response))
  } catch (e) {
    dispatch(setStatus('error'))
    dispatch(createError(e as ErrorResponse))
  }
}

export const filterReports =
  (range: TReportRange): AppThunk =>
  async (dispatch, getState) => {
    const { status } = getState().reports
    if (status !== 'loading') {
      dispatch(setRange(range))
      dispatch(createReport())
    }
  }

// Selectors -------------------------------------------------------
export const selectReport = (state: RootState) => state.reports.data
export const selectReportsRange = (state: RootState) => state.reports.range
export const selectReportsStatus = (state: RootState) => state.reports.status
