import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 58px;
  left: 4px;
  right: 4px;
  z-index: 20;
  max-width: 312px;
  margin: auto;
  box-shadow: 0px 11px 22px #000000;
`
