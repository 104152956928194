import React from 'react'
import { Flare } from '../flare/flare'
import { Goal } from '../goal/goal'
import { ScoreBar } from './scoreBar'
import { StatusBar } from './statusBar'
import { Item } from './gameItem.style'
import { SelectionResponse } from '../../../../api/types'
import { findMarketFromID } from '../../../../sports/marketUtils'
import { showOutcome, showMarket } from '../../../../lib/utils/uiUtils'
import { useItemState } from '../../../../hooks/useItemState'
import * as SimSoccer from '../../../../sports/soccer/markets'

interface IProps {
  selection: SelectionResponse
}
export const SoccerItem: React.FC<IProps> = ({ selection }) => {
  const marketName = findMarketFromID(selection.market_id, selection.sport_id)
  const { goal, flare, winning, won, score, halftimeResult } = useItemState(
    selection,
    marketName as SimSoccer.Market
  )
  const outcome = showOutcome(selection.selected_option, selection.event_name)
  const market = showMarket(marketName)

  return (
    <Item data-testid="soccer-item" won={!!won}>
      {flare && <Flare />}
      {goal && <Goal />}
      <ScoreBar
        won={won}
        score={score}
        event={selection.event_name}
        halftimeResult={halftimeResult}
        sport={selection.sport_id}
      />
      <StatusBar
        outcome={outcome}
        market={market}
        winning={winning}
        won={won}
      />
    </Item>
  )
}
