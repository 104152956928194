import { SimMarket, Score, TimeSpan, CMOU } from './types'
import { logic1X2 } from './1X2'
import { logicOU } from './OU'

export const logicCMOU =
  (handicap: number) => (outcome: CMOU, score: Score) => {
    const oc: { [key in CMOU]: () => boolean | 'settled' } = {
      '1 or ov': () => logic1X2('1', score) || logicOU(handicap)('o', score),
      'x or ov': () => logic1X2('x', score) || logicOU(handicap)('o', score),
      '2 or ov': () => logic1X2('2', score) || logicOU(handicap)('o', score),
      '1 or un': () => logic1X2('1', score) || logicOU(handicap)('u', score),
      'x or un': () => logic1X2('x', score) || logicOU(handicap)('u', score),
      '2 or un': () => logic1X2('2', score) || logicOU(handicap)('u', score),
    }
    return oc[outcome]() ?? false
  }

const ocCMOU = [
  '1 or ov',
  'x or ov',
  '2 or ov',
  '1 or un',
  'x or un',
  '2 or un',
] as CMOU[]

const _CMOU = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocCMOU,
  logic: logicCMOU(handicap),
})

export const FT_CMOU_15 = _CMOU(15, 'FT', 1.5)
export const FT_CMOU_25 = _CMOU(16, 'FT', 2.5)
export const FT_CMOU_35 = _CMOU(17, 'FT', 3.5)
