import { BetListResponse } from '../../../api/types'
import { switchOnFilter, errorHandling } from '../../../api/utils'
import { targetUrl } from '../../../config'
import { FilterList } from '../store/mygames.state'

/**
 * GET: retreive every bets placed by the user,
 * @param nexttoken token used for next page
 * @param sessionID user session ID
 * @param filter fetch won = 1, lost = -1, or both = 0
 */
export const getBetlist = async (
  nexttoken: string,
  sessionID: string,
  filter: FilterList
): Promise<BetListResponse> => {
  const response = await fetch(
    `${targetUrl()}/simulate/BetList?nexttoken=${nexttoken}&filter=${switchOnFilter(
      filter
    )}`,
    {
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-Type': 'application/json',
        'X-SID': `${sessionID}`,
      },
    }
  ).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}
