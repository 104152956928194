import * as SimBasketball from './basketball/markets'
import * as SimSoccer from './soccer/markets'
import { simulateBasketballMarkets } from './basketball/markets/marketMap'
import { simulateMarkets as simulateSoccerMarkets } from './soccer/markets/marketMap'

/**
 * Determine if a market is currently winning.
 * Uses the market map.
 * Uses partial application passing :
 * @param outcome simulate outcome partially applied
 * @param marketName simulate market
 */
export const winningBet =
  (
    outcome: SimBasketball.Outcome | SimSoccer.Outcome,
    marketName: SimSoccer.Market | SimBasketball.Market,
    sport_id: number
  ) =>
  (score: number[], time?: SimBasketball.TimeSpan): boolean | 'settled' => {
    if (sport_id === 2)
      return (
        simulateBasketballMarkets
          .get(marketName as SimBasketball.Market)
          ?.logic(outcome, score, time) || false
      )

    return (
      simulateSoccerMarkets
        .get(marketName as SimSoccer.Market)
        ?.logic(outcome, score) || false
    )
  }

/**
 * Retreive a market name from its id, sses the market map.
 * @param marketID as defined by simulate backend
 */
export const findMarketFromID = (
  marketID: number,
  sport_id: number
): SimSoccer.Market | SimBasketball.Market => {
  if (sport_id === 2) {
    const marketArray = Array.from(simulateBasketballMarkets.keys())
    const find = marketArray.find(
      (m) => simulateBasketballMarkets.get(m)?.id === marketID
    )
    return find || ('' as SimBasketball.Market)
  }
  const marketArray = Array.from(simulateSoccerMarkets.keys())
  const find = marketArray.find(
    (m) => simulateSoccerMarkets.get(m)?.id === marketID
  )
  return find || ('' as SimSoccer.Market)
}

export const findSimMarketId = (
  market: SimSoccer.Market | SimBasketball.Market,
  sport_id: number
): number | undefined => {
  if (sport_id === 2)
    return simulateBasketballMarkets.get(
      (market as SimBasketball.Market) || ('' as SimBasketball.Market)
    )?.id

  return simulateSoccerMarkets.get(
    (market as SimSoccer.Market) || ('' as SimSoccer.Market)
  )?.id
}
