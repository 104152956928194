import { AnimatePresence } from 'framer-motion'
import { useSelector } from 'react-redux'
import { getEnv } from '../../../../config'
import { IntlMessage } from '../../../../lang/IntlMessage'
import { resetSoccerTimeLine } from '../../../../sports/soccer/timeline/soccerTimeline'
import { changeView, selectIsCashier } from '../../../app/store/app.state'
import { selectFreeBets } from '../../../betslip/store/betslip.selectors'
import {
  placeBet,
  resetGameData,
  selectGameFooterInfos,
} from '../../store/game.state'
import { useAppDispatch } from '../../../../store/store'
import { FreeRebet } from '../free-rebet/freeRebet'
import { Checkthick } from '../../../../components/svgs'
import { Won, Wrapper, ButtonDone, RebetButton } from './gameFooter.style'

export const GameFooter = () => {
  const dispatch = useAppDispatch()
  const { currency } = getEnv()
  const isCashier = useSelector(selectIsCashier)
  const freeBetsList = useSelector(selectFreeBets)

  const { stake, status, wasFreeBet, win, visible, freeBetAmount, view } =
    useSelector(selectGameFooterInfos)

  const handleDone = () => {
    if (view !== 'betslip') {
      dispatch(changeView('betslip'))
    }
    dispatch(resetGameData())
    resetSoccerTimeLine()
  }

  const handleBetAgain = () => {
    dispatch(placeBet())
  }

  if (visible && isCashier && freeBetsList.length > 0) {
    return (
      <>
        {status === 1 && (
          <Won
            animate={{ y: -81 }}
            initial={{ y: 90 }}
            exit={{ y: 90 }}
            transition={{ ease: 'linear', duration: 0.2 }}
          >
            <div className="container">
              <div className="won-yes">
                <IntlMessage id="gameFooter_yes" />
              </div>
              <div className="won-txt">
                <IntlMessage id="gameFooter_betWon" />
                <Checkthick
                  width="16"
                  height="16"
                  viewBox="2 -5 28 28"
                  fill="#fff"
                />
              </div>
              <div className="won-amount">
                {currency} {win.toFixed(2).toLocaleString()}
              </div>
            </div>
          </Won>
        )}

        <Wrapper
          data-testid="component-game-Footer"
          animate={{ y: 1 }}
          initial={{ y: 45 }}
          exit={{ y: 45 }}
          status={status}
          transition={{ ease: 'linear', duration: 0.2 }}
        >
          <FreeRebet />
        </Wrapper>
      </>
    )
  }

  return (
    <AnimatePresence>
      {visible && (
        <>
          {status === 1 && (
            <Won
              animate={{ y: -72 }}
              initial={{ y: 90 }}
              exit={{ y: 90 }}
              transition={{ ease: 'linear', duration: 0.2 }}
            >
              <div className="container">
                <div className="won-yes">
                  <IntlMessage id="gameFooter_yes" />
                </div>
                <div className="won-txt">
                  <IntlMessage id="gameFooter_betWon" />
                  <Checkthick width="16" height="16" viewBox="2 -5 28 28" />
                </div>
                <div className="won-amount">
                  {currency} {win.toFixed(2).toLocaleString()}
                </div>
              </div>
            </Won>
          )}
          <Wrapper
            data-testid="component-game-Footer"
            animate={{ y: 0 }}
            initial={{ y: 45 }}
            exit={{ y: 45 }}
            status={status}
            transition={{ ease: 'linear', duration: 0.2 }}
          >
            <ButtonDone
              data-testid="game-done"
              onClick={handleDone}
              width={wasFreeBet ? '100%' : '33%'}
              className="footer-btn"
            >
              <IntlMessage id="gameFooter_done" />
            </ButtonDone>

            {!wasFreeBet && !freeBetAmount && (
              <RebetButton onClick={handleBetAgain} className="footer-btn">
                {freeBetAmount ? (
                  <>
                    <IntlMessage id="gameFooter_freeBet" />: {currency}{' '}
                    {freeBetAmount.toLocaleString()}
                  </>
                ) : (
                  <>
                    <IntlMessage id="gameFooter_rebet" /> ({currency}{' '}
                    {stake.toLocaleString()})
                  </>
                )}
              </RebetButton>
            )}
          </Wrapper>
        </>
      )}
    </AnimatePresence>
  )
}
