import { TMessages } from './types'

export const messagesInEnglish: TMessages = {
  acceptOdds_changes: 'Accept odds changes',
  couponDetail_receipt: 'Re-Print Bet Receipt',
  couponDetail_odds: 'Odds',
  couponHead_freeBet: 'Freebet',
  couponHead_won: 'Won',
  couponHead_lost: 'Lost',
  emptyNotif_title: 'Your notifications will appear here',
  emptyNotif_subtitle:
    'Receive important information and any other updates relevant to you',
  stats_explore: 'Market simulation stats',
  explore_soccerHeading: 'Top Popular Football Bets',
  explore_basketHeading: 'Top Popular Basketball Bets',
  explore_leagueHeading: 'Top {value1} Bets',
  tournament_Heading: ' Most Popular Premier League Markets',
  explorePage_msg: 'There is currently no information to display here.',
  filter_won: 'Won',
  filter_lost: 'Lost',
  filter_all: 'All',
  freeBetTicket_won: 'YOU WON A',
  freeBetTicket_rebet: 'FREE REBET!',
  freeBetTicket_info: 'Pay Once Play Twice',
  freeRebet_txt: 'free rebet',
  freeBetNoContent_heading: 'Keep playing to be awarded free bets!',
  freeBetContent_title: 'Free Bet',
  freeBetContent_expiry: 'Expires in',
  freeBetContent_btn: 'Use this!',
  gameDisplay_heading: 'RESULTS COMING IN',
  gameFooter_yes: 'YES!',
  gameFooter_betWon: 'BET WON',
  gameFooter_done: 'Done',
  gameFooter_rebet: 'Rebet',
  gameFooter_freeBet: 'Free Bet',
  inputStake_amount: 'Enter Amount',
  loader_printingTicket: 'Printing Ticket',
  loader_simulating: 'Simulating',
  loader_info: 'Please Wait',
  togglePrintButton_title: 'Print Betslip',
  myBetsNav_myBets: 'My Bets',
  myBetsNav_freeBets: 'Free Bets Available',
  navBar_linkExplore: 'Explore',
  navBar_linkBetSlip: 'Bet Slip',
  navBar_linkMyBets: 'My Bets',
  navBar_linkReport: 'Report',
  navBar_linkTournament: 'Eng Premier',
  placeBetButton_freeBet: 'Place Simulate Free Bet',
  placeBetButton_bet: 'Place Bet',
  quickFreeBet_freeBet: 'FREE BET',
  receipt_not: 'Not',
  receipt_certified: 'Certified',
  receipt_copy: 'Copy',
  receipt_originalTicket: 'ORIGINAL TICKET',
  receipt_ticketCopy: 'TICKET COPY',
  receipt_user: 'USER#',
  receipt_ticket: 'TICKET#',
  receipt_selection: 'SELECTIONS',
  receipt_totalOdds: 'TOTAL ODDS',
  receipt_totalStake: 'TOTAL STAKE',
  receipt_potWin: 'POT. WIN',
  reportBox_lastUpdated: 'Last Updated',
  reportBox_turnOver: 'Turn Over',
  reportBox_paidOut: 'Paid Out',
  reportBox_tickets: 'Tickets',
  reportBox_totalProfit: 'Total Profit',
  reportsFilter_thisWeek: 'This Week',
  reportsFilter_yesterday: 'Yesterday',
  reportsFilter_today: 'Today',
  scrollElement_info: 'Enter Stake to continue',
  scrollElement_odds: 'Odds',
  unsupportedSelection_info1:
    'This betting market is not supported by Simulate yet',
  unsupportedSelection_info2: 'It will not be included in your bet',
  selectionsBar_sel: 'Selections',
  selectionsBar_addMore: '+ Add more',
  speedDrawer_normalSpeed: 'Normal speed',
  speedDrawer_fastSpeed: 'Fast speed',
  speedDrawer_turboSpeed: 'Turbo speed',
  totalOdds_heading: 'Total Odds',
  winBar_heading: 'Potential Win',
  exploreBanner_btn: 'Lets see it!',
  exploreBanner_heading: 'ALL NEW ADDITION!',
  exploreBanner_title: 'Basketball',

  notificationItem_msgFreeBet: 'You have a freebet!',
  notificationItem_msgFreeBetBtn: 'Use it!',
  notificationItem_msgFreeBets: 'You have some freebets!',
  notificationItem_msgFreeBetsBtn: 'Show me!',
  notificationItem_msgFreeBetSelected: '{value1} {value2} free bet selected!',

  //reusable
  no_info: 'There is currently no information to display here.',
  btnContinue: 'OK Continue',
  clear: 'Clear',
  done: 'Done',

  // errors
  errorTest: 'test error',
  error1000: 'No selection to Simulate.',
  error500: 'Oops, a problem occured...',
  error401: 'Session expired.',
  error403: 'Unsuccessful login.',
  error404: 'Page Not found.',
  error408: 'Request Timeout.',
  error422: 'Invalid Betslip.',
  error424: 'Your selections have changed.',
  error425: 'FreeBet is not valid or has expired.',
  errorPM: 'invalid post message',
  errorSelectionNum: 'The maximum number of selection is 40.',
  errorNoSelection: 'No Selections',
  errorMinStake: 'Minimum Stake is {value1} {value2}',
  errorMaxStake: 'Maximum Stake is {value1} {value2}',
  errorMaxWin: 'Winnings of over {value1} {value2} exceed the maximum.',
  errorAccountStatus_2: 'Winnings not paid',
  errorAccountStatus_1: 'Not enough funds',
  errorAccountStatus0: 'No money paid',
  errorAccountStatus1: 'Bet paid',
  errorAccountStatus2: 'Win paid',
  errorBetStatus_10: 'Bet Not placed',
  errorBetStatus_1: 'Lost',
  errorBetStatus0: 'Running',
  errorBetStatus1: 'Won',
}
