import styled from 'styled-components'
import TogglePrintButton from '../menu/togglePrintButton'
import { NavBar } from '../navBar/navBar'

export const NavBarWrapper = (props: any) => {
  if (props.isDesktop && !props.isGameView) {
    return (
      <NavTop>
        {props.isCashier && <TogglePrintButton />}
        <NavBar />
      </NavTop>
    )
  }

  if (!props.isDesktop && !props.isGameView) {
    return (
      <NavBottom>
        <NavBar />
      </NavBottom>
    )
  }

  return <></>
}

const NavBottom = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colours.S000};
  width: 100%;
  max-width: 1024px;
`
const NavTop = styled.div`
  display: flex;
  justify-content: flex-end;
  background-image: ${({ theme }) => theme.colours.S090G};
`
