import { SimMarket, Score, TimeSpan, OneXTwo } from './types'

export const logic1X2_1Q =
  (time: TimeSpan) => (outcome: OneXTwo, score: Score, cTime: any) => {
    const oc: { [key in OneXTwo]: () => boolean } = {
      1: () => score[0] > score[1],
      x: () => score[0] === score[1],
      2: () => score[0] < score[1],
    }
    if (cTime === '2Q' || cTime === '3Q' || cTime === '4Q') return false
    if (time === cTime && oc[outcome]()) return 'settled'

    return oc[outcome]() ?? false
  }

export const logic1X2_2Q =
  (time: TimeSpan) => (outcome: OneXTwo, score: Score, cTime: any) => {
    const oc: { [key in OneXTwo]: () => boolean } = {
      1: () => score[0] > score[1],
      x: () => score[0] === score[1],
      2: () => score[0] < score[1],
    }
    if (cTime === '3Q' || cTime === '4Q') return false
    if (time === cTime && oc[outcome]()) return 'settled'

    return oc[outcome]() ?? false
  }

const oc1X2 = ['1', 'x', '2'] as OneXTwo[]

const _1X2 = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: oc1X2,
  logic: id === 2002 ? logic1X2_1Q(time) : logic1X2_2Q(time),
})

export const FIRST_QUARTER_1X2 = _1X2(2002, '1Q')
export const FIRST_HALF_1X2 = _1X2(2003, '2Q')
