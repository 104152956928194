import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Lcdphone';
    src: url('/static/fonts/LCDPHONE.ttf') format('truetype');
  }

  @font-face {
    font-family: 'VT323';
    src: url('/static/fonts/VT323-Regular.ttf') format('truetype');
  }

  * {
    box-sizing: border-box;
    font-size: 12px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  html {
    height: 100vh;
    width: auto;
    min-width: 320px;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0;
  }

  body {
    min-width: 320px;
    overflow: hidden;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0;
    background-color:#17181c;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }

  @media print {
    * {
      white-space: nowrap;
      color: #000000;
      font-family: Arial, Helvetica, sans-serif;
    }
    @page { 
      margin-top: 0;
      margin-bottom: 0;
    }
    body * {
      visibility: hidden;
    }
  
    #section-to-print, #section-to-print * {
      visibility: visible;
    }
  }
`
