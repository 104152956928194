import { Outlet } from 'react-router-dom'
import { useSource } from '../../../hooks/useSource'
import { StyledPage } from '../../../style/styles'
import { ErrorModal } from '../../../components/error-modal/errorModal'
import { MyBetsNav } from '../components/myBets-nav/myBetsNav'
import { Receipt } from '../../receipt/components/receipt/receipt'

export const MyBetsPage = () => {
  const { isCashier } = useSource()

  return (
    <>
      <StyledPage>
        {isCashier ? <Receipt /> : undefined}
        <MyBetsNav />

        <Outlet />
      </StyledPage>
      <ErrorModal />
    </>
  )
}
