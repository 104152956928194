import { logic1X2 } from './1X2'
import { logicOUnS } from './OU'
import { SimMarket, Score, TimeSpan, OneXTwoAndOU } from './types'

export const logic1X2OU =
  (handicap: number) => (outcome: OneXTwoAndOU, score: Score) => {
    const oc: { [key in OneXTwoAndOU]: () => boolean } = {
      '1 & over': () => logic1X2('1', score) && logicOUnS(handicap)('o', score),
      '1 & under': () =>
        logic1X2('1', score) && logicOUnS(handicap)('u', score),
      'x & over': () => logic1X2('x', score) && logicOUnS(handicap)('o', score),
      'x & under': () =>
        logic1X2('x', score) && logicOUnS(handicap)('u', score),
      '2 & over': () => logic1X2('2', score) && logicOUnS(handicap)('o', score),
      '2 & under': () =>
        logic1X2('2', score) && logicOUnS(handicap)('u', score),
    }
    return oc[outcome]() ?? false
  }

const oc1X2OU = [
  '1 & over',
  '1 & under',
  'x & over',
  'x & under',
  '2 & over',
  '2 & under',
] as OneXTwoAndOU[]

const _1X2OU = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: oc1X2OU,
  logic: logic1X2OU(handicap),
})

export const FT_1X2OU_15 = _1X2OU(18, 'FT', 1.5)
export const FT_1X2OU_25 = _1X2OU(19, 'FT', 2.5)
export const FT_1X2OU_35 = _1X2OU(20, 'FT', 3.5)
export const FT_1X2OU_45 = _1X2OU(21, 'FT', 4.5)
