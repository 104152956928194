import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledArrow = styled(motion.div)`
  position: fixed;
  height: 0%;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`
