export const bet9jaTheme = {
  fonts: ['Roboto Condensed', 'Lcdphone', 'VT323'],
  colours: {
    AP010: '#F1E3C6',
    AP050: '#F6B52D',
    AP050G: 'linear-gradient(180deg, #FFC959 0%, #F6B52D 100%)',
    AP051G: 'linear-gradient(180deg, #E6C676 0%, #DBA928 100%)',
    AP080: '#F7BF4A',
    AP020: '#FEFDBE',
    AP050O: 'rgba(246, 181, 45, 0.15)',

    AP052G: 'linear-gradient(180deg, #220d28 0%, #310f39 100%)',

    AS030: '#148957',
    AS050: '#0FA666',
    AS060: '#167F52',

    N030: '#DEE2E7',
    N040: '#AEB4B9',
    N050: '#727284',
    N055: '#484A55',
    N070: '#2B2B32',
    N080: '#1D1D20',
    N090: '#181818',

    ST000: '#FFFFFF',
    ST100: '#35383D',

    S000: '#17181C',
    S040: '#595B6A',
    S040G: 'linear-gradient(180deg, #3A3A48 0%, #202227 100%)',
    S050: '#3A3A48',
    S050G: 'linear-gradient(180deg, #444455 0%, #3C3C48 100%)',
    S060: '#393C47',
    S060O: 'rgba(57, 60, 71, 0.5)',
    S070: '#353642',
    S090: '#202225',
    S090G: 'linear-gradient(180deg, #2D2F34 0%, #202227 100%)',
    SR050: '#79182F',
    SB050: '#7491D2',
    SP050: '#B68ED4',
    S050O: 'rgba(89, 91, 106, 0.5)',

    RP050: '#97BD6F',
    RP010: '#81F8B4',
    RP020: '#0FA666',
    RP030: '#3B845A',

    RN050: '#F5222D',
    RN080: '#48242F',

    RI090: '#33333D',
  },
}
