import * as ISol from './types'
import * as Sim from '../../markets'

export const ISolBasketballMap = new Map<ISol.Markets, Sim.Market>()
export const ISolBasketballOutcomeMap = new Map<ISol.Outcomes, Sim.Outcome>()

export const findIsolBasketballOutcome = (
  _b9jMkt: ISol.Markets,
  outcome: ISol.Outcomes
): Sim.Outcome => {
  return ISolBasketballOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 2 way
ISolBasketballMap.set('UnoDue', '2 Way')
ISolBasketballOutcomeMap.set('UnoDue_1', '1')
ISolBasketballOutcomeMap.set('UnoDue_2', '2')
// 1x2 1st Quarter
ISolBasketballMap.set('UnOXDue1stQ', '1X2 1st Quarter')
ISolBasketballOutcomeMap.set('UnOXDue1stQ_11stQ', '1')
ISolBasketballOutcomeMap.set('UnOXDue1stQ_21stQ', '2')
ISolBasketballOutcomeMap.set('UnOXDue1stQ_X1stQ', 'x')
// 1x2 1st Half
ISolBasketballMap.set('Halftime Basket', '1X2 1st Half')
ISolBasketballOutcomeMap.set('Haltime Basket 1HT', '1')
ISolBasketballOutcomeMap.set('Haltime Basket 2HT', '2')
ISolBasketballOutcomeMap.set('Haltime Basket XHT', 'x')
// Home Away
ISolBasketballMap.set('Margin Home Basket', 'HomeAway')
ISolBasketballMap.set('Margin Away Basket', 'HomeAway')
ISolBasketballOutcomeMap.set('Margin Away 1-5', 'Away 1-5')
ISolBasketballOutcomeMap.set('Margin Away 6-10', 'Away 6-10')
ISolBasketballOutcomeMap.set('Margin Away 11-15', 'Away 11-15')
ISolBasketballOutcomeMap.set('Margin Away 16-20', 'Away 16-20')
ISolBasketballOutcomeMap.set('Margin Away 21-25', 'Away 21-25')
ISolBasketballOutcomeMap.set('Margin Away 26+', 'Away 26+')
ISolBasketballOutcomeMap.set('Margin Home 1-5', 'Home 1-5')
ISolBasketballOutcomeMap.set('Margin Home 6-10', 'Home 6-10')
ISolBasketballOutcomeMap.set('Margin Home 11-15', 'Home 11-15')
ISolBasketballOutcomeMap.set('Margin Home 16-20', 'Home 16-20')
ISolBasketballOutcomeMap.set('Margin Home 21-25', 'Home 21-25')
ISolBasketballOutcomeMap.set('Margin Home 26+', 'Home 26+')
// ISolBasketballMap.set('Winmarginshandball', 'HomeAway')
// ISolBasketballOutcomeMap.set('Win Away 1-5', 'Away 1-5')
// ISolBasketballOutcomeMap.set('WinMarginAway6-10', 'Away 6-10')
// ISolBasketballOutcomeMap.set('WinMarginAway11-15', 'Away 11-15')
// ISolBasketballOutcomeMap.set('WinMarginAway16-20', 'Away 16-20')
// ISolBasketballOutcomeMap.set('WinMarginAway21-25', 'Away 21-25')
// ISolBasketballOutcomeMap.set('WinMarginAway26+', 'Away 26+')
// ISolBasketballOutcomeMap.set('Win Home1-5', 'Home 1-5')
// ISolBasketballOutcomeMap.set('WinMarginHome6-10', 'Home 6-10')
// ISolBasketballOutcomeMap.set('WinMarginHome11-15', 'Home 11-15')
// ISolBasketballOutcomeMap.set('WinMarginHome16-20', 'Home 16-20')
// ISolBasketballOutcomeMap.set('WinMarginHome21-25', 'Home 21-25')
// ISolBasketballOutcomeMap.set('WinMarginHome26+', 'Home 26+')

// 1x2 5.5
ISolBasketballMap.set('1x2 Margin Basket', '1X2 (5.5 points)')
ISolBasketballOutcomeMap.set('2 Margin 5 Basket', '2')
ISolBasketballOutcomeMap.set('H Margin 5 Basket', '1')
ISolBasketballOutcomeMap.set('X Margin 5 Basket', 'x')

// Over/Under 1st Quarter
ISolBasketballMap.set('Ov/Un Q1 Basket (27.5)', 'O/U 1st Quarter 27.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (28.5)', 'O/U 1st Quarter 28.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (29.5)', 'O/U 1st Quarter 29.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (30.5)', 'O/U 1st Quarter 30.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (31.5)', 'O/U 1st Quarter 31.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (32.5)', 'O/U 1st Quarter 32.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (33.5)', 'O/U 1st Quarter 33.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (34.5)', 'O/U 1st Quarter 34.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (35.5)', 'O/U 1st Quarter 35.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (36.5)', 'O/U 1st Quarter 36.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (37.5)', 'O/U 1st Quarter 37.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (38.5)', 'O/U 1st Quarter 38.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (39.5)', 'O/U 1st Quarter 39.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (40.5)', 'O/U 1st Quarter 40.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (41.5)', 'O/U 1st Quarter 41.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (42.5)', 'O/U 1st Quarter 42.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (43.5)', 'O/U 1st Quarter 43.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (44.5)', 'O/U 1st Quarter 44.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (45.5)', 'O/U 1st Quarter 45.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (46.5)', 'O/U 1st Quarter 46.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (47.5)', 'O/U 1st Quarter 47.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (48.5)', 'O/U 1st Quarter 48.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (49.5)', 'O/U 1st Quarter 49.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (50.5)', 'O/U 1st Quarter 50.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (51.5)', 'O/U 1st Quarter 51.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (52.5)', 'O/U 1st Quarter 52.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (53.5)', 'O/U 1st Quarter 53.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (54.5)', 'O/U 1st Quarter 54.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (55.5)', 'O/U 1st Quarter 55.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (56.5)', 'O/U 1st Quarter 56.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (57.5)', 'O/U 1st Quarter 57.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (58.5)', 'O/U 1st Quarter 58.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (59.5)', 'O/U 1st Quarter 59.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (60.5)', 'O/U 1st Quarter 60.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (61.5)', 'O/U 1st Quarter 61.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (62.5)', 'O/U 1st Quarter 62.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (63.5)', 'O/U 1st Quarter 63.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (64.5)', 'O/U 1st Quarter 64.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (65.5)', 'O/U 1st Quarter 65.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (66.5)', 'O/U 1st Quarter 66.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (67.5)', 'O/U 1st Quarter 67.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (68.5)', 'O/U 1st Quarter 68.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (69.5)', 'O/U 1st Quarter 69.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (70.5)', 'O/U 1st Quarter 70.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (71.5)', 'O/U 1st Quarter 71.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (72.5)', 'O/U 1st Quarter 72.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (73.5)', 'O/U 1st Quarter 73.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (74.5)', 'O/U 1st Quarter 74.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (75.5)', 'O/U 1st Quarter 75.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (76.5)', 'O/U 1st Quarter 76.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (77.5)', 'O/U 1st Quarter 77.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (78.5)', 'O/U 1st Quarter 78.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (79.5)', 'O/U 1st Quarter 79.5')
ISolBasketballMap.set('Ov/Un Q1 Basket (80.5)', 'O/U 1st Quarter 80.5')
ISolBasketballOutcomeMap.set('Over Q1 Basket', 'o')
ISolBasketballOutcomeMap.set('Under Q1 Basket', 'u')

// Over/Under
ISolBasketballMap.set('OverUnderBasket110-300 110.5', 'O/U 110.5')
ISolBasketballMap.set('OverUnderBasket110-300 111.5', 'O/U 111.5')
ISolBasketballMap.set('OverUnderBasket110-300 112.5', 'O/U 112.5')
ISolBasketballMap.set('OverUnderBasket110-300 113.5', 'O/U 113.5')
ISolBasketballMap.set('OverUnderBasket110-300 114.5', 'O/U 114.5')
ISolBasketballMap.set('OverUnderBasket110-300 115.5', 'O/U 115.5')
ISolBasketballMap.set('OverUnderBasket110-300 116.5', 'O/U 116.5')
ISolBasketballMap.set('OverUnderBasket110-300 117.5', 'O/U 117.5')
ISolBasketballMap.set('OverUnderBasket110-300 118.5', 'O/U 118.5')
ISolBasketballMap.set('OverUnderBasket110-300 119.5', 'O/U 119.5')
ISolBasketballMap.set('OverUnderBasket110-300 120.5', 'O/U 120.5')
ISolBasketballMap.set('OverUnderBasket110-300 121.5', 'O/U 121.5')
ISolBasketballMap.set('OverUnderBasket110-300 122.5', 'O/U 122.5')
ISolBasketballMap.set('OverUnderBasket110-300 123.5', 'O/U 123.5')
ISolBasketballMap.set('OverUnderBasket110-300 124.5', 'O/U 124.5')
ISolBasketballMap.set('OverUnderBasket110-300 125.5', 'O/U 125.5')
ISolBasketballMap.set('OverUnderBasket110-300 126.5', 'O/U 126.5')
ISolBasketballMap.set('OverUnderBasket110-300 127.5', 'O/U 127.5')
ISolBasketballMap.set('OverUnderBasket110-300 128.5', 'O/U 128.5')
ISolBasketballMap.set('OverUnderBasket110-300 129.5', 'O/U 129.5')
ISolBasketballMap.set('OverUnderBasket110-300 130.5', 'O/U 130.5')
ISolBasketballMap.set('OverUnderBasket110-300 131.5', 'O/U 131.5')
ISolBasketballMap.set('OverUnderBasket110-300 132.5', 'O/U 132.5')
ISolBasketballMap.set('OverUnderBasket110-300 133.5', 'O/U 133.5')
ISolBasketballMap.set('OverUnderBasket110-300 134.5', 'O/U 134.5')
ISolBasketballMap.set('OverUnderBasket110-300 135.5', 'O/U 135.5')
ISolBasketballMap.set('OverUnderBasket110-300 136.5', 'O/U 136.5')
ISolBasketballMap.set('OverUnderBasket110-300 137.5', 'O/U 137.5')
ISolBasketballMap.set('OverUnderBasket110-300 138.5', 'O/U 138.5')
ISolBasketballMap.set('OverUnderBasket110-300 139.5', 'O/U 139.5')
ISolBasketballMap.set('OverUnderBasket140-169 140.5', 'O/U 140.5')
ISolBasketballMap.set('OverUnderBasket140-169 141.5', 'O/U 141.5')
ISolBasketballMap.set('OverUnderBasket140-169 142.5', 'O/U 142.5')
ISolBasketballMap.set('OverUnderBasket140-169 143.5', 'O/U 143.5')
ISolBasketballMap.set('OverUnderBasket140-169 144.5', 'O/U 144.5')
ISolBasketballMap.set('OverUnderBasket140-169 145.5', 'O/U 145.5')
ISolBasketballMap.set('OverUnderBasket140-169 146.5', 'O/U 146.5')
ISolBasketballMap.set('OverUnderBasket140-169 147.5', 'O/U 147.5')
ISolBasketballMap.set('OverUnderBasket140-169 148.5', 'O/U 148.5')
ISolBasketballMap.set('OverUnderBasket140-169 149.5', 'O/U 149.5')
ISolBasketballMap.set('OverUnderBasket140-169 150.5', 'O/U 150.5')
ISolBasketballMap.set('OverUnderBasket140-169 151.5', 'O/U 151.5')
ISolBasketballMap.set('OverUnderBasket140-169 152.5', 'O/U 152.5')
ISolBasketballMap.set('OverUnderBasket140-169 153.5', 'O/U 153.5')
ISolBasketballMap.set('OverUnderBasket140-169 154.5', 'O/U 154.5')
ISolBasketballMap.set('OverUnderBasket140-169 155.5', 'O/U 155.5')
ISolBasketballMap.set('OverUnderBasket140-169 156.5', 'O/U 156.5')
ISolBasketballMap.set('OverUnderBasket140-169 157.5', 'O/U 157.5')
ISolBasketballMap.set('OverUnderBasket140-169 158.5', 'O/U 158.5')
ISolBasketballMap.set('OverUnderBasket140-169 159.5', 'O/U 159.5')
ISolBasketballMap.set('OverUnderBasket140-169 160.5', 'O/U 160.5')
ISolBasketballMap.set('OverUnderBasket140-169 161.5', 'O/U 161.5')
ISolBasketballMap.set('OverUnderBasket140-169 162.5', 'O/U 162.5')
ISolBasketballMap.set('OverUnderBasket140-169 163.5', 'O/U 163.5')
ISolBasketballMap.set('OverUnderBasket140-169 164.5', 'O/U 164.5')
ISolBasketballMap.set('OverUnderBasket140-169 165.5', 'O/U 165.5')
ISolBasketballMap.set('OverUnderBasket140-169 166.5', 'O/U 166.5')
ISolBasketballMap.set('OverUnderBasket140-169 167.5', 'O/U 167.5')
ISolBasketballMap.set('OverUnderBasket140-169 168.5', 'O/U 168.5')
ISolBasketballMap.set('OverUnderBasket140-169 169.5', 'O/U 169.5')
ISolBasketballMap.set('OverUnderBasket170-199 170.5', 'O/U 170.5')
ISolBasketballMap.set('OverUnderBasket170-199 171.5', 'O/U 171.5')
ISolBasketballMap.set('OverUnderBasket170-199 172.5', 'O/U 172.5')
ISolBasketballMap.set('OverUnderBasket170-199 173.5', 'O/U 173.5')
ISolBasketballMap.set('OverUnderBasket170-199 174.5', 'O/U 174.5')
ISolBasketballMap.set('OverUnderBasket170-199 175.5', 'O/U 175.5')
ISolBasketballMap.set('OverUnderBasket170-199 176.5', 'O/U 176.5')
ISolBasketballMap.set('OverUnderBasket170-199 177.5', 'O/U 177.5')
ISolBasketballMap.set('OverUnderBasket170-199 178.5', 'O/U 178.5')
ISolBasketballMap.set('OverUnderBasket170-199 179.5', 'O/U 179.5')
ISolBasketballMap.set('OverUnderBasket170-199 180.5', 'O/U 180.5')
ISolBasketballMap.set('OverUnderBasket170-199 181.5', 'O/U 181.5')
ISolBasketballMap.set('OverUnderBasket170-199 182.5', 'O/U 182.5')
ISolBasketballMap.set('OverUnderBasket170-199 183.5', 'O/U 183.5')
ISolBasketballMap.set('OverUnderBasket170-199 184.5', 'O/U 184.5')
ISolBasketballMap.set('OverUnderBasket170-199 185.5', 'O/U 185.5')
ISolBasketballMap.set('OverUnderBasket170-199 186.5', 'O/U 186.5')
ISolBasketballMap.set('OverUnderBasket170-199 187.5', 'O/U 187.5')
ISolBasketballMap.set('OverUnderBasket170-199 188.5', 'O/U 188.5')
ISolBasketballMap.set('OverUnderBasket170-199 189.5', 'O/U 189.5')
ISolBasketballMap.set('OverUnderBasket170-199 190.5', 'O/U 190.5')
ISolBasketballMap.set('OverUnderBasket170-199 191.5', 'O/U 191.5')
ISolBasketballMap.set('OverUnderBasket170-199 192.5', 'O/U 192.5')
ISolBasketballMap.set('OverUnderBasket170-199 193.5', 'O/U 193.5')
ISolBasketballMap.set('OverUnderBasket170-199 194.5', 'O/U 194.5')
ISolBasketballMap.set('OverUnderBasket170-199 195.5', 'O/U 195.5')
ISolBasketballMap.set('OverUnderBasket170-199 196.5', 'O/U 196.5')
ISolBasketballMap.set('OverUnderBasket170-199 197.5', 'O/U 197.5')
ISolBasketballMap.set('OverUnderBasket170-199 198.5', 'O/U 198.5')
ISolBasketballMap.set('OverUnderBasket170-199 199.5', 'O/U 199.5')
ISolBasketballMap.set('OverUnderBasket200-229 200.5', 'O/U 200.5')
ISolBasketballMap.set('OverUnderBasket200-229 201.5', 'O/U 201.5')
ISolBasketballMap.set('OverUnderBasket200-229 202.5', 'O/U 202.5')
ISolBasketballMap.set('OverUnderBasket200-229 203.5', 'O/U 203.5')
ISolBasketballMap.set('OverUnderBasket200-229 204.5', 'O/U 204.5')
ISolBasketballMap.set('OverUnderBasket200-229 205.5', 'O/U 205.5')
ISolBasketballMap.set('OverUnderBasket200-229 206.5', 'O/U 206.5')
ISolBasketballMap.set('OverUnderBasket200-229 207.5', 'O/U 207.5')
ISolBasketballMap.set('OverUnderBasket200-229 208.5', 'O/U 208.5')
ISolBasketballMap.set('OverUnderBasket200-229 209.5', 'O/U 209.5')
ISolBasketballMap.set('OverUnderBasket200-229 210.5', 'O/U 210.5')
ISolBasketballMap.set('OverUnderBasket200-229 211.5', 'O/U 211.5')
ISolBasketballMap.set('OverUnderBasket200-229 212.5', 'O/U 212.5')
ISolBasketballMap.set('OverUnderBasket200-229 213.5', 'O/U 213.5')
ISolBasketballMap.set('OverUnderBasket200-229 214.5', 'O/U 214.5')
ISolBasketballMap.set('OverUnderBasket200-229 215.5', 'O/U 215.5')
ISolBasketballMap.set('OverUnderBasket200-229 216.5', 'O/U 216.5')
ISolBasketballMap.set('OverUnderBasket200-229 217.5', 'O/U 217.5')
ISolBasketballMap.set('OverUnderBasket200-229 218.5', 'O/U 218.5')
ISolBasketballMap.set('OverUnderBasket200-229 219.5', 'O/U 219.5')
ISolBasketballMap.set('OverUnderBasket200-229 220.5', 'O/U 220.5')
ISolBasketballMap.set('OverUnderBasket200-229 221.5', 'O/U 221.5')
ISolBasketballMap.set('OverUnderBasket200-229 222.5', 'O/U 222.5')
ISolBasketballMap.set('OverUnderBasket200-229 223.5', 'O/U 223.5')
ISolBasketballMap.set('OverUnderBasket200-229 224.5', 'O/U 224.5')
ISolBasketballMap.set('OverUnderBasket200-229 225.5', 'O/U 225.5')
ISolBasketballMap.set('OverUnderBasket200-229 226.5', 'O/U 226.5')
ISolBasketballMap.set('OverUnderBasket200-229 227.5', 'O/U 227.5')
ISolBasketballMap.set('OverUnderBasket200-229 228.5', 'O/U 228.5')
ISolBasketballMap.set('OverUnderBasket200-229 229.5', 'O/U 229.5')
ISolBasketballMap.set('OverUnderBasket230-259 230.5', 'O/U 230.5')
ISolBasketballMap.set('OverUnderBasket230-259 231.5', 'O/U 231.5')
ISolBasketballMap.set('OverUnderBasket230-259 232.5', 'O/U 232.5')
ISolBasketballMap.set('OverUnderBasket230-259 233.5', 'O/U 233.5')
ISolBasketballMap.set('OverUnderBasket230-259 234.5', 'O/U 234.5')
ISolBasketballMap.set('OverUnderBasket230-259 235.5', 'O/U 235.5')
ISolBasketballMap.set('OverUnderBasket230-259 236.5', 'O/U 236.5')
ISolBasketballMap.set('OverUnderBasket230-259 237.5', 'O/U 237.5')
ISolBasketballMap.set('OverUnderBasket230-259 238.5', 'O/U 238.5')
ISolBasketballMap.set('OverUnderBasket230-259 239.5', 'O/U 239.5')
ISolBasketballMap.set('OverUnderBasket230-259 240.5', 'O/U 240.5')
ISolBasketballMap.set('OverUnderBasket230-259 241.5', 'O/U 241.5')
ISolBasketballMap.set('OverUnderBasket230-259 242.5', 'O/U 242.5')
ISolBasketballMap.set('OverUnderBasket230-259 243.5', 'O/U 243.5')
ISolBasketballMap.set('OverUnderBasket230-259 244.5', 'O/U 244.5')
ISolBasketballMap.set('OverUnderBasket230-259 245.5', 'O/U 245.5')
ISolBasketballMap.set('OverUnderBasket230-259 246.5', 'O/U 246.5')
ISolBasketballMap.set('OverUnderBasket230-259 247.5', 'O/U 247.5')
ISolBasketballMap.set('OverUnderBasket230-259 248.5', 'O/U 248.5')
ISolBasketballMap.set('OverUnderBasket230-259 249.5', 'O/U 249.5')
ISolBasketballMap.set('OverUnderBasket230-259 250.5', 'O/U 250.5')
ISolBasketballMap.set('OverUnderBasket230-259 251.5', 'O/U 251.5')
ISolBasketballMap.set('OverUnderBasket230-259 252.5', 'O/U 252.5')
ISolBasketballMap.set('OverUnderBasket230-259 253.5', 'O/U 253.5')
ISolBasketballMap.set('OverUnderBasket230-259 254.5', 'O/U 254.5')
ISolBasketballMap.set('OverUnderBasket230-259 255.5', 'O/U 255.5')
ISolBasketballMap.set('OverUnderBasket230-259 256.5', 'O/U 256.5')
ISolBasketballMap.set('OverUnderBasket230-259 257.5', 'O/U 257.5')
ISolBasketballMap.set('OverUnderBasket230-259 258.5', 'O/U 258.5')
ISolBasketballMap.set('OverUnderBasket230-259 259.5', 'O/U 259.5')
ISolBasketballMap.set('OverUnderBasket260-289 260.5', 'O/U 260.5')
ISolBasketballMap.set('OverUnderBasket260-289 261.5', 'O/U 261.5')
ISolBasketballMap.set('OverUnderBasket260-289 262.5', 'O/U 262.5')
ISolBasketballMap.set('OverUnderBasket260-289 263.5', 'O/U 263.5')
ISolBasketballMap.set('OverUnderBasket260-289 264.5', 'O/U 264.5')
ISolBasketballMap.set('OverUnderBasket260-289 265.5', 'O/U 265.5')
ISolBasketballMap.set('OverUnderBasket260-289 266.5', 'O/U 266.5')
ISolBasketballMap.set('OverUnderBasket260-289 267.5', 'O/U 267.5')
ISolBasketballMap.set('OverUnderBasket260-289 268.5', 'O/U 268.5')
ISolBasketballMap.set('OverUnderBasket260-289 269.5', 'O/U 269.5')
ISolBasketballMap.set('OverUnderBasket260-289 270.5', 'O/U 270.5')
ISolBasketballMap.set('OverUnderBasket260-289 271.5', 'O/U 271.5')
ISolBasketballMap.set('OverUnderBasket260-289 272.5', 'O/U 272.5')
ISolBasketballMap.set('OverUnderBasket260-289 273.5', 'O/U 273.5')
ISolBasketballMap.set('OverUnderBasket260-289 274.5', 'O/U 274.5')
ISolBasketballMap.set('OverUnderBasket260-289 275.5', 'O/U 275.5')
ISolBasketballMap.set('OverUnderBasket260-289 276.5', 'O/U 276.5')
ISolBasketballMap.set('OverUnderBasket260-289 277.5', 'O/U 277.5')
ISolBasketballMap.set('OverUnderBasket260-289 278.5', 'O/U 278.5')
ISolBasketballMap.set('OverUnderBasket260-289 279.5', 'O/U 279.5')
ISolBasketballMap.set('OverUnderBasket260-289 280.5', 'O/U 280.5')
ISolBasketballMap.set('OverUnderBasket260-289 281.5', 'O/U 281.5')
ISolBasketballMap.set('OverUnderBasket260-289 282.5', 'O/U 282.5')
ISolBasketballMap.set('OverUnderBasket260-289 283.5', 'O/U 283.5')
ISolBasketballMap.set('OverUnderBasket260-289 284.5', 'O/U 284.5')
ISolBasketballMap.set('OverUnderBasket260-289 285.5', 'O/U 285.5')
ISolBasketballMap.set('OverUnderBasket260-289 286.5', 'O/U 286.5')
ISolBasketballMap.set('OverUnderBasket260-289 287.5', 'O/U 287.5')
ISolBasketballMap.set('OverUnderBasket260-289 288.5', 'O/U 288.5')
ISolBasketballMap.set('OverUnderBasket260-289 289.5', 'O/U 289.5')
ISolBasketballMap.set('OverUnderBasket290-320 290.5', 'O/U 290.5')
ISolBasketballMap.set('OverUnderBasket290-320 291.5', 'O/U 291.5')
ISolBasketballMap.set('OverUnderBasket290-320 292.5', 'O/U 292.5')
ISolBasketballMap.set('OverUnderBasket290-320 293.5', 'O/U 293.5')
ISolBasketballMap.set('OverUnderBasket290-320 294.5', 'O/U 294.5')
ISolBasketballMap.set('OverUnderBasket290-320 295.5', 'O/U 295.5')
ISolBasketballMap.set('OverUnderBasket290-320 296.5', 'O/U 296.5')
ISolBasketballMap.set('OverUnderBasket290-320 297.5', 'O/U 297.5')
ISolBasketballMap.set('OverUnderBasket290-320 298.5', 'O/U 298.5')
ISolBasketballMap.set('OverUnderBasket290-320 299.5', 'O/U 299.5')
ISolBasketballMap.set('OverUnderBasket290-320 300.5', 'O/U 300.5')
ISolBasketballMap.set('OverUnderBasket290-320 301.5', 'O/U 301.5')
ISolBasketballMap.set('OverUnderBasket290-320 302.5', 'O/U 302.5')
ISolBasketballMap.set('OverUnderBasket290-320 303.5', 'O/U 303.5')
ISolBasketballMap.set('OverUnderBasket290-320 304.5', 'O/U 304.5')
ISolBasketballMap.set('OverUnderBasket290-320 305.5', 'O/U 305.5')
ISolBasketballMap.set('OverUnderBasket290-320 306.5', 'O/U 306.5')
ISolBasketballMap.set('OverUnderBasket290-320 307.5', 'O/U 307.5')
ISolBasketballMap.set('OverUnderBasket290-320 308.5', 'O/U 308.5')
ISolBasketballMap.set('OverUnderBasket290-320 309.5', 'O/U 309.5')
ISolBasketballMap.set('OverUnderBasket290-320 310.5', 'O/U 310.5')
ISolBasketballMap.set('OverUnderBasket290-320 311.5', 'O/U 311.5')
ISolBasketballMap.set('OverUnderBasket290-320 312.5', 'O/U 312.5')
ISolBasketballMap.set('OverUnderBasket290-320 313.5', 'O/U 313.5')
ISolBasketballMap.set('OverUnderBasket290-320 314.5', 'O/U 314.5')
ISolBasketballMap.set('OverUnderBasket290-320 315.5', 'O/U 315.5')
ISolBasketballMap.set('OverUnderBasket290-320 316.5', 'O/U 316.5')
ISolBasketballMap.set('OverUnderBasket290-320 317.5', 'O/U 317.5')
ISolBasketballMap.set('OverUnderBasket290-320 318.5', 'O/U 318.5')
ISolBasketballMap.set('OverUnderBasket290-320 319.5', 'O/U 319.5')
ISolBasketballMap.set('OverUnderBasket290-320 320.5', 'O/U 320.5')
ISolBasketballOutcomeMap.set('Over', 'o')
ISolBasketballOutcomeMap.set('Under', 'u')
