import React from 'react'
import { useSelector } from 'react-redux'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { SelectionResponse } from '../api/types'
import { basketballTimeline$ } from '../sports/basketball/timeline/basketballTimeline'
import { formatScore } from '../lib/utils/uiUtils'

import * as Sim from '../sports/basketball/markets'
import { simulateBasketballMarkets } from '../sports/basketball/markets/marketMap'
import { winningBet } from '../sports/marketUtils'
import { selectSpeed } from '../features/app/store/app.state'

// REDUCER ----------------------------------------------------------------------------
export type GameItemState = {
  score: Sim.Score
  winning: boolean | 'settled'
  won: boolean | 'settled'
  lost: boolean
  firstQuarterResult: Sim.Score
  secondQuarterResult: Sim.Score
  thirdQuarterResult: Sim.Score
  fourthQuarterResult: Sim.Score
}

const initialState: GameItemState = {
  score: [0, 0],
  winning: false,
  won: false,
  lost: false,
  firstQuarterResult: [0, 0],
  secondQuarterResult: [0, 0],
  thirdQuarterResult: [0, 0],
  fourthQuarterResult: [0, 0],
}

type TisWinning = (score: number[], time?: Sim.TimeSpan) => boolean | 'settled'

type Action =
  | { type: 'RESET'; isWinning: TisWinning }
  | { type: 'WON' }
  | { type: 'LOST' }
  | {
      type: '1QUARTER'
      sel: SelectionResponse
      isWinning: TisWinning
      mktType: Sim.TimeSpan
    }
  | {
      type: '2QUARTER'
      sel: SelectionResponse
      isWinning: TisWinning
      mktType: Sim.TimeSpan
    }
  | {
      type: '3QUARTER'
      sel: SelectionResponse
      isWinning: TisWinning
      mktType: Sim.TimeSpan
    }
  | {
      type: '4QUARTER'
      sel: SelectionResponse
    }

function reducer(state: GameItemState, action: Action): GameItemState {
  switch (action.type) {
    case 'RESET':
      return {
        ...initialState,
        winning: action.isWinning([0, 0]),
      }
    case 'WON':
      return {
        ...state,
        won: true,
      }
    case 'LOST':
      return {
        ...state,
        lost: true,
      }

    case '1QUARTER': {
      const isWinning = () => {
        if (state.winning === 'settled') return 'settled'
        return action.isWinning(formatScore(action.sel.first_quarter), '1Q')
      }

      const isWon = () => {
        if (isWinning() === 'settled' && action.sel.result === 1) return true
        return false
      }

      return {
        ...state,
        score: formatScore(action.sel.first_quarter),
        firstQuarterResult: formatScore(action.sel.first_quarter),
        winning: isWinning(),
        won: isWon(),
      }
    }
    case '2QUARTER': {
      const score = [
        state.score[0] + formatScore(action.sel.second_quarter)[0],
        state.score[1] + formatScore(action.sel.second_quarter)[1],
      ]

      const isWinning = () => {
        if (state.winning === 'settled') return 'settled'
        return action.isWinning(score, '2Q')
      }

      const isWon = () => {
        if (isWinning() === 'settled' && action.sel.result === 1) return true
        return false
      }

      return {
        ...state,
        score,
        secondQuarterResult: formatScore(action.sel.second_quarter),
        winning: isWinning(),
        won: isWon(),
      }
    }
    case '3QUARTER': {
      const score = [
        state.score[0] + formatScore(action.sel.third_quarter)[0],
        state.score[1] + formatScore(action.sel.third_quarter)[1],
      ]

      const isWinning = () => {
        if (state.winning === 'settled') return 'settled'
        return action.isWinning(score, '3Q')
      }

      const isWon = () => {
        if (isWinning() === 'settled' && action.sel.result === 1) return true
        return false
      }

      return {
        ...state,
        score,
        thirdQuarterResult: formatScore(action.sel.third_quarter),
        winning: isWinning(),
        won: isWon(),
      }
    }
    case '4QUARTER':
      return {
        ...state,
        score: [
          state.score[0] + formatScore(action.sel.fourth_quarter)[0],
          state.score[1] + formatScore(action.sel.fourth_quarter)[1],
        ],
        fourthQuarterResult: formatScore(action.sel.fourth_quarter),
        winning: action.sel.result === 1,
        won: action.sel.result === 1,
      }

    // ERROR OUT
    default:
      throw new Error()
  }
}

// HOOK ----------------------------------------------------------------------------
export function useBasketballItemState(
  selection: SelectionResponse,
  marketName: Sim.Market
) {
  const isWinning = winningBet(
    selection.selected_option,
    marketName,
    selection.sport_id
  )
  const speed = useSelector(selectSpeed)
  const mktType = simulateBasketballMarkets.get(marketName)?.time || 'FT'

  const basketball$ = basketballTimeline$.pipe(
    mergeMap(({ clock }) =>
      of(selection).pipe(
        map((sel) => {
          if (clock === 0) {
            dispatch({ type: 'RESET', isWinning })
          }
          if (clock === 12) {
            ;(async () => {
              dispatch({
                type: '1QUARTER',
                sel,
                isWinning,
                mktType,
              })
            })()
          }
          if (clock === 24) {
            ;(async () => {
              dispatch({
                type: '2QUARTER',
                sel,
                isWinning,
                mktType,
              })
            })()
          }
          if (clock === 36) {
            ;(async () => {
              dispatch({
                type: '3QUARTER',
                sel,
                isWinning,
                mktType,
              })
            })()
          }
          if (clock === 47) {
            ;(async () => {
              dispatch({
                type: '4QUARTER',
                sel,
              })
            })()
          }
        })
      )
    )
  )

  const [state, dispatch] = React.useReducer(reducer, initialState)
  // console.log(state)
  React.useEffect(() => {
    const sub = basketball$.subscribe()

    return () => sub.unsubscribe()
  }, [speed, selection, marketName])

  return state
}
