import React from 'react'
import { ScoreBar } from './scoreBar'
import { StatusBar } from './statusBar'
import { Item } from './gameItem.style'
import { SelectionResponse } from '../../../../api/types'
import { findMarketFromID } from '../../../../sports/marketUtils'
import { showOutcome, showMarket } from '../../../../lib/utils/uiUtils'
import { StatsBar } from './statsBar'
import { useBasketballItemState } from '../../../../hooks/useBasketballItemState'
import * as SimBasketball from '../../../../sports/basketball/markets'

interface IProps {
  selection: SelectionResponse
}
export const BasketballItem: React.FC<IProps> = ({ selection }) => {
  const marketName = findMarketFromID(selection.market_id, selection.sport_id)

  const {
    won,
    winning,
    score,
    firstQuarterResult,
    secondQuarterResult,
    thirdQuarterResult,
    fourthQuarterResult,
  } = useBasketballItemState(selection, marketName as SimBasketball.Market)

  const outcome = showOutcome(selection.selected_option, selection.event_name)
  const market = showMarket(marketName)

  return (
    <Item data-testid="basket-item" won={!!won}>
      <ScoreBar score={score} event={selection.event_name} won={won} />
      <StatsBar
        event={selection.event_name}
        won={won}
        firstQuarterResult={firstQuarterResult}
        secondQuarterResult={secondQuarterResult}
        thirdQuarterResult={thirdQuarterResult}
        fourthQuarterResult={fourthQuarterResult}
      />
      <StatusBar
        outcome={outcome}
        market={market}
        winning={winning}
        won={won}
      />
    </Item>
  )
}
