import { useTimeline } from '../../../../hooks/useTimeline'
import { TimeLine } from '../../../../sports/soccer/timeline/soccerTimeline'
import { GameDisplay } from '../game-display/gameDisplay'
import { Container } from './gameHeader.style'

export const GameHeader = () => {
  const { timeline } = useTimeline('Soccer')

  return (
    <Container>
      <GameDisplay timeline={timeline || ({} as TimeLine)} />
    </Container>
  )
}
