import { useSelector } from 'react-redux'
import { IntlMessage } from '../../lang/IntlMessage'
import { TMessages } from '../../lang/types'
import {
  selectError,
  validatesErrorModal,
} from '../../features/app/store/app.state'
import { useAppDispatch } from '../../store/store'
import { Modal } from '../modal/modal'
import { Wrapper } from './errorModal.style'

export const ErrorModal = () => {
  const dispatch = useAppDispatch()
  const error = useSelector(selectError)
  const handleClick = () => dispatch(validatesErrorModal())

  if (error)
    return (
      <Modal>
        <Wrapper>
          <div className="message" data-testid="error-msg">
            <IntlMessage
              id={error.msg as keyof TMessages}
              values={{ ...error?.values }}
            />{' '}
          </div>
          <button onClick={handleClick} className="btn">
            <span>
              <IntlMessage id="btnContinue" />
            </span>
          </button>
        </Wrapper>
      </Modal>
    )

  return <></>
}
