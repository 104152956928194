import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { selectPermissions } from '../../../app/store/app.state'
import { selectActiveSelections } from '../../../betslip/store/betslip.selectors'
import { AddMore, Bar } from './sectionBar.style'
import { IntlMessage } from '../../../../lang/IntlMessage'

export const SelectionsBar = () => {
  const navigate = useNavigate()
  const activeSelections = useSelector(selectActiveSelections)
  const permissions = useSelector(selectPermissions)

  return (
    <Bar>
      <div className="selections">
        <span data-testid="activeSelections" className="amount">
          {activeSelections}
        </span>
        <span className="text">
          <IntlMessage id="selectionsBar_sel" />
        </span>
      </div>
      {permissions?.explore === true ? (
        <AddMore
          data-testid="addMore"
          className="add-more"
          onClick={() => navigate('/explore')}
        >
          <IntlMessage id="selectionsBar_addMore" />
        </AddMore>
      ) : (
        <></>
      )}
    </Bar>
  )
}
