import styled, { css } from 'styled-components'

export const Item = styled.div<{ won: boolean }>`
  font-family: ${(props) => props.theme.fonts[0]};
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;

  ${({ won, theme }) => css`
    color: ${won ? theme.colours.ST000 : theme.colours.N030};
    background: ${won ? theme.colours.RP020 : theme.colours.S050G};
  `};
`

export const ScoreBarWrapper = styled.div<{ won: boolean }>`
  width: 100%;
  .scoreDisplay {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 14px;
      height: 14px;
      margin: 0 5px;
      background-color: ${({ theme }) => theme.colours.S000};
      border-radius: 50%;
    }

    .results {
      ${({ won, theme }) => css`
        font-size: ${won ? '12px' : '8px'};
        font-weight: 400;
        color: ${won ? theme.colours.ST000 : theme.colours.N030};
      `};
    }
  }
`
export const StatsBarWrapper = styled.div<{ won: boolean }>`
  padding-bottom: 4px;
  .summary {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    .teamName {
      text-align: right;
      width: 50%;
      font-size: 10px;
      line-height: 12px;
    }

    .score {
      width: 50%;
      line-height: 12px;

      .digit {
        margin-left: 6px;
        font-size: 10px;
      }
      .bold {
        font-weight: 900;
      }
    }
  }
`
export const Team = styled.div<{ won: boolean; left?: boolean }>`
  ${({ won, theme, left }) => css`
    font-weight: 700;
    width: 100%;
    text-align: ${left ? 'left' : 'right'};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${won ? theme.colours.ST000 : theme.colours.N030};
    text-transform: ${won ? 'unset' : 'uppercase'};
    margin: 0 5px;
  `};
`

export const Status = styled.div<{ won: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */
  line-height: 14px;
  padding: 5px;
  width: 100%;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colours.RI090};

  ${({ won }) =>
    won &&
    css`
      background-color: ${({ theme }) => theme.colours.RP030};
      color: ${({ theme }) => theme.colours.ST100};
    `};

  .outcome {
    ${({ won, theme }) => css`
      font-weight: 500;
      color: ${won ? theme.colours.ST000 : theme.colours.N030};
    `}
  }

  .market {
    ${({ won, theme }) => css`
      font-weight: 400;
      margin-left: 4px;
      color: ${won ? theme.colours.ST000 : theme.colours.N040};
    `}
  }
`

export const Icon = styled.span`
  display: flex;
  position: absolute;
  width: 23px;
  right: 0px;

  path {
    fill: #fff;
  }
`

export const Ball = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 3px;
  overflow: hidden;
  z-index: 200;
`
