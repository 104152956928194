import styled, { css } from 'styled-components'

export const Navigation = styled.ul<{ count: number }>`
  margin: 0;
  color: ${(props) => props.theme.colours.N030};
  box-sizing: border-box;
  /* position: relative; */
  /* grid-gap: 16px; */
  z-index: 80;
  display: grid;
  grid-template-columns: ${({ count }) =>
    `repeat(${count}, minmax(max-content, 1fr))`};
  gap: 8px;
  width: min-content;
  margin: auto;
  padding: 1px 8px;
`

export const NavItem = styled.li<{ isTournament: boolean; isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  list-style-type: none;
  cursor: pointer;

  ${({ isTournament }) =>
    isTournament &&
    css`
      :nth-of-type(1) {
        order: 1;
      }
      :nth-of-type(2) {
        order: 3;
      }
      :nth-of-type(3) {
        order: 2;
      }
      :nth-of-type(4) {
        order: 4;
      }
    `};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    transition: 0.16s all 0.025s;

    height: 0;
    border-bottom: ${({ theme }) => `2px solid ${theme.colours.N040}`};
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    left: 100%;
    right: 0;

    ${({ isActive }) => {
      return (
        isActive &&
        `
        left: 0;
        right: 0;
    `
      )
    }}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      & ~ #item::after {
        left: 0;
        right: 100%;
      }
    `}

  svg {
    margin-bottom: 2px;
  }

  .title {
    font-size: 10px;
    line-height: 12px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 6px 8px 10px;

    &.tournament {
      color: ${({ theme }) => theme.colours.AP050};
      background: ${({ theme }) => theme.colours.AP050O};
      border-radius: 4px;
      padding: 8px;
      position: relative;
      overflow: hidden;

      .tournament-box {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &.betSlipStats {
      position: relative;
      background-image: ${({ theme }) => theme.colours.AP050G};
      color: ${({ theme }) => theme.colours.ST100};
      padding-top: 3px;
      padding-bottom: 6px;
      border: none;
      border-radius: 3px;

      .odds {
        font-size: 10px;
        font-weight: 400;
        line-height: 9px;
      }

      .sel {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`
