import { logic1X2 } from './1X2'
import { logicGGNG } from './GG'
import { SimMarket, Score, TimeSpan, CMGGNG } from './types'

export const logicCM_GGNG = (outcome: CMGGNG, score: Score) => {
  const oc: { [key in CMGGNG]: () => boolean } = {
    '1 or gg': () => logic1X2('1', score) || logicGGNG('gg', score),
    'x or gg': () => logic1X2('x', score) || logicGGNG('gg', score),
    '2 or gg': () => logic1X2('2', score) || logicGGNG('gg', score),
    '1 or ng': () => logic1X2('1', score) || logicGGNG('ng', score),
    'x or ng': () => logic1X2('x', score) || logicGGNG('ng', score),
    '2 or ng': () => logic1X2('2', score) || logicGGNG('ng', score),
  }
  return oc[outcome]() || false
}

const ocCM_GGNG = [
  '1 or gg',
  'x or gg',
  '2 or gg',
  '1 or ng',
  'x or ng',
  '2 or ng',
] as CMGGNG[]

const _CM_GGNG = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocCM_GGNG,
  logic: logicCM_GGNG,
})

export const FT_CM_GGNG = _CM_GGNG(14, 'FT')
