import styled from 'styled-components'
import { TReportRange, Range } from '../../../reports/store/reports.state'

export const FilterWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  background-color: ${({ theme }) => theme.colours.S000};
  display: flex;
  justify-content: flex-end;
  padding: 7px 4px 5px 0;
  z-index: 5;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
`

export const FilterContainer = styled.div`
  width: 225px;
  height: 20px;
  border-radius: 14px;
  border: solid 1px ${({ theme }) => theme.colours.S040};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const SelectorUnderlay = styled.span<{
  range: TReportRange
}>`
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 20px;
  border-radius: 11.5px;
  background-color: ${({ theme }) => theme.colours.S040};
  transform: ${({ range }) => {
    switch (range) {
      case Range.LASTSEVENDAYS:
        return 'translateX(-75px)'
      case Range.YESTERDAY:
        return 'translateX(0px)'
      case Range.TODAY:
        return 'translateX(75px)'
      default:
        return 'translateX(75px)'
    }
  }};
  transition: transform 200ms ease-out;
`

export const Selector = styled.span<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #fff;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: ${({ selected }) => (selected ? '1' : '0.6')};
  transition: opacity 200ms ease-out;
  cursor: pointer;
`
