import type { SimBet } from '../../../../sports/types'
import { SelectionWithMarket } from '../matchSelection/selectionWithMarket'
import { Selection } from '../matchSelection/selection'
import {
  Market,
  SelectionContainer,
  TitleWrapper,
  SelectionWrapper,
  Wrapper,
} from './matchSelection.style'
import { splitTeams } from '../../../../lib/utils/uiUtils'
import { SelectionTitle } from './selectionTitle'

interface IMatchSelection {
  bets: SimBet[]
}

export const MatchSelection: React.FC<IMatchSelection> = ({ bets }) => {
  const { market, event } = bets[0]
  const { team1, team2 } = splitTeams(event)

  const array: any = [undefined, undefined, undefined]
  const out = ['1', 'x', '2']

  for (let i = 0; i < 3; i++) {
    if (bets[i]?.simOutcome)
      array[out.findIndex((e) => e === bets[i]?.simOutcome)] = bets[i]
  }

  if (bets.length > 0 && (market === 'FT 1X2' || market === 'HT 1X2')) {
    return (
      <Wrapper>
        <Market>{market}</Market>
        <>
          <SelectionWithMarket
            key={array[0]?.b9jBet || 1}
            team={team1}
            odds={array[0]?.odd || undefined}
            bet={array[0] || undefined}
          />
          <SelectionWithMarket
            key={array[1]?.b9jBet || 2}
            team="draw"
            odds={array[1]?.odd || undefined}
            bet={array[1] || undefined}
          />
          <SelectionWithMarket
            key={array[2]?.b9jBet || 3}
            team={team2}
            odds={array[2]?.odd || undefined}
            bet={array[2] || undefined}
          />
        </>
      </Wrapper>
    )
  }

  return (
    <SelectionWrapper>
      <Market>{market}</Market>
      <div>
        <TitleWrapper>
          <SelectionTitle event={event} />
        </TitleWrapper>
        <SelectionContainer length={bets.length}>
          {bets.map((b) => {
            return <Selection key={b.b9jBet} odds={b.odd} bet={b} />
          })}
        </SelectionContainer>
      </div>
    </SelectionWrapper>
  )
}
