import { GGNG, SimMarket, Score, TimeSpan } from './types'

export const logicGGNG = (outcome: GGNG, score: Score) => {
  const oc: { [key in GGNG]: () => boolean } = {
    gg: () => score[0] > 0 && score[1] > 0,
    ng: () => score[0] === 0 || score[1] === 0,
  }
  return oc[outcome]() || false
}

const ocGGNG = ['gg', 'ng'] as GGNG[]

const _GGNG = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocGGNG,
  logic: logicGGNG,
})

export const FT_GGNG = _GGNG(11, 'FT')
export const HT_GGNG = _GGNG(40, 'HT')
