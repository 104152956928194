import { SimMarket, Score, TimeSpan, HomeAway } from './types'

export const logicMarginHomeAway = (outcome: HomeAway, score: Score) => {
  const oc: { [key in HomeAway]: () => boolean } = {
    'Away 1-5': () => score[1] - score[0] >= 1 && score[1] - score[0] <= 5,
    'Away 6-10': () => score[1] - score[0] >= 6 && score[1] - score[0] <= 10,
    'Away 11-15': () => score[1] - score[0] >= 11 && score[1] - score[0] <= 15,
    'Away 16-20': () => score[1] - score[0] >= 16 && score[1] - score[0] <= 20,
    'Away 21-25': () => score[1] - score[0] >= 21 && score[1] - score[0] <= 25,
    'Away 26+': () => score[1] - score[0] >= 25,
    'Home 1-5': () => score[0] - score[1] >= 1 && score[0] - score[1] <= 5,
    'Home 6-10': () => score[0] - score[1] >= 6 && score[0] - score[1] <= 10,
    'Home 11-15': () => score[0] - score[1] >= 11 && score[0] - score[1] <= 15,
    'Home 16-20': () => score[0] - score[1] >= 16 && score[0] - score[1] <= 20,
    'Home 21-25': () => score[0] - score[1] >= 21 && score[0] - score[1] <= 25,
    'Home 26+': () => score[0] - score[1] >= 25,
  }
  return oc[outcome]() ?? false
}

const ocMarginHomeAway = [
  'Home 1-5',
  'Home 6-10',
  'Home 11-15',
  'Home 16-20',
  'Home 21-25',
  'Home 26+',
  'Away 1-5',
  'Away 6-10',
  'Away 11-15',
  'Away 16-20',
  'Away 21-25',
  'Away 26+',
] as HomeAway[]

const _MarginHomeAway = (
  id: number,
  time: TimeSpan,
  handicap = 0
): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocMarginHomeAway,
  logic: logicMarginHomeAway,
})

export const WINHA = _MarginHomeAway(2301, 'FT')
