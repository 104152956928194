import styled from 'styled-components'
import { ModalProps } from './modal'

const Layer = styled.div`
  position: fixed;
  height: 100vh;
  max-width: 1024px;
  min-width: 320px;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
`

export const ModalWrapper = styled(Layer)<ModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 600;
  .box {
    position: absolute;
    min-width: 260px;
    max-width: 500px;
    min-height: 160px;
    top: 20vh;
    background: ${({ theme }) => theme.colours.S060};
    border-radius: 2px;
    padding: 15px;
    width: ${({ width }) => (width ? width : '90%')};
    height: ${({ height }) => (height ? height : 'auto')};
    z-index: 1000;
  }
`
