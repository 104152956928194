import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Won = styled(motion.div)`
  position: fixed;
  width: 100%;
  min-width: 320px;
  max-width: 1024px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  transition: all 200ms;
  padding: 8px;
  /* background: #32353d; */
  background: ${(props) => props.theme.colours.S090};
  border-radius: 6px 6px 0px 0px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colours.RP020};
    color: #fff;
    padding: 3px 10px 8px;
    border: 2px solid ${(props) => props.theme.colours.RP010};
    border-radius: 10px;
    .won-yes {
      font-weight: bold;
      font-size: 26.6667px;
      line-height: 31px;
    }
    .won-txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 3px;
      display: flex;
      gap: 4px;
    }
    .won-amount {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
`

export const Wrapper = styled(motion.footer)<{ status: number }>`
  position: fixed;
  width: 100%;
  padding: 8px;
  /* background: #32353d; */
  background: ${(props) => props.theme.colours.S090};
  max-width: 1024px;
  min-width: 320px;
  display: flex;
  bottom: 0px;
  margin: auto;
  box-shadow: ${({ status }) =>
    status !== 1 ? '0px -10px 12px -1px rgba(0, 0, 0, 0.35)' : ''};

  .footer-btn {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 6px;
    padding: 20px 0;
    :first-of-type {
      margin-right: 8px;
      background: ${(props) => props.theme.colours.S040};
    }
    :nth-of-type(2) {
      color: ${(props) => props.theme.colours.ST100};
      box-shadow: 0px 3px 0px #b5892e, 0px 6px 3px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
    }
  }
`

const Button = styled.button`
  /* height: 44px; */
  font-size: 13px;
  border: none;
  outline: none;

  &:active {
    background-color: ${({ theme }) => theme.colours.S050};
    color: ${({ theme }) => theme.colours.N040};
  }

  &:hover {
    cursor: pointer;
  }
`

export const ButtonDone = styled(Button)<{ width: string }>`
  font-weight: 500;
  width: ${({ width }) => width};
  color: ${({ theme }) => theme.colours.ST000};
  background-color: ${({ theme }) => theme.colours.S040};
`

export const RebetButton = styled(Button)`
  width: 67%;
  font-weight: 700;
  color: ${({ theme }) => theme.colours.ST100};
  background-color: ${({ theme }) => theme.colours.AP050};
`
