import styled from 'styled-components'

export const PaddingWrapper = styled.div`
  padding: 8px;
  overflow: auto;
`
export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`
export const Heading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colours.N040};
`
