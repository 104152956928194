import * as Sim from '../../sports/soccer/markets'
import * as SimBasketball from '../../sports/basketball/markets'

export const fixDateForAllBrowsers = (dateString: string) =>
  dateString.replace(/-/g, '/')

export const formatScore = (scr: string): number[] => {
  const [h, a] = scr.split('-')
  return [parseInt(h, 10), parseInt(a, 10)]
}

export const titleCase = (s: string) => {
  return s[0].toUpperCase() + s.slice(1).trim()
}

export const titleCaseSentence = (sentence: string) => {
  return sentence.replace(/\s+/g, ' ').split(' ').map(titleCase).join(' ')
}

export const truncateString = (input: string, length: number) => {
  return input.length > length ? input.substring(0, length) + '...' : input
}

export const splitTeams = (event: string) => {
  let team1: string, team2: string

  const splitOnDash = event.split(' - ')
  team1 = splitOnDash[0]
  team2 = splitOnDash[1]

  if (!team2) {
    const splitOnV = event.split(' V ')
    team1 = splitOnV[0]
    team2 = splitOnV[1]
  }

  return {
    team1: team1 ? titleCase(team1) : 'who ?',
    team2: team2 ? titleCase(team2) : 'who ?',
  }
}

export const showOutcome = (
  simOutcome: Sim.Outcome | SimBasketball.Outcome,
  event: string,
  spread?: string
) => {
  const map = {
    '1': splitTeams(event).team1,
    x: 'Draw',
    '2': splitTeams(event).team2,
    '12': 'Home / Away',
    '1x': 'Home / Draw',
    '2x': 'Away / Draw',
    o: `Over ${spread || ''}`,
    u: `Under ${spread || ''}`,
    gg: 'GoalGoal',
    ng: 'NoGoal',
    ov: 'Over',
    un: 'Under',
  } as Partial<{ [key in Sim.Outcome | SimBasketball.Outcome]: string }>
  return map[simOutcome] ?? titleCase(simOutcome)
}

export const showMarket = (market: Sim.Market | SimBasketball.Market) => {
  return market
    .replace(/_|\s/g, ' ')
    .split(' ')
    .map((str) => {
      // add more...
      const translations = {
        OU: 'O/U',
      } as any // oh well...
      return translations[str] ?? str
    })
    .join(' ')
    .trim()
}

// trunc number to fixed decimal places without rounding
export const toFixed = (num: number, fixed: number) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return num.toString().match(re)?.shift()
}
