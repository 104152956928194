import styled from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100vh;
`
export const ListWrapper = styled.div`
  /* margin: 50px 0 45px 0; */
  background-color: ${({ theme }) => theme.colours.S000};
  margin: 0px 0 45px 0;
  padding-bottom: 100px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  p {
    color: white;
    padding: 12px;
  }
`
