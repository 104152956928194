import { Boost } from '../../../../components/svgs'
import { StyledArrow } from './boost.style'

export const GameBoost: React.FC<{ display: boolean }> = ({ display }) => {
  return (
    <>
      {display && (
        <StyledArrow
          initial={{
            opacity: 1,
            scale: 1,
            y: 100,
          }}
          animate={{
            opacity: [1, 0.8, 0.7, 0],
            scale: 5,
            y: -2000,
            transition: { ease: 'easeIn', duration: 0.6 },
          }}
        >
          <Boost height="2000" width="80" viewBox="8 0 18 18" />
        </StyledArrow>
      )}
    </>
  )
}
