import { SimMarket, Score, TimeSpan, DC } from './types'
export const logicDC = (outcome: DC, score: Score) => {
  const oc: { [key in DC]: () => boolean } = {
    '1x': () => score[0] > score[1] || score[0] === score[1],
    '2x': () => score[0] < score[1] || score[0] === score[1],
    '12': () => score[0] !== score[1],
  }
  return oc[outcome]() || false
}

const ocDC = ['1x', '2x', '12'] as DC[]

const _DC = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocDC,
  logic: logicDC,
})

export const FT_DC = _DC(13, 'FT')
export const HT_DC = _DC(39, 'HT')
