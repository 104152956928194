import type { TournamentEvent } from '../../../../sports/types'
import { MatchStats } from '../matchStats/matchStats'
import { Wrapper } from './match.style'
import { MatchSelection } from '../matchSelection/matchSelection'

interface IMatch {
  event: TournamentEvent
}

export const Match: React.FC<IMatch> = ({ event }) => {
  const { bets, stats } = event

  if (!bets) {
    return <></>
  }
  const { event: eventName } = bets[0]

  return (
    <Wrapper>
      <MatchSelection bets={bets} key={event.market_id} />
      <MatchStats stats={stats} eventName={eventName} />
    </Wrapper>
  )
}
