import { useSelector } from 'react-redux'
import { selectActiveSport, setActiveSport } from '../../store/explore.state'
import { useAppDispatch } from '../../../../store/store'
import { TabsWrapper, Tab } from './exploreTabs.style'
import { Sport } from '../../../../sports/types'

type TSports = {
  id: number
  emoji: any
  name: Sport
}
const sports: TSports[] = [
  {
    id: 1,
    emoji: '⚽',
    name: 'Soccer',
  },
  {
    id: 2,
    emoji: '🏀',
    name: 'Basketball',
  },
]

export const ExploreTabs = () => {
  const dispatch = useAppDispatch()
  const activeSport = useSelector(selectActiveSport)

  return (
    <TabsWrapper>
      {sports.map((sport) => (
        <Tab
          key={sport.id}
          active={activeSport === sport.name}
          onClick={() => dispatch(setActiveSport(sport.name))}
        >
          <span className="emoji">{sport.emoji}</span>
          {sport.name}
        </Tab>
      ))}
    </TabsWrapper>
  )
}
