import { useState } from 'react'
import { useAppDispatch } from '../../../../store/store'
import { setActiveSport } from '../../store/explore.state'
import { IntlMessage } from '../../../../lang/IntlMessage'
import { PixelBasketball, Remove } from '../../../../components/svgs'
import { BannerWrapper } from './exploreBanner.style'

export const ExploreBanner = () => {
  const [show, setShow] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  return (
    <>
      {show && (
        <BannerWrapper>
          <div className="circle">
            <div className="circle_line"></div>
            <div className="circle_small"></div>
            <div className="circle_big"></div>
          </div>
          <div className="banner">
            <h3 className="banner_heading">
              <IntlMessage id="exploreBanner_heading" />
            </h3>
            <div className="banner_game">
              <PixelBasketball
                width="21"
                height="20"
                viewBox="0 0 21 21"
                className="banner_icon"
              />

              <p className="banner_title">
                <IntlMessage id="exploreBanner_title" />
              </p>
            </div>
          </div>
          <button
            title="Basketball"
            className="btn"
            data-testid="btnMsg"
            onClick={() => dispatch(setActiveSport('Basketball'))}
          >
            <IntlMessage id="exploreBanner_btn" />
          </button>
          <div className="delete" onClick={() => setShow(false)}>
            <Remove width="17" height="17" viewBox="0 0 24 24" />
          </div>
          <div className="stripe"></div>
        </BannerWrapper>
      )}
    </>
  )
}
