import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { IntlMessage } from '../../../../lang/IntlMessage'
import { selectOdds } from '../../../betslip/store/betslip.selectors'

export const TotalOdds = () => {
  const odds = useSelector(selectOdds)
  return (
    <Wrapper>
      <span className="heading">
        <IntlMessage id="totalOdds_heading" />
      </span>
      <span className="odds">{Number(odds.toFixed(2)).toLocaleString()}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colours.N030};
  .heading {
    font-size: 10px;
    margin-left: auto;
  }

  .odds {
    font-weight: 500;
    font-size: 16px;
  }
`
