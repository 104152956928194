import { useSelector } from 'react-redux'
import { selectGameAPIStatus } from '../../store/game.state'
import { BrandSimulate } from '../../../../components/svgs'
import {
  BackGround,
  Circle,
  RocketWrapper,
  LoaderLogo,
} from './gameLoader.style'

export const GameLoader = () => {
  const gameStatus = useSelector(selectGameAPIStatus)

  return (
    <>
      {gameStatus === 'loading' && (
        <RocketWrapper visible={true} data-testid="gameLoader">
          <BackGround>
            <LoaderLogo
              animate={{
                rotate: [0, -6, 0, 6, 0],
                translateX: [1, 0, -1, 1, 0],
              }}
              style={{ transformOrigin: 'top' }}
              transition={{
                repeat: Infinity,
                repeatType: 'mirror',
                duration: 0.25,
                stiffness: 30,
              }}
            >
              <BrandSimulate viewBox="-7 -10 42 42" />
            </LoaderLogo>
            <Circle
              animate={{
                opacity: 0,
                scale: 300,
              }}
              transition={{ duration: 1, repeat: Infinity }}
            />
          </BackGround>
        </RocketWrapper>
      )}
    </>
  )
}
