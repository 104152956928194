import { teams } from '../../../../lib/utils/teamSVGMap'
import { splitTeams, titleCase } from '../../../../lib/utils/uiUtils'
import { H6, TeamContainer } from './matchSelection.style'

export const SelectionTitle = ({ event }: { event: string }) => {
  const { team1, team2 } = splitTeams(event)

  const team1SVG = teams.get(team1.toUpperCase()) || 'Tottenham'
  const team2SVG = teams.get(team2.toUpperCase()) || 'Tottenham'

  return (
    <>
      <TeamContainer>
        <H6>{titleCase(team1)}</H6>
        <img
          title="flag"
          src={require(`../../../../components/svgs/shirts/${team1SVG}.svg`)}
          width="36"
          height="26"
        />
      </TeamContainer>
      <TeamContainer>
        <img
          title="flag"
          src={require(`../../../../components/svgs/shirts/${team2SVG}.svg`)}
          width="36"
          height="26"
        />
        <H6>{titleCase(team2)}</H6>
      </TeamContainer>
    </>
  )
}
