import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.colours.ST100};
  background-image: ${(props) => props.theme.colours.AP050G};
  border: 0.7px solid ${(props) => props.theme.colours.AP080};
  border-radius: 6px;
  box-shadow: 0px 3px 0px #b5892e, 0px 6px 3px rgba(0, 0, 0, 0.25);
  outline: none;
  position: relative;
  z-index: 50;

  &:active {
    padding-top: 3px;
    box-shadow: 0px 1px 0px #b5892e, 0px 2px 3px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    background: ${(props) => props.theme.colours.AP050};
  }

  &:disabled {
    color: ${(props) => props.theme.colours.N030};
    background: ${(props) => props.theme.colours.S050};
    border: 0.7px solid ${(props) => props.theme.colours.S050};
    box-shadow: 0px 6px 3px rgba(0, 0, 0, 0.25);
  }

  .stake {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
    path {
      fill: ${(props) => props.theme.colours.ST100};
    }
  }
`
