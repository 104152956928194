import styled from 'styled-components'

export const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  padding: 5px;
`
export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  min-width: 320px;
`
export const EventHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #2a2c33;
  color: ${({ theme }) => theme.colours.N040};
  /* margin-bottom: 4px; */
  width: calc(100% + 10px);

  .box {
    display: flex;
    align-items: center;
    padding: 0 5px;

    .eventName {
      font-size: 10px;
      line-height: 12px;
      margin-left: 2px;
    }

    .time {
      font-size: 10px;
      line-height: 12px;
    }

    .bold {
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
      margin: 0 4px;
    }

    .icon-clock {
      path {
        fill: ${(props) => props.theme.colours.N040};
      }
    }
  }
`
