import type { ReactNode } from 'react'
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message'
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl'
import { TMessages } from './types'

// Our new union type of all available message IDs.
// type IntlMessageKeys = keyof typeof messagesInEnglish;
type IntlMessageKeys = keyof TMessages

// Extend the original FormattedMessage props.
type IntlMessageProps = ReactIntlFormattedMessageProps<
  Record<string, ReactNode>
> & {
  id: IntlMessageKeys
}

export function IntlMessage({ id, ...rest }: IntlMessageProps) {
  return <ReactIntlFormattedMessage id={id} {...rest} />
}
