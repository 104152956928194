import { AnimatePresence } from 'framer-motion'
import { BetListObject } from '../../../../api/types'
import { Wrapper, Infos, PrintButton } from './couponDetail.style'
import React from 'react'
import { reduceOdds } from '../../../../store/utils'
import { useDispatch, useSelector } from 'react-redux'
import { setReceiptData } from '../../../receipt/store/receipt.state'
import { usePrintReceipt } from '../../../../hooks/usePrintReceipt'
import { CouponBet } from '../couponBet/couponBet'
import { selectIsCashier } from '../../../app/store/app.state'
import { IntlMessage } from '../../../../lang/IntlMessage'

interface ICouponDetailProps {
  bet: BetListObject
  expand: boolean
}
export const CouponDetail: React.FC<ICouponDetailProps> = ({ expand, bet }) => {
  const dispatch = useDispatch()
  const isCashier = useSelector(selectIsCashier)

  const { handlePrintGameReceipt } = usePrintReceipt()

  const [totalOdds, setTotalOdds] = React.useState<number>(0)

  React.useEffect(() => {
    if (bet.selections.length) {
      const oddsArray = bet.selections.map(({ odd }) => odd)
      const toOdds = reduceOdds(oddsArray)
      setTotalOdds(toOdds)
    }
  }, [bet])

  const handlePrint = () => {
    dispatch(
      setReceiptData({
        bet_id: bet.bet_id,
        client_id: bet.client_id,
        stake: bet.stake,
        win: bet.win,
        potencial_win: bet.stake * bet.total_odds,
        total_odds: bet.total_odds,
        placement_date: bet.placement_date,
        original: false,
        selections: bet.selections,
        status: bet.status,
      })
    )

    handlePrintGameReceipt(0)
  }

  return (
    <AnimatePresence>
      {expand && (
        <Wrapper
          variants={{
            open: { height: 'calc(100% - 52px)' },
            closed: { height: '0' },
          }}
          initial="closed"
          animate="open"
          exit="closed"
          transition={{ duration: 0.12 }}
        >
          <Infos>
            <div>
              <span>ID: </span>
              <span className="bold">{bet.bet_id}</span>
            </div>
            <div>
              <span>
                <IntlMessage id="couponDetail_odds" />:{' '}
              </span>
              <span className="bold">{totalOdds.toFixed(2)}</span>
            </div>
          </Infos>
          {bet.selections.map((selection, idx) => (
            <CouponBet
              key={selection.event_name + ' ' + idx}
              selection={selection}
              idx={idx}
            />
          ))}
          {isCashier ? (
            <PrintButton onClick={handlePrint}>
              <IntlMessage id="couponDetail_receipt" />
            </PrintButton>
          ) : undefined}
        </Wrapper>
      )}
    </AnimatePresence>
  )
}
