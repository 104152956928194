export const teams = new Map()
teams.set('BRENTFORD', 'Brentford')
teams.set('BRENTFORD FC', 'Brentford')
teams.set('EVERTON', 'Everton')
teams.set('EVERTON FC', 'Everton')
teams.set('WOLVES', 'Wolves')
teams.set('WOLVERHAMPTON WANDERERS', 'Wolves')
teams.set('TOTTENHAM', 'Tottenham')
teams.set('TOTTENHAM HOTSPUR', 'Tottenham')
teams.set('CRYSTAL PALACE', 'Palace')
teams.set('FULHAM', 'Fulham')
teams.set('FULHAM FC', 'Fulham')
teams.set('LEICESTER CITY', 'Leicester')
teams.set('NEWCASTLE UTD', 'Newcastle')
teams.set('NEWCASTLE UNITED', 'Newcastle')
teams.set('SOUTHAMPTON', 'Southampton')
teams.set('SOUTHAMPTON FC', 'Southampton')
teams.set('ASTON VILLA', 'Villa')
teams.set('LIVERPOOL', 'Liverpool')
teams.set('LIVERPOOL FC', 'Liverpool')
teams.set('ARSENAL', 'Arsenal')
teams.set('ARSENAL FC', 'Arsenal')
teams.set('WEST HAM', 'WestHam')
teams.set('WEST HAM UNITED', 'WestHam')
teams.set('CHELSEA', 'Chelsea')
teams.set('CHELSEA FC', 'Chelsea')
teams.set('BOURNEMOUTH', 'Bournemouth')
teams.set('AFC BOURNEMOUTH', 'Bournemouth')
teams.set('MANCHESTER UTD', 'ManUtd')
teams.set('MANCHESTER UNITED', 'ManUtd')
teams.set('NOTTINGHAM FOREST', 'Forest')
teams.set('LEEDS UTD', 'Leeds')
teams.set('LEEDS UNITED', 'Leeds')
teams.set('MANCHESTER CITY', 'ManCity')
teams.set('BRIGHTON', 'Brighton')
teams.set('BRIGHTON & HOVE ALBION', 'Brighton')
