import styled from 'styled-components'

export const Wrapper = styled.div`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.58;
  text-align: center;

  .btn {
    color: white;
    background-color: ${({ theme }) => theme.colours.S040};
    border: none;
    box-shadow: 0 4px 4px -3px rgb(0 0 0 / 50%),
      inset 0 1px 0 0 rgb(255 255 255 / 5%);
    width: 100%;
    height: 44px;
  }

  .message {
    margin: 0.8rem 0 2rem 0;
  }
`
