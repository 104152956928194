import { PostMessage } from '../lib/postMessageValidator'
import { fakeOTPs } from './mockCredentials'

export const newMobilePM: PostMessage = {
  stake: 100,
  otp: fakeOTPs.nofail_d,
  source: 'new-mobile',
  selections: [
    {
      selection: '44109754$VS_OU@1.5_O',
      event: 'Z.Monaco - Z.Reims',
      market: 'Over/Under Goals',
      sign: 'Over (1.5) Goals',
      odd: 1.32,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '78765$S_1X2_X',
      event: 'Juventus - Napoli',
      market: '1X2',
      sign: 'Draw',
      odd: 3.2,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '99927928$S_1X2_1',
      event: 'Lazio - Roma',
      market: '1X2',
      sign: 'Lazio',
      odd: 2,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    // {
    //   selection: '86604789$B_1X21P_11Q',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: '1st Quarter - 1X2',
    //   sign: '1',
    //   odd: '1.61',
    // },
    // {
    //   selection: '86604794$B_1X21P_11Q',
    //   event: 'Indiana Pacers - Washington Wizards',
    //   market: '1st Quarter - 1X2',
    //   sign: '1',
    //   odd: '2.01',
    // },
    // {
    //   selection: '86604795$B_1X21P_11Q',
    //   event: 'Miami Heat - Chicago Bulls',
    //   market: '1st Quarter - 1X2',
    //   sign: '1',
    //   odd: '1.62',
    // },
    // {
    //   selection: '86605348$B_1X21P_11Q',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: '1st Quarter - 1X2',
    //   sign: '1',
    //   odd: '1.67',
    // },
    // {
    //   selection: '89105855$B_1X21P_11Q',
    //   event: 'Detroit Pistons - Orlando Magic',
    //   market: '1st Quarter - 1X2',
    //   sign: '1',
    //   odd: '1.71',
    // },
    // {
    //   selection: '86604789$B_1X21T_1HT',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: '1st Half - 1X2',
    //   sign: '1 HT',
    //   odd: '1.51',
    // },
    // {
    //   selection: '86605348$B_1X21T_1HT',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: '1st Half - 1X2',
    //   sign: '1 HT',
    //   odd: '1.57',
    // },
    // {
    //   selection: '89105855$B_1X21T_1HT',
    //   event: 'Detroit Pistons - Orlando Magic',
    //   market: '1st Half - 1X2',
    //   sign: '1 HT',
    //   odd: '1.62',
    // },
    // {
    //   selection: '89347370$B_1X21T_1HT',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: '1st Half - 1X2',
    //   sign: '1 HT',
    //   odd: '1.51',
    // },
    // {
    //   selection: '89347383$B_1X21T_1HT',
    //   event: 'Detroit Pistons - Orlando Magic',
    //   market: '1st Half - 1X2',
    //   sign: '1 HT',
    //   odd: '1.62',
    // },
    // {
    //   selection: '83300447$B_12_1',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: '2 Way',
    //   sign: '1',
    //   odd: '15.00',
    // },
    // {
    //   selection: '83300447$B_1X21P_11Q',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: '1st Quarter - 1X2',
    //   sign: '1',
    //   odd: '6.30',
    // },
    // {
    //   selection: '83300447$B_1X21T_1HT',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: '1st Half - 1X2',
    //   sign: '1 HT',
    //   odd: '11',
    // },
    // {
    //   selection: '234190951$B_OUN@158.5_O',
    //   event: 'Dallas Wings - New York Liberty',
    //   market: 'Over/Under',
    //   sign: 'Over (158.5)',
    //   odd: '1.59',
    // },
    // {
    //   selection: '234437401$B_OUN@160.5_U',
    //   event: 'Chicago Sky - Seattle Storm',
    //   market: 'Over/Under',
    //   sign: 'Under (160.5)',
    //   odd: '2.26',
    // },
    // {
    //   selection: '234437403$B_1X2HNDN@5.5_XH',
    //   event: 'Las Vegas Aces - Atlanta Dream',
    //   market: '1X2 (5.5 points)',
    //   sign: 'Draw',
    //   odd: '3.65',
    // },
    // {
    //   selection: '237437404$B_MARGINAWAY_Away11-15',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: 'Margin Away',
    //   sign: 'Away 11-15',
    //   odd: '13.25',
    // },
    // {
    //   selection: '237438129$B_MARGINHOME_Home1-5',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: 'Margin Home',
    //   sign: 'Home 1-5',
    //   odd: '5.4',
    // },
    // {
    //   selection: '237760561$B_MARGINHOME_Home1-5',
    //   event: 'Atlanta Hawks - Houston Rockets',
    //   market: 'Margin Home',
    //   sign: 'Home 1-5',
    //   odd: '5.05',
    // },
    // {
    //   selection: '237760566$B_MARGINAWAY_Away25+',
    //   event: 'Indiana Pacers - Washington Wizards',
    //   market: 'Margin Away',
    //   sign: 'Away 25+',
    //   odd: '12.25',
    // },
    // {
    //   selection: '251929980$B_OU1Q@36.5_Over',
    //   event: 'Pallacanestro 2.015 Forli - AS Pistoia Basket 2000',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Over (36.5)',
    //   odd: '1.89',
    // },
    // {
    //   selection: '251929983$B_OU1Q@38.5_Under',
    //   event: 'Kleb Basket Ferrara - Chieti Basket 1974',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Under (38.5)',
    //   odd: '1.71',
    // },
    // {
    //   selection: '251930014$B_OU1Q@39.5_Over',
    //   event: 'Frata Nardo - Pallacanestro Mantovana',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Over (39.5)',
    //   odd: '1.88',
    // },
    // {
    //   selection: '252145477$B_OU1Q@37.5_Under',
    //   event: 'Casale Monferrato - Pallacanestro Trapani',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Under (37.5)',
    //   odd: '1.89',
    // },
  ],
}

export const newDesktopPM: PostMessage = {
  stake: 100,
  otp: fakeOTPs.nofail_d,
  source: 'new-desktop',
  selections: [
    {
      selection: '44109754$VS_OU@1.5_O',
      event: 'Z.Monaco - Z.Reims',
      market: 'Over/Under Goals',
      sign: 'Over (1.5) Goals',
      odd: 1.32,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '78765$S_1X2_X',
      event: 'Juventus - Napoli',
      market: '1X2',
      sign: 'Draw',
      odd: 3.2,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '99927928$S_1X2_1',
      event: 'Lazio - Roma',
      market: '1X2',
      sign: 'Lazio',
      odd: 2,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
  ],
}

export const mobileLitePM: PostMessage = {
  stake: 100,
  otp: fakeOTPs.nofail_d,
  source: 'mobile-lite',
  selections: [
    {
      selection: '44109754$VS_OU@1.5_O',
      event: 'Z.Monaco - Z.Reims',
      market: 'Over/Under Goals',
      sign: 'Over (1.5) Goals',
      odd: 1.32,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '78765$S_1X2_X',
      event: 'Juventus - Napoli',
      market: '1X2',
      sign: 'Draw',
      odd: 3.2,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '99927928$S_1X2_1',
      event: 'Lazio - Roma',
      market: '1X2',
      sign: 'Lazio',
      odd: 2,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
  ],
}

export const selfServicePM: PostMessage = {
  stake: 200,
  otp: fakeOTPs.nofail_d,
  source: 'self-service',
  selections: [
    {
      selection: '45285598$S#UnoXDue#UnoXDue_X',
      event: 'SJK Akatemia - FF Jaro',
      market: '1X2',
      sign: 'X',
      odd: 1.25,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '45351262$S#OverUnder25#OverUnder25_Over',
      event: 'Shrewsbury Town - Bristol Rovers',
      market: 'O/U 2.5 ',
      sign: 'Over',
      odd: 2.03,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '45329720$S#HalfTime#HalfTime_X',
      event: 'Lincoln City - Plymouth Argyle',
      market: 'Half Time',
      sign: 'X HT',
      odd: 2.09,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    // {
    //   selection: '253931503$B#OverUnderBasket110-300#Over138basket',
    //   event: 'Dinamo Sassari - Akronos Moncalieri',
    //   market: 'O/U Points 110-139',
    //   sign: 'Over 138.5',
    //   odd: '1.63',
    // },
    // {
    //   selection: '83300447$B#UnoDue#UnoDue_1',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: '1 - 2 ',
    //   sign: '1HH',
    //   odd: '15.00',
    // },
    // {
    //   selection: '237438129$B#1x2 Margin Basket#X Margin 5 Basket',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: '1X2 (5.5)',
    //   sign: 'X',
    //   odd: '2.74',
    // },
    // {
    //   selection: '244043316$B#Ov/Un Q1 Basket#Over Q1 Basket',
    //   event: 'Correcaminos UAT Victoria - Halcones de Xalapa',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Over 1stQ(44.5)',
    //   odd: '1.81',
    // },
    // {
    //   selection: '244043315$B#Ov/Un Q1 Basket#Under Q1 Basket',
    //   event: 'Abejas de Leon - Plateros de Fresnillo',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Under 1stQ(44.5)',
    //   odd: '1.79',
    // },
    // {
    //   selection: '237437404$B#OverUnderBasket200-229#Under229Basket',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: 'O/U Points 200-229',
    //   sign: 'Under 229.5',
    //   odd: '1.77',
    // },
    // {
    //   selection: '244043316$B#OverUnderBasket170-199#Over176basket',
    //   event: 'Correcaminos UAT Victoria - Halcones de Xalapa',
    //   market: 'O/U Points 170-199',
    //   sign: 'Over 176.5',
    //   odd: '1.69',
    // },
    // {
    //   selection: '243757882$B#OverUnderBasket140-169#Under162basket',
    //   event: 'Gigantes de Guayana - Gladiadores de Anzoategui',
    //   market: 'O/U Points 140-169',
    //   sign: 'Under 162.5',
    //   odd: '1.83',
    // },
  ],
}

export const desktopPM: PostMessage = {
  stake: 200,
  otp: fakeOTPs.nofail_d,
  source: 'desktop',
  selections: [
    {
      selection: '45285598$S#UnoXDue#UnoXDue_X',
      event: 'SJK Akatemia - FF Jaro',
      market: '1X2',
      sign: 'X',
      odd: 1.25,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '45351262$S#OverUnder25#OverUnder25_Over',
      event: 'Shrewsbury Town - Bristol Rovers',
      market: 'O/U 2.5 ',
      sign: 'Over',
      odd: 2.03,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '45329720$S#HalfTime#HalfTime_X',
      event: 'Lincoln City - Plymouth Argyle',
      market: 'Half Time',
      sign: 'X HT',
      odd: 2.09,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    // {
    //   selection: '237438129$B#Margin Home Basket#Margin Home 1-5',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: 'Margin Home',
    //   sign: 'Home 1-5',
    //   odd: '5.30',
    // },
    // {
    //   selection: '237437404$B#Margin Home Basket#Margin Home 26+',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: 'Margin Home',
    //   sign: 'Home 26+',
    //   odd: '10.25',
    // },
    // {
    //   selection: '237760570$B#Margin Away Basket#Margin Away 6-10',
    //   event: 'Detroit Pistons - Orlando Magic',
    //   market: 'Margin Away',
    //   sign: 'Away 6-10',
    //   odd: '7.90',
    // },
    // {
    //   selection: '237760566$B#Margin Away Basket#Margin Away 26+',
    //   event: 'Indiana Pacers - Washington Wizards',
    //   market: 'Margin Away',
    //   sign: 'Away 26+',
    //   odd: '12.25',
    // },
    // {
    //   selection: '83300447$B#UnoDue#UnoDue_1',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: '1 - 2 ',
    //   sign: '1HH',
    //   odd: '15.00',
    // },
    // {
    //   selection: '83300447$B#UnOXDue1stQ#UnOXDue1stQ_X1stQ',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: '1X2 1stQ',
    //   sign: 'X 1stQ',
    //   odd: '14.50',
    // },
    // {
    //   selection: '83300447$B#Halftime Basket#Haltime Basket 1HT',
    //   event: 'A1 Challenge - Ucu Lady Cannons',
    //   market: 'Halftime',
    //   sign: '1 HT',
    //   odd: '11.00',
    // },
    // {
    //   selection: '237438129$B#1x2 Margin Basket#X Margin 5 Basket',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: '1X2 (5.5)',
    //   sign: 'X',
    //   odd: '2.74',
    // },
    // {
    //   selection: '237437404$B#OverUnderBasket200-229#Under229Basket',
    //   event: 'Golden State Warriors - Los Angeles Lakers',
    //   market: 'O/U Points 200-229',
    //   sign: 'Under 229.5',
    //   odd: '1.77',
    // },
    // {
    //   selection: '237438129$B#OverUnderBasket200-229#Over209Basket',
    //   event: 'Boston Celtics - Philadelphia 76ers',
    //   market: 'O/U Points 200-229',
    //   sign: 'Over 209.5',
    //   odd: '1.61',
    // },
    // {
    //   selection: '244043316$B#OverUnderBasket170-199#Over176basket',
    //   event: 'Correcaminos UAT Victoria - Halcones de Xalapa',
    //   market: 'O/U Points 170-199',
    //   sign: 'Over 176.5',
    //   odd: '1.69',
    // },
    // {
    //   selection: '243757882$B#OverUnderBasket140-169#Under162basket',
    //   event: 'Gigantes de Guayana - Gladiadores de Anzoategui',
    //   market: 'O/U Points 140-169',
    //   sign: 'Under 162.5',
    //   odd: '1.83',
    // },
    // {
    //   selection: '251930014$B#Ov/Un Q1 Basket#Over Q1 Basket',
    //   event: 'Frata Nardo - Pallacanestro Mantovana',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Over 1stQ(39.5)',
    //   odd: '1.88',
    // },
    // {
    //   selection: '252145477$B#Ov/Un Q1 Basket#Under Q1 Basket',
    //   event: 'Casale Monferrato - Pallacanestro Trapani',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Under 1stQ(37.5)',
    //   odd: '1.89',
    // },
    // {
    //   selection: '251929999$B#Ov/Un Q1 Basket#Over Q1 Basket',
    //   event: 'Basket Torino - UCC Piacenza',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Over 1stQ(38.5)',
    //   odd: '1.87',
    // },
    // {
    //   selection: '251930006$B#Ov/Un Q1 Basket#Under Q1 Basket',
    //   event: 'Blu Basket 1971 - ASD Latina Basket',
    //   market: 'Over/Under 1stQ',
    //   sign: 'Under 1stQ(39.5)',
    //   odd: '1.86',
    // },
  ],
}

export const oldMobilePM: PostMessage = {
  stake: 200,
  otp: fakeOTPs.nofail_d,
  source: 'old-mobile',
  selections: [
    {
      selection: '45285598$S#UnoXDue#UnoXDue_X',
      event: 'SJK Akatemia - FF Jaro',
      market: '1X2',
      sign: 'X',
      odd: 1.25,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '45351262$S#OverUnder25#OverUnder25_Over',
      event: 'Shrewsbury Town - Bristol Rovers',
      market: 'O/U 2.5 ',
      sign: 'Over',
      odd: 2.03,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
    {
      selection: '45329720$S#HalfTime#HalfTime_X',
      event: 'Lincoln City - Plymouth Argyle',
      market: 'Half Time',
      sign: 'X HT',
      odd: 2.09,
      tournament_id: 12345,
      tournament_name: 'Premier League',
    },
  ],
}
