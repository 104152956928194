import styled from 'styled-components'

type PercentBarProps = {
  min: number
  max: number
  value: number
  label: string
}
export const PercentBar: React.FC<PercentBarProps> = ({
  min = 0,
  max = 100,
  value = 0,
  label,
}) => {
  const calculateProgress = ({
    min,
    max,
    value,
  }: Omit<PercentBarProps, 'label'>) => {
    const progress = value - min
    const offsetMax = max - min

    if (max <= 0 || min < 0 || progress <= 0) {
      return 0
    }

    if (progress > offsetMax) {
      return 100
    }
    return Math.round((progress / offsetMax) * 100 * 10) / 10
  }

  const percentage = calculateProgress({ min, max, value })

  return (
    <Wrapper>
      <ProgressBar role="progressbar" aria-label={label}>
        <Bar progress={percentage} />
      </ProgressBar>
      <Percentage>{percentage} %</Percentage>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const ProgressBar = styled.div`
  background: ${(props) => props.theme.colours.N055};
  border-radius: 120px;
  height: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`

const Percentage = styled.p`
  white-space: nowrap;
  margin: 0 0 0 5px;
  color: #fff;
  font-weight: bold;
  min-width: 30px;
  text-align: right;
`

const Bar = styled.div<{ progress: number }>`
  background-color: ${(props) => props.theme.colours.AP050};
  background-image: ${(props) => props.theme.colours.AP051G};
  border-radius: 4px;
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  animation: load 1s ease;

  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: width;
    }
  }
`
