import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  selectActiveSelections,
  selectOdds,
} from '../../features/betslip/store/betslip.selectors'
import { useNavigate } from 'react-router'
import {
  BrandSimulate,
  Compas,
  Avatar,
  AvatarFilled,
  CompasFilled,
  Report,
  ReportFilled,
  SoccerYellow,
} from '../svgs'
import { NavItem, Navigation } from './navBar.style'
import { useSource } from '../../hooks/useSource'
import { IntlMessage } from '../../lang/IntlMessage'
import { TMessages } from '../../lang/types'
import { selectPermissions } from '../../features/app/store/app.state'

const Title = ({ title }: { title: string }) => (
  <span className="title">
    <IntlMessage id={title as keyof TMessages} />
  </span>
)

export const NavBar = () => {
  const [activeItemId, setActiveId] = useState('')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeSelections = useSelector(selectActiveSelections)
  const odds = useSelector(selectOdds)
  const { isCashierAndSelfService } = useSource()

  const permissions = useSelector(selectPermissions)
  const isTournament = permissions?.tournament_explore
  const getClassNames = (path: string) => {
    let classNames = ''

    if (pathname === path) {
      classNames += ' active'
    }
    return classNames
  }

  const selectActive = (id: string, path: string) => {
    navigate(path)
    setActiveId(id)
  }

  useEffect(() => {
    switch (pathname) {
      case '/':
        return setActiveId('betslip')
      case '/explore':
        return setActiveId('explore')
      case '/mybets':
      case '/mybets/freebets':
        return setActiveId('mybets')
      case '/report':
        return setActiveId('report')
      case '/tournament':
        return setActiveId('tournament')
    }
  }, [pathname])

  function renderNavItemContent({
    label,
    iconActive,
    iconInactive,
    className,
    path,
  }: any) {
    const isBetSlipStats = className === 'betSlipStats'
    const isTournamentBtn = className === 'tournament'

    if (isTournamentBtn && !isTournament) {
      return
    }

    return (
      <div className={`wrapper ${className}`}>
        {isBetSlipStats && (
          <>
            <span className="odds">{odds.toFixed(2)}</span>
            <span className="sel">{activeSelections}</span>
          </>
        )}

        {isTournamentBtn && isTournament ? (
          <div className="tournament-box">
            {!isBetSlipStats && pathname === path ? iconActive : iconInactive}

            <Title title={label as string} />
          </div>
        ) : (
          <>
            {!isBetSlipStats && pathname === path ? iconActive : iconInactive}

            <Title title={label as string} />
          </>
        )}
      </div>
    )
  }

  const links = [
    {
      id: 'explore',
      path: '/explore',
      iconInactive: <Compas width="24" height="24" viewBox="0 0 24 24" />,
      iconActive: <CompasFilled width="24" height="24" viewBox="0 0 24 24" />,
      label: 'navBar_linkExplore',
      className: 'explore',
    },
    {
      id: 'betslip',
      path: '/',
      iconActive: <BrandSimulate width="24" height="24" viewBox="0 0 24 24" />,
      label: 'navBar_linkBetSlip',
      className: pathname !== '/' ? 'betSlipStats' : 'betSlip',
    },
    {
      id: 'mybets',
      path: '/mybets',
      iconInactive: <Avatar width="24" height="24" viewBox="0 0 24 24" />,
      iconActive: <AvatarFilled width="24" height="24" viewBox="0 0 24 24" />,
      label: 'navBar_linkMyBets',
      className: 'mybets',
    },
    {
      id: 'report',
      path: '/report',
      iconInactive: <Report width="24" height="24" viewBox="0 0 24 24" />,
      iconActive: <ReportFilled width="24" height="24" viewBox="0 0 24 24" />,
      label: 'navBar_linkReport',
      className: 'report',
    },
    {
      id: 'tournament',
      path: '/tournament',
      iconInactive: <SoccerYellow width="20" height="20" viewBox="1 0 20 20" />,
      iconActive: <SoccerYellow width="20" height="20" viewBox="1 0 20 20" />,
      label: 'navBar_linkTournament',
      className: 'tournament',
    },
  ].filter(
    (link) =>
      link.label !== 'navBar_linkReport' ||
      (link.label === 'navBar_linkReport' && isCashierAndSelfService)
  )

  return (
    <div>
      <Navigation count={links.length}>
        {links.map((link) => (
          <NavItem
            id="item"
            isActive={link.id === activeItemId}
            key={link.label}
            onClick={() => selectActive(link.id, link.path)}
            className={getClassNames(link.path)}
            data-testid="navItem"
            isTournament={isTournament}
          >
            {renderNavItemContent(link)}
          </NavItem>
        ))}
      </Navigation>
    </div>
  )
}
