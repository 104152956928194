import { createSelector } from '@reduxjs/toolkit'
import { BetRequest, SelectionRequest } from '../../../api/types'
import {
  selectSessionID,
  selectSource,
  selectAccepOdds,
} from '../../app/store/app.state'
import { RootState } from '../../../store/store'
import { filterSelections, totalOdds, totalToWin } from '../../../store/utils'

// selectors -----------------------------------------------------------------------

export const selectBeslip = (state: RootState) => state.betslip
export const selectStake = (state: RootState) => state.betslip.stake
export const selectKeyboard = (state: RootState) => state.betslip.keyboard
export const selectMenu = (state: RootState) => state.betslip.menu
export const selectBets = (state: RootState) => state.betslip.bets

export const selectNotification = (state: RootState) =>
  state.betslip.notification

export const selectFreeBetsDrawer = (state: RootState) =>
  state.betslip.freeBetsDrawer

export const selectActivefreeBet = (state: RootState) =>
  state.betslip.activeFreeBet

export const selectFreeBetAmount = (state: RootState) =>
  state.betslip.activeFreeBet.amount

export const selectFreeBets = (state: RootState) =>
  state.betslip.incentives?.free_bets || []

export const selectFreeBetLength = (state: RootState) =>
  state.betslip.incentives?.free_bets?.length || 0

export const selectDisableDigit = (state: RootState) =>
  state.betslip.stake.toString().length >= 7

export const seletFilteredSelections = createSelector(selectBets, (bets) =>
  filterSelections(bets)
)

export const selectOdds = createSelector(
  seletFilteredSelections,
  (bets): number => totalOdds(bets)
)

export const selectToWin = createSelector(
  [selectOdds, selectStake],
  (odds: number, stake: number): number => totalToWin(odds, stake)
)

export const selectBlocking = createSelector(selectBets, (bets) =>
  bets.some((bet) => bet.blocking === true)
)

export const selectDisplayStake = createSelector(
  [selectStake, selectBlocking],
  (stake, blocked) =>
    !blocked ? (stake > 0 ? stake.toLocaleString() : '') : ''
)

export const selectActiveSelections = createSelector(
  seletFilteredSelections,
  (bets): number => bets.filter((bets) => !bets.blocking).length
)

export const selectFreeBetToWin = createSelector(
  [selectOdds, selectFreeBetAmount, selectStake],
  (odds: number, stake: number, freeBet: number): number =>
    totalToWin(odds, stake) - freeBet
)

export const selectDisableQuickStake = createSelector(
  [selectActivefreeBet, selectStake, selectBlocking],
  (activeFB, stake, blockUI) =>
    stake.toString().length >= 7 || blockUI || activeFB.amount > 0
)

export const selectPlaceBet = createSelector(
  [
    selectFreeBetAmount,
    selectSessionID,
    selectBlocking,
    selectActiveSelections,
  ],
  (freeBetAmount, sessionID, blockUI, activeSelections) => ({
    disablePlaceBet: !sessionID || blockUI || activeSelections === 0,
    freeBetAmount,
    sessionID,
  })
)

export const selectCockpitValues = createSelector(
  [
    selectStake,
    selectOdds,
    selectToWin,
    selectActiveSelections,
    selectBlocking,
    selectKeyboard,
    selectFreeBetAmount,
    selectFreeBetToWin,
  ],
  (
    stake,
    odds,
    toWin,
    activeSelections,
    blocked,
    keyboard,
    freeBetAmount,
    freeBetToWin
  ) => ({
    stake: !blocked ? (stake > 0 ? stake : '') : '',
    odds: !blocked ? odds : 0.0,
    toWin: !blocked ? (freeBetToWin ? freeBetToWin : toWin) : 0.0,
    activeSelections,
    blocked,
    keyboard,
    freeBetAmount,
  })
)

export const selectBetAPIData = createSelector(
  [
    selectSessionID,
    selectActivefreeBet,
    selectStake,
    selectBets,
    selectSource,
    selectAccepOdds,
  ],
  (
    sessionID,
    { free_bet_uid },
    stake,
    bets,
    source,
    accept_odds_changes
  ): BetRequest => {
    const selections: SelectionRequest[] = filterSelections(bets).map(
      (selection) => {
        const {
          sport,
          event,
          simMarketID,
          simOutcome,
          odd,
          b9jBet,
          odd_id,
          matchID,
          tournament_id,
          tournament_name,
        } = selection
        return {
          b9j_bet: b9jBet || 'no-code-found',
          sport_id: sport,
          event_name: event,
          event_id: matchID + '',
          selected_option: simOutcome,
          market_id: simMarketID || 0,
          odd,
          odd_id,
          tournament_id,
          tournament_name,
        }
      }
    )

    return {
      selections,
      stake,
      sessionID,
      free_bet_token: free_bet_uid,
      source,
      accept_odds_changes,
    }
  }
)

// TO TEST ---------------------------------------------------------------------------------
