import { SimMarket, Score, TimeSpan, MULTIGOAL } from './types'
export const logicMG = (outcome: MULTIGOAL, score: Score) => {
  const fork = outcome
    .replace(' goals', '')
    .split('-')
    .map((val) => parseInt(val, 10))
  const totalGoals = score[0] + score[1]
  const comparison = fork[0] <= totalGoals && fork[1] >= totalGoals
  return comparison
}

const ocMG = [
  '1-2 goals',
  '1-3 goals',
  '1-4 goals',
  '1-5 goals',
  '1-6 goals',
  '2-3 goals',
  '2-4 goals',
  '2-5 goals',
  '2-6 goals',
  '3-4 goals',
  '3-5 goals',
  '3-6 goals',
  '4-5 goals',
  '4-6 goals',
  '5-6 goals',
] as MULTIGOAL[]

const _MG = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocMG,
  logic: logicMG,
})

export const FT_MG = _MG(31, 'FT')
