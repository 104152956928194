import { ModalWrapper } from './modal.style'
import { Backdrop } from '../backdrop/backdrop'

export type ModalProps = {
  height?: string
  width?: string
  children?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({ height, width, children }) => (
  <div data-testid="component-modal">
    <Backdrop />
    <ModalWrapper height={height} width={width}>
      <div className="box">{children}</div>
    </ModalWrapper>
  </div>
)
