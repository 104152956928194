import React, { MutableRefObject } from 'react'
import { Keyboard } from '../keyboard/keyboard'
import { InputStake } from '../inputStake/inputStake'
import { PlaceBetButton } from '../placeBetButton/placeBetButton'
import { QuickStakes } from '../quickStake/quickStake'
import { TotalOdds } from '../totalOdds/totalOdds'
import { WinBar } from '../winBar/winBar'
import {
  ActionAreaWrapper,
  CockpitWrapper,
  InputWrapper,
  Wrapper,
  AcceptOddsWrapper,
} from './actionArea.style'
import { AcceptOdds } from '../acceptOdds/acceptOdds'
import { SpeedDrawer } from '../speed-drawer/speedDrawer'
import { useSource } from '../../../../hooks/useSource'

interface IActionArea {
  betslipRef: MutableRefObject<HTMLDivElement | null>
}
export const ActionArea: React.FC<IActionArea> = ({ betslipRef }) => {
  const { isCashierAndSelfService } = useSource()
  return (
    <ActionAreaWrapper data-testid="actionArea">
      {isCashierAndSelfService ? (
        <AcceptOddsWrapper>
          <AcceptOdds alignLeft />
        </AcceptOddsWrapper>
      ) : (
        <Wrapper>
          <SpeedDrawer />
          <AcceptOdds />
        </Wrapper>
      )}
      <CockpitWrapper>
        <InputWrapper>
          <TotalOdds />
          <InputStake />
        </InputWrapper>
        <QuickStakes />
        <WinBar />
        <Keyboard betslipRef={betslipRef} />
      </CockpitWrapper>
      <PlaceBetButton />
    </ActionAreaWrapper>
  )
}
