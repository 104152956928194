import { MotionProps } from 'framer-motion'
import { StyledBackdrop } from './backdrop.style'

type IBackdrop = {
  onClick?: () => void
  children?: React.ReactNode
} & MotionProps

export const Backdrop: React.FC<IBackdrop> = ({
  onClick,
  children,
  ...rest
}) => (
  <StyledBackdrop onClick={onClick} {...rest} data-testid="backdrop">
    {children}
  </StyledBackdrop>
)
