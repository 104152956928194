import { BetRequest, BetResponse } from '../../../api/types'
import {
  switchOnSource,
  errorHandling,
  statusHandling,
} from '../../../api/utils'
import { targetUrl } from '../../../config'

/**
 * POST a simulate object to retreive a Simulation Data,
 * @param data Formated for the backend request
 */

export const postBet = async ({
  selections,
  stake,
  accept_odds_changes,
  free_bet_token,
  source,
  sessionID,
}: BetRequest): Promise<BetResponse> => {
  const payload = {
    selections,
    stake,
    accept_odds_changes,
    free_bet_token,
    source: switchOnSource(source),
  }

  const response = await fetch(`${targetUrl()}/simulate/Bet`, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-Type': 'application/json',
      'X-SID': `${sessionID}`,
    },
    body: JSON.stringify(payload),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return statusHandling(await response?.json())
}
