import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  margin: 8px 0px;
  background: ${({ theme }) => theme.colours.S050G};
  border-radius: 3px;
  min-width: 308px;
  max-width: 320px;
`

export const Info = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  margin: 5px 0;
  padding: 4px 0;
  span {
    font-size: 11px;
    &.key {
      opacity: 0.8;
    }
  }
`

export const DateS = styled(Info)`
  padding-top: 16px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
`
export const Total = styled(Info)`
  border-top: 1px solid ${({ theme }) => theme.colours.N040};
  padding-top: 8px;
  padding-bottom: 25px;
  span {
    font-size: 16px;
  }
`
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 45px 0;
  padding-bottom: 120px;
  p {
    color: #fff;
    padding: 12px;
  }
`
