export const wait = (time = 1500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

export const expiresIn = (seconds: number) => {
  const now = Date.now() / 1000
  const mm = new Date(seconds).getTime()
  return new Date(now + mm)
}

export const debounce = (fn: (...args: any[]) => any, wait = 250) => {
  let timer: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), wait)
  }
}
