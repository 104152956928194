import React from 'react'
import { IntlMessage } from '../../../../lang/IntlMessage'
import { TimeLine } from '../../../../sports/soccer/timeline/soccerTimeline'
import { Container, TextDisplay } from './gameDisplay.styles'

export type GameDisplayProps = {
  timeline: TimeLine
}
export const GameDisplay: React.FC<GameDisplayProps> = React.memo(
  ({ timeline }) => {
    return (
      <Container>
        <TextDisplay>
          {timeline?.clock === 90 ? (
            timeline?.display
          ) : (
            <IntlMessage id="gameDisplay_heading" />
          )}
        </TextDisplay>
      </Container>
    )
  }
)
