import { Source } from '../sports/types'

export type PostMessage = {
  otp: string
  selections: PostMessageSelection[]
  stake: number
  source: Source
}

export type PostMessageSelection = {
  selection: string
  event: string
  market: string
  sign: string
  odd: number | string
  tournament_id: number
  tournament_name: string
}

export const validatePostMessage = (pm: PostMessage): PostMessage => {
  // check all post message properties
  const validpm =
    Object.prototype.hasOwnProperty.call(pm, 'otp') &&
    Object.prototype.hasOwnProperty.call(pm, 'selections') &&
    Object.prototype.hasOwnProperty.call(pm, 'stake')

  if (validpm) {
    // check all selections

    const filteredPM = {
      ...pm,
      selections: pm.selections.filter(
        (selection) =>
          Object.prototype.hasOwnProperty.call(selection, 'selection') &&
          Object.prototype.hasOwnProperty.call(selection, 'event') &&
          Object.prototype.hasOwnProperty.call(selection, 'market') &&
          Object.prototype.hasOwnProperty.call(selection, 'sign') &&
          Object.prototype.hasOwnProperty.call(selection, 'odd') &&
          Object.prototype.hasOwnProperty.call(selection, 'tournament_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'tournament_name')
      ),
    }

    // cap the number of selections
    if (filteredPM.selections.length > 40) {
      throw { msg: 'errorSelectionNum' }
    }

    return filteredPM
  }

  throw { msg: 'errorPM' }
}
