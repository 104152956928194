import { Source } from '../sports/types'

export const BRAND = process.env.REACT_APP_BRAND
export const TARGET = process.env.REACT_APP_BUILD_TARGET
export const ACTIVETOURNAMENT = TARGET === 'prod' ? '170880' : '354121'

export type SimSports = 'Soccer' | 'Basketball'

type TCountry = {
  maxWin: number
  minStake: number
  maxStake: number
  currency: string
}

const nigeria = (source?: Source): TCountry => ({
  maxWin: 10000000,
  minStake: source === 'self-service' ? 150 : 50,
  maxStake: 2000000,
  currency: '₦',
})
const colombia = (source?: Source): TCountry => ({
  maxWin: 10000000, //placeholder
  minStake: source === 'self-service' ? 100 : 50, //placeholder
  maxStake: 2000000, //placeholder
  currency: '$',
})

export function getEnv(source?: Source) {
  const country: { [key: string]: TCountry } = {
    bet9ja: nigeria(source),
    yajuego: colombia(source),
  }

  return BRAND ? country[BRAND] : nigeria(source)
}

export function targetUrl() {
  const url: { [key: string]: { [key: string]: string } } = {
    local: {
      bet9ja: 'https://dev-simulate.appspot.com',
      yajuego: 'api.local.yajuego', //placeholder
    },
    dev: {
      bet9ja: 'https://api.simulate.dev.novafutur.com',
      yajuego: 'api.dev.yajuego', //placeholder
    },
    stg: {
      bet9ja: 'https://api.simulate.stg.novafutur.com',
      yajuego: 'api.stg.yajuego', //placeholder
    },
    prod: {
      bet9ja: 'https://api.simulate.novafutur.com',
      yajuego: 'api.yajuego', //placeholder
    },
    cert: {
      bet9ja: 'https://api.simulate.cert.novafutur.com',
      yajuego: 'api.yajuego', //placeholder
    },
  }

  if (!BRAND) return 'https://dev-simulate.appspot.com'

  return TARGET ? url[TARGET][BRAND] : url.local[BRAND]
}
