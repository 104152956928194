import { TransparentLayer } from '../../style/styles'
import { Spinner } from '../spinner/spinner'

export const SpinnerLayer = ({ status }: { status: string }) => {
  if (status === 'loading') {
    return (
      <TransparentLayer data-testid="transparentLayer">
        <Spinner size="65px" height="50vh" color="#f6b52d" />
      </TransparentLayer>
    )
  }

  return <div />
}
