import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Layer = styled.div`
  position: fixed;
  height: 100vh;
  max-width: 1024px;
  min-width: 320px;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
`
export const RocketWrapper = styled(Layer)<{ visible: boolean }>`
  display: flex;
  z-index: 1000;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: ${({ visible }) =>
    visible ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.0)'};
`

export const BackGround = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100%;
  max-width: 1024px;
  z-index: 900;
  background-color: ${({ theme }) => theme.colours.AP050};
  transform: translateZ(0);
  overflow: hidden;
`

export const Circle = styled(motion.div)`
  position: absolute;
  height: 5%;
  width: 2%;
  border-radius: 50%;
  z-index: 900;
  background-color: #fff;
  opacity: 1;
`

export const LoaderLogo = styled(motion.div)`
  width: 50px;
  height: 50px;
  z-index: 1000;

  path {
    fill: #000;
  }
`
