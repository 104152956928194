import { motion } from 'framer-motion'
import { StyledFlare } from './flare.style'

export const FlareAnim = () => (
  <StyledFlare>
    <motion.div
      animate={{
        x: '120vw',
        backgroundColor: '#fff',
        transition: {
          type: 'spring',
          stiffness: 55,
        },
      }}
      style={{
        x: '-100%',
        y: -30,
        width: 185,
        height: 150,
        rotate: 45,
        filter: 'blur(20px)',
        zIndex: 900,
        backgroundColor: '#ddd',
      }}
    />
  </StyledFlare>
)

export const Flare = FlareAnim
