import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colours.S060O};
  border-radius: 6px;
  width: 100%;
  height: 28px;
  position: absolute;
  z-index: 1;
  bottom: -20px;

  .icon_arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 25%);
  }

  .icon_stats {
    position: absolute;
    right: 9px;
    bottom: -1px;
  }
`
