import { SimMarket, Score, TimeSpan, CSCOREM1, CSCOREM2 } from './types'

const logicCSM =
  (other: string) =>
  (outcome: CSCOREM1 | CSCOREM2, score: Score): boolean => {
    // find the score
    const chosenScore = score[0] + '-' + score[1]
    const foundScore = outcome
      .split(' / ')
      .find((outcome) => outcome === chosenScore)

    // if outcome is 'OTHER' and score is not found
    if (outcome === other && !foundScore) {
      const allPossibleScores = (): string[] => {
        const ocs = ocCS_M2.map((outcome) => outcome.split(' / '))
        return [].concat(...(ocs as any[]))
      }
      const exists = allPossibleScores().find((score) => score === chosenScore)
      return exists ? false : true
    }

    return foundScore === chosenScore
  }

// Mutli Score 4
const ocCS_M1 = [
  '0-0 / 1-1 / 0-1 / 1-0',
  '2-0 / 2-1 / 3-0 / 3-1',
  '0-2 / 1-2 / 0-3 / 1-3',
  '2-2 / 2-3 / 3-2 / 3-3',
  '4-0 / 4-1 / 4-2 / 4-3',
  '0-4 / 1-4 / 2-4 / 3-4',
  '4-4 / other',
] as CSCOREM1[]

// Multi Score 3
const ocCS_M2 = [
  '0-0 / 1-1',
  '2-2 / 3-3 / 4-4',
  '1-0 / 2-0 / 2-1',
  '0-1 / 0-2 / 1-2',
  '3-0 / 3-1 / 3-2',
  '0-3 / 1-3 / 2-3',
  '4-1 / 4-2 / 4-3',
  '1-4 / 2-4 / 3-4',
  '4-0 / 0-4 / other',
] as CSCOREM2[]

const logicCS_M1 = (outcome: CSCOREM1, score: Score) =>
  logicCSM('4-4 / other')(outcome, score)

const logicCS_M2 = (outcome: CSCOREM2, score: Score) =>
  logicCSM('4-0 / 0-4 / other')(outcome, score)

const _CS_M1 = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id, // 29
  time,
  handicap,
  outcomes: ocCS_M1,
  logic: logicCS_M1,
})

const _CS_M2 = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id, // 30
  time,
  handicap,
  outcomes: ocCS_M2,
  logic: logicCS_M2,
})

export const FT_CS_M1 = _CS_M1(29, 'FT')
export const FT_CS_M2 = _CS_M2(30, 'FT')
