import styled from 'styled-components'

export const BetSlipItem = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  position: relative;
  z-index: 2;
  margin: 4px 0;
  padding: 0 4px;
  overflow: hidden;
  cursor: pointer;
`

export const Unsupported = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  margin: 0 5px;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colours.N040};
  font-size: 11px;
  opacity: 0.9;
  background-image: linear-gradient(to left, #2d2f34, #202227);
  border-radius: 3px;
`

export const Cartouche = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: ${(props) => props.theme.colours.RN080};
  padding-left: 8px;

  svg path {
    fill: ${(props) => props.theme.colours.RN050};
  }
`

export const Delete = styled.div<{ warning?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  width: 25px;
  height: 50px;
  margin-left: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: ${({ warning, theme }) =>
    warning ? 'rgba(23, 24, 28, .2)' : `${theme.colours.S050}`};
`

export const Item = styled.div<{ warning?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  min-width: 0;
  width: 100%;
  padding: 10px 0px 10px 8px;
  border-radius: 3px;
  ${({ warning, theme }) =>
    warning
      ? `background-color: ${theme.colours.RN080}`
      : `background-image: ${theme.colours.S050G}`}
`

export const Bet = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-right: auto;
  .title {
    .title-outcome {
      font-weight: 500;
      font-size: 16px;
      color: ${(props) => props.theme.colours.N030};
    }
    .title-market {
      word-wrap: nowrap;
      overflow: hidden;
      font-size: 12px;
      color: ${(props) => props.theme.colours.N040};
      padding-left: 3px;
    }
  }
  .event {
    color: ${(props) => props.theme.colours.N040};
    line-height: 18px;
    display: flex;
    align-items: center;
    .event-match {
      font-size: 0.9rem;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      padding-left: 3px;
    }
  }
`

export const Odd = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.colours.AP050};
  font-weight: 500;
`
