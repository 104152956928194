import styled from 'styled-components'

export const Wrapper = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto 44px auto;
  border-radius: 6px;
`
