import { getSport, SportsCode } from './sportMap'
import { Outcome } from './soccer/markets/types'
import { SimBet, Source } from './types'
import { PostMessage, PostMessageSelection } from '../lib/postMessageValidator'
import { findMarketFromID } from './marketUtils'

export const getSymbolToUse = (src: Source) => {
  if (src === 'desktop' || src === 'old-mobile' || src === 'self-service')
    return '#'

  return '_'
}

export const getSpread = (
  sign?: string,
  mkt?: string,
  market_id?: number,
  sport_id?: number
) => {
  const sign_num = sign?.replace(/[^\d.|^.]*/g, '')
  const outcome_num = mkt?.replace(/[^\d.|^.]*/g, '')
  let market = ''

  if (market_id && sport_id) {
    const m = findMarketFromID(market_id, sport_id)
    market = m.slice(-5).replace(/[^\d.|^.]*/g, '')
  }

  if (market) return market
  if (sign_num) return sign_num
  if (outcome_num) return outcome_num
  return ''
}

export const adapter = (
  pm: PostMessage,
  permissions: { [key: string]: boolean }
): SimBet[] => {
  return pm.selections
    .map((sel) => createSelection(sel, pm.source || 'new-mobile', permissions))
    .filter((s) => s.event)
}

export const createSelection = (
  {
    event,
    selection,
    odd,
    market,
    sign,
    tournament_id,
    tournament_name,
  }: PostMessageSelection,
  src: Source,
  permissions: { [key: string]: boolean }
): SimBet => {
  // get the symbol to split on
  const symbol = getSymbolToUse(src)
  const isIsolutions =
    src === 'desktop' || src === 'old-mobile' || src === 'self-service'

  // extract matchId and sport head, MKT, outcome
  // const [head, MKT, outcome] = selection.split(symbol)
  const hmo = selection.split(symbol)
  const head = hmo[0]
  let MKT = hmo[1]
  let outcome = hmo[2]

  if (isIsolutions) {
    if (market === 'Over/Under 1stQ') {
      MKT = `${hmo[1]} ${sign?.replace(
        /[^()](?=([^()]*\([^()]*\))*[^()]*$)/g,
        ''
      )}`
    }

    if (
      market === 'O/U Points 110-139' ||
      market === 'O/U Points 140-169' ||
      market === 'O/U Points 170-199' ||
      market === 'O/U Points 200-229' ||
      market === 'O/U Points 230-259' ||
      market === 'O/U Points 260-289' ||
      market === 'O/U Points 290-320'
    ) {
      MKT = `${hmo[1]} ${sign?.replace(/[^\d.]*/g, '')}`
      if (outcome.includes('Under')) outcome = 'Under'
      if (outcome.includes('Over')) outcome = 'Over'
    }
  }

  const [matchId, sport] = head.split('$')

  // find which sport map to use
  const sportMap = getSport(src, sport as SportsCode)
  if (!sportMap) {
    return {} as SimBet
  }

  const { sportId, marketMap, outcomeMap, findSimMarketId } = sportMap

  if (sportId === 2 && !permissions?.basketball) {
    return {} as SimBet
  }

  const simulateMarket = marketMap.get(MKT) // find if there is a matching market in the map
  const supported = marketMap.has(MKT) // find if market is supported
  const simMarketID = findSimMarketId(simulateMarket, sportId) || 0
  const spread = getSpread(sign, MKT, simMarketID, sportId)
  const matchID = Number(matchId) // cast matchId
  odd = typeof odd === 'string' ? parseFloat(odd) : odd // cast odd and replace
  const simOutcome = simulateMarket ? outcomeMap(MKT, outcome) : ('' as Outcome) // find the outcome

  // return a valid simulate object
  return {
    b9jBet: selection,
    matchID,
    sport: sportId,
    odd,
    event,
    market,
    simMarketID,
    outcome: sign,
    simOutcome,
    supported,
    blocking: false,
    spread,
    tournament_id: tournament_id?.toString(),
    tournament_name,
  }
}
