import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 8px 4px 12px 4px;
`

export const LeagueList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-x: auto;
  list-style-type: none;
  background-color: ${({ theme }) => theme.colours.N090};
`

export const LeagueItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  padding: 7px 14px;
  margin-right: 4px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colours.N070};
  color: ${({ theme }) => theme.colours.N050};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.colours.N030};
    background: #3b3d48;
  }
`
