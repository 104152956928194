import { useSelector } from 'react-redux'
import {
  selectActiveTournament,
  selectAvailableTournaments,
  setActiveTournaments,
} from '../../store/explore.state'
import { useAppDispatch } from '../../../../store/store'
import { Wrapper, LeagueList, LeagueItem } from './exploreLeagueTab.style'

export const ExploreLeagueTab = () => {
  const dispatch = useAppDispatch()
  const tournaments = useSelector(selectAvailableTournaments)
  const activeTournament = useSelector(selectActiveTournament)

  return (
    <Wrapper>
      <LeagueList>
        {tournaments.map((t) => (
          <LeagueItem
            key={t.tournament_id}
            className={`${
              activeTournament?.tournament_id === t.tournament_id && 'active'
            }`}
            onClick={() => dispatch(setActiveTournaments(t.tournament_id))}
          >
            <div>{t.tournament_name}</div>
          </LeagueItem>
        ))}
      </LeagueList>
    </Wrapper>
  )
}
