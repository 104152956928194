import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectActiveSport } from '../../store/explore.state'
import { ArrowRight } from '../../../../components/svgs'

interface ExploreButtonProps {
  viewMore: boolean
  setViewMore(viewMore: boolean): void
}

export const ExploreButton: React.FC<ExploreButtonProps> = ({
  setViewMore,
  viewMore,
}) => {
  const activeSport = useSelector(selectActiveSport)

  const buttonContent = `Show ${
    viewMore
      ? 'less'
      : `more ${activeSport === 'Soccer' ? 'Football' : 'Basketball'}`
  }`

  return (
    <Wrapper>
      <Button onClick={() => setViewMore(!viewMore)}>{buttonContent}</Button>
      <ArrowRight width="24" height="16" viewBox="3 3 20 19" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 8px;
  path {
    fill: ${({ theme }) => theme.colours.AP050};
  }
`
const Button = styled.button`
  color: ${({ theme }) => theme.colours.AP050};
  background: transparent;
  border: none;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  cursor: pointer;
`
