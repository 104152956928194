import { AnimatePresence } from 'framer-motion'
import { SimBet } from '../../../../sports/types'
import { Wrapper } from './exploreDetail.style'
import { Stats } from '../../../../components/stats/stats'

interface ExploreDetailProps {
  bet: SimBet
  expand: boolean
  setExpand(expand: boolean): void
}

export const ExploreDetail: React.FC<ExploreDetailProps> = ({
  bet,
  expand,
  setExpand,
}) => {
  return (
    <AnimatePresence>
      {expand && (
        <Wrapper
          variants={{
            open: { height: 'calc(100% - 52px)' },
            closed: { height: '0' },
          }}
          initial={{ height: 0 }}
          animate={{ height: expand ? 'auto' : 0 }}
          exit="closed"
          transition={{ duration: 0.12 }}
          onClick={() => setExpand(!expand)}
          data-testid="exploreDetail"
        >
          <Stats stats={bet?.stats} event={bet?.event} />
        </Wrapper>
      )}
    </AnimatePresence>
  )
}
