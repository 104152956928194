import { SpinnerWrapper, StyledSpinner } from './spinner.style'

export const Spinner: React.FC<{
  height: string
  size?: string
  color?: string
  thick?: number
}> = ({ height, size, color, thick = 4 }) => (
  <SpinnerWrapper height={height}>
    <StyledSpinner size={size} color={color} viewBox="0 0 50 50">
      <title>Spinner</title>
      <circle
        fill="none"
        className="path"
        cx="25"
        cy="25"
        r="22"
        strokeWidth={thick}
      />
    </StyledSpinner>
  </SpinnerWrapper>
)
