import { IntlMessage } from '../../../../lang/IntlMessage'
import { TMessages } from '../../../../lang/types'
import { Label, Box, HiddenCheckBox, VisibleCheckBox } from './checkbox.style'

interface ICheckbox {
  checked: boolean
  onChange(): void
  label: keyof TMessages
}

export const CheckBox: React.FC<ICheckbox> = ({ checked, onChange, label }) => {
  return (
    <label>
      <Label>
        <IntlMessage id={label} />
      </Label>
      <Box>
        <HiddenCheckBox onChange={onChange} />
        <VisibleCheckBox checked={checked} data-testid="tick">
          {checked && (
            <svg viewBox="0 0 24 24">
              <title>tick</title>
              <polyline points="20 6 9 17 4 12" />
            </svg>
          )}
        </VisibleCheckBox>
      </Box>
    </label>
  )
}
