import { Source } from '../sports/types'
import { FilterList } from '../features/my-games/store/mygames.state'
import { BetResponse, ErrorResponse } from './types'

/**
 * Maps source to Backend number.
 * @param source Application sending the data
 */
export const switchOnSource = (source: Source) => {
  const src: { [key in Source]: number } = {
    'new-mobile': 0,
    'old-mobile': 1,
    desktop: 2,
    'self-service': 3,
    'new-desktop': 4,
    'mobile-lite': 5,
    '': 0,
  }

  return src[source] || 0
}

/**
 * Maps Filter to BackEnd number
 * @param f mygames filter value
 */
export const switchOnFilter = (f: FilterList) => {
  switch (f) {
    case 'lost':
      return -1
    case 'won':
      return 1
    case 'all':
      return 0
  }
}

/**
 * Backend status code handler, returns the appropriate message.
 * @param data the bet response
 */
export const statusHandling = (data: BetResponse): BetResponse | never => {
  const accountStatusDico = {
    '-2': 'errorAccountStatus_2',
    '-1': 'errorAccountStatus_1',
    '0': 'errorAccountStatus0',
    '1': 'errorAccountStatus1',
    '2': 'errorAccountStatus2',
  }

  const betStatusDico = {
    '-10': 'errorBetStatus_10',
    '-1': 'errorBetStatus_1',
    '0': 'errorBetStatus0',
    '1': 'errorBetStatus1',
  }

  if (data.account_status === -1) {
    throw { msg: accountStatusDico[data.account_status] }
  }
  if (data.status === -10) {
    throw { msg: betStatusDico[data.status] }
  }
  return data
}

export const errorHandling = (res: any, status = 500): ErrorResponse => {
  const say = (code: number) => {
    const out: { [key: number]: string } = {
      401: 'error401',
      403: 'error403',
      404: 'error404',
      408: 'error408',
      422: 'error422',
      424: 'error424',
      425: 'error425',
    }

    return out[code] || 'error500'
  }

  const errorResponse: ErrorResponse = {
    msg: say(status),
    status: status,
    data: res || {},
  }

  throw errorResponse
}
