import { SimMarket, Score, TimeSpan, OU } from './types'

export const logicOU = (handicap: number) => (outcome: OU, score: Score) => {
  const oc: { [key in OU]: () => boolean | 'settled' } = {
    u: () => score[0] + score[1] < handicap,
    o: () => (score[0] + score[1] > handicap ? 'settled' : false),
  }
  return oc[outcome]() ?? false
}

export const logicOUnS = (handicap: number) => (outcome: OU, score: Score) => {
  const oc: { [key in OU]: () => boolean } = {
    u: () => score[0] + score[1] < handicap,
    o: () => score[0] + score[1] > handicap,
  }
  return oc[outcome]() ?? false
}

const ocOU = ['o', 'u'] as OU[]

const _OU = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocOU,
  logic: logicOU(handicap),
})

export const FT_OU_05 = _OU(2, 'FT', 0.5)
export const FT_OU_15 = _OU(3, 'FT', 1.5)
export const FT_OU_25 = _OU(4, 'FT', 2.5)
export const FT_OU_35 = _OU(5, 'FT', 3.5)
export const FT_OU_45 = _OU(6, 'FT', 4.5)
export const FT_OU_55 = _OU(7, 'FT', 5.5)
export const FT_OU_65 = _OU(8, 'FT', 6.5)
export const FT_OU_75 = _OU(9, 'FT', 7.5)
export const FT_OU_85 = _OU(10, 'FT', 8.5)

export const HT_OU_05 = _OU(36, 'HT', 0.5)
export const HT_OU_15 = _OU(37, 'HT', 1.5)
export const HT_OU_25 = _OU(38, 'HT', 2.5)
