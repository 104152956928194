import { BehaviorSubject, queueScheduler, scheduled, timer } from 'rxjs'
import { concatAll, map, scan, take } from 'rxjs/operators'
import { GameSpeed } from '../../../features/app/store/app.state'
import { clamp } from '../../../lib/utils/mathUtils'
import { speeds } from '../../../lib/timeline/utils'

export type TimeLine = {
  boost: boolean
  clock: number
  countDown: string
  display: string
  segment: string
}

const timelineInit: TimeLine = {
  boost: false, // boost animation
  countDown: '', // count down animation
  clock: 0, // absolute time
  display: '0:00', // counter in game header
  segment: '1st Half',
}

export const soccerTimeline$ = new BehaviorSubject<TimeLine>(timelineInit)

export const resetSoccerTimeLine = () => soccerTimeline$.next(timelineInit)

export const formatClock = (n: number): string => {
  n = clamp(0, 90, n)
  const result: {
    [key: number]: string
  } = {
    45: 'HALFTIME',
    90: 'FULLTIME',
  }
  return result[n] || `${n}:00`
}

export const generateSoccerTimeLine = (
  speed: GameSpeed,
  isCashier: boolean
) => {
  const periodSpeed = speeds(speed, isCashier)
  const duration = 45
  const cdArray = isCashier
    ? ['5', '4', '3', '2', '1', 'Kick-off']
    : ['3', '2', '1', 'Kick-off']

  const boost = () =>
    timer(0, 800).pipe(
      take(2),
      map(() => ({
        boost: true,
      }))
    )

  const countDown = () =>
    timer(0, 800).pipe(
      take(cdArray.length),
      map((i) => ({
        boost: false,
        countDown: cdArray[i],
      }))
    )

  const pause = (p: number) => timer(p)

  const period = (
    duetime: number,
    period: number,
    offset: number,
    duration: number
  ) =>
    timer(duetime, period).pipe(
      take(duration),
      map((n) => ({
        boost: false,
        countDown: '',
        clock: n + 1 + offset,
        display: formatClock(n + 1 + offset),
        segment: n + 1 + offset > 45 ? '2nd Half' : '1st Half',
      }))
    )

  const line$ = [
    boost(),
    speed !== 'turbo' || isCashier ? countDown() : pause(0),
    period(speed !== 'turbo' || isCashier ? 800 : 40, periodSpeed, 0, duration),
    period(1000, periodSpeed, duration, duration),
    pause(speed !== 'turbo' || isCashier ? 400 : 700),
  ]

  const gameTimeline$ = scheduled(line$, queueScheduler).pipe(
    concatAll(),
    scan((acc, curr) => Object.assign({}, acc, curr), timelineInit),
    map((t) => {
      // console.log('soccer', t)
      soccerTimeline$.next(t)
      return t
    })
  )

  return gameTimeline$
}
