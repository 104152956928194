import { ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSource } from '../../../app/store/app.state'
import { clicksSetSpeed } from '../../..//app/store/app.state'

import { MenuButton } from './setSpeedButton.style'

export const SetSpeedButton: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const source = useSelector(selectSource)

  // if (source === 'self-service') {
  //   dispatch(clicksSpeed('turbo'))
  //   return <div />
  // }
  if (source === 'self-service') {
    return <div />
  }

  return (
    <MenuButton
      onClick={() => dispatch(clicksSetSpeed())}
      className="MenuButton"
    >
      {children}
    </MenuButton>
  )
}
