import styled from 'styled-components'
import { Basketball, Football } from '../../../../components/svgs'
import { useSelector } from 'react-redux'
import { selectActiveSport } from '../../store/explore.state'

interface ExploreHeadingProps {
  title: React.ReactNode | string
}
export const ExploreHeading: React.FC<ExploreHeadingProps> = ({ title }) => {
  const activeSport = useSelector(selectActiveSport)

  return (
    <Wrapper data-testid="exploreHeading">
      {activeSport === 'Soccer' ? (
        <Football width="16" height="16" viewBox="0 0 14 16" />
      ) : (
        <Basketball width="16" height="16" viewBox="0 0 14 16" />
      )}

      <Heading>{title}</Heading>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
  color: ${(props) => props.theme.colours.N040};
  margin: 0 4px;
`

const Heading = styled.h2`
  margin: 0;
  font-weight: 500;
`
