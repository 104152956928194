import { useDispatch, useSelector } from 'react-redux'
import { useActiveFreeBetCountDown } from '../../../../lib/count-down/useCountDown'
import {
  selectBlocking,
  selectCockpitValues,
  selectDisableQuickStake,
} from '../../../betslip/store/betslip.selectors'
import { clicksQuickStake } from '../../../betslip/store/betslip.state'
import { useNavigate } from 'react-router'
import { Gift } from '../../../../components/svgs'
import {
  Button,
  QuickStakesWrapper,
  FreeBet,
  FreeBetButton,
} from './quickStake.style'
import { selectIsCashier } from '../../../app/store/app.state'
import { IntlMessage } from '../../../../lang/IntlMessage'

export const QuickStakes = () => (
  <QuickStakesWrapper data-testid="QuickStakes">
    <QuickAmounts />
    <QuickFreeBet />
  </QuickStakesWrapper>
)

const QuickAmounts = () => {
  const dispatch = useDispatch()
  const disabled = useSelector(selectDisableQuickStake)
  const qbtns = [100, 200, 500, 1000]

  return (
    <>
      {qbtns.map((stake) => (
        <Button
          data-testid="qbtn"
          disabled={disabled}
          key={stake}
          onClick={() => dispatch(clicksQuickStake(stake))}
        >
          + {stake}
        </Button>
      ))}
    </>
  )
}

const QuickFreeBet = () => {
  const navigate = useNavigate()
  const blockUI = useSelector(selectBlocking)
  const { displayCountDown, freeBetLength } = useActiveFreeBetCountDown()
  const { freeBetAmount } = useSelector(selectCockpitValues)
  const isCashier = useSelector(selectIsCashier)

  if (freeBetLength > 0 && !isCashier) {
    return (
      <div>
        <FreeBetButton
          disabled={blockUI}
          onClick={() => navigate('/mybets/freebets')}
          animation={!!freeBetAmount}
        >
          <FreeBet>
            <Gift width="24" height="24" viewBox="-1 -1 26 26" />
            <div>
              <div className="info">
                <IntlMessage id="quickFreeBet_freeBet" />
              </div>
              {displayCountDown}
            </div>
          </FreeBet>
        </FreeBetButton>
      </div>
    )
  }

  return <div />
}
