import { AuthenticateRequest, AuthenticateResponse } from '../../../api/types'
import { errorHandling, switchOnSource } from '../../../api/utils'
import { targetUrl } from '../../../config'
import { Source } from '../../../sports/types'

/**
 * POST to authenticate using credentials passed down by client in PostMessage,
 * Returns a session ID
 * @param otp one time password
 * @param novaToken valid token
 */

export const authenticate = async (
  otp: string,
  source: Source
): Promise<AuthenticateResponse> => {
  const payload: AuthenticateRequest = {
    client_token: otp,
    source: switchOnSource(source),
  }

  const response = await fetch(`${targetUrl()}/simulate/Authenticate`, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}
